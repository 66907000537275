import React, { useState, useContext, useRef } from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import t from '@jetshop/intl';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { theme } from '../../Theme';
import ChannelContext from '@jetshop/core/components/ChannelContext';

import { TopBar } from './Header';
import MobileCategories from './Categories/MobileCategories';
import ChannelSelector from './ChannelSelector/ChannelSelector';
import Selector from './ChannelSelector/Selector';

import { useQuery } from 'react-apollo';
import homeCategoriesQuery from './HomeCategoriesQuery.gql';

import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { ReactComponent as User } from '../../../svg/fa/user_new.svg';
import { ReactComponent as MapMarker } from '../../../svg/fa/mapmarker_new.svg';
import { ReactComponent as Times } from '../../../svg/fa/close.svg';
import { ReactComponent as AngleRight } from '../../../svg/fa/angle-right-light.svg';

const NavBarHeader = styled('div')`
  position: relative;
  background-color: #fff;
  display: flex;
  height: 52px;
  justify-content: center;
  align-items: baseline;
  flex: 0 0 auto;
  justify-content: flex-end;
`;

const Scroll = styled('div')`
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  flex: 1 1 auto;
  display: flex;
  align-items: space-between;
  min-height: calc(100% - 52px);
  flex-direction: column;
  background: white;
`;

export const CloseButton = styled('button')`
  background: none;
  border: 0;
  appearance: none;
  border-radius: 0;
  padding: 1rem;
  svg {
    display: block;
    width: 20px !important;
    height: 20px;
    use,
    path {
      fill: ${theme.colors.primary};
    }
  }
`;

const ExtraButtonsTop = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  padding: 1rem;

`;

const MobileLogin = styled('div')`
  a {
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    align-items: center;
    gap: 0.3rem;
    svg {
      width: 20px;
      height: 20px;
    }
  }
`;

const FindStore = styled('div')`
  a {
    line-height: initial;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row-reverse;
    gap: 0.3rem;
    font-size: 16px;
    svg {
      width: 20px !important;
      height: 20px;
    }
  }
`;

const ExtraButtonsBottom = styled('div')`
  display: flex;
  align-items: flex-start;
  background: #f6f6f6;
  padding: 1rem;
  height: 700px;

  .selector-with-arrow {
    display: flex;
    align-items: center;
    margin: 0 !important;
    svg {
      height: 22px;
      width: 22px;
    }
  }

  > button,
  > div {
    width: 50%;
    padding: 0;
    flex: 1;
    span {
      font-size: 14px;
    }
  }
  button,
  a {
    padding: 0;
    line-height: initial;
    text-align: center;
    justify-content: flex-end;
    margin: 0 !important;
  }
`;

export default function MobileMenu() {
  const data = useQuery(homeCategoriesQuery, {
    variables: {
      levels: 3
    }
  });
  const [locked, setLocked] = useState(false);
  const { loggedIn } = useAuth();
  const [active, setActive] = useState(false);
  const [openCat, setOpenCat] = useState(null);
  const { channels, updateChannel, selectedChannel } =
    useContext(ChannelContext);

  const menuWrapper = useRef(null);
  if (!(data && data?.data?.categories)) return null;
  return (
    <DrawerTarget id="menu-drawer">
      {drawer => (
        <Drawer isOpen={drawer.isOpen} left>
          <div locked={locked ? locked : undefined} ref={menuWrapper}>
            <NavBarHeader>
              <CloseButton
                onClick={() => {
                  drawer.hideTarget();
                }}
              >
                <Times />
              </CloseButton>
            </NavBarHeader>

            <ExtraButtonsTop>
              {selectedChannel.name !== 'EU' && (
                    <>
                      <FindStore>
                        <Link to="/stores" onClick={() => drawer.hideTarget()}>
                          {t('Find a store')}
                          <MapMarker />
                        </Link>
                      </FindStore>
                      
                      <MobileLogin>
                        <Link
                          to="/my-pages"
                          className="mobile-header-button"
                          onClick={() => drawer.hideTarget()}
                        >
                          <span>{loggedIn ? 'Mina sidor' : 'Logga in'}</span>
                          <User />
                        </Link>
                      </MobileLogin>
                    </>
                )}
            </ExtraButtonsTop>

            <Scroll>
              <MobileCategories
                menuWrapper={menuWrapper}
                locked={locked}
                setLocked={setLocked}
                categories={data?.data.categories}
                closeMenu={drawer.hideTarget}
                openCat={openCat}
                setOpenCat={setOpenCat}
              />

              <ExtraButtonsBottom>
                <div className='selector-with-arrow'>
                  <ChannelSelector onClick={() => setActive(!active)} />
                  <AngleRight />
                </div>
                {active ? (
                  <Selector
                    channels={channels}
                    selectedChannel={selectedChannel}
                    updateChannel={updateChannel}
                    hideMobile={() => drawer.hideTarget()}
                  />
                ) : null}
              </ExtraButtonsBottom>

            </Scroll>
          </div>
        </Drawer>
      )}
    </DrawerTarget>
  );
}
