import React, { useMemo, useState, useEffect } from 'react';
import { styled } from 'linaria/react';
import UIMaxWidth from '../Layout/MaxWidth';
import dayjs from 'dayjs';
import { theme } from '../Theme';
import DynamicContent from '../DynamicContent/DynamicContent';
import t from '@jetshop/intl';

dayjs.locale('sv');

const MaxWidth = styled(UIMaxWidth)``;

const TimelineWrapper = styled('section')`
  h1 {
    text-align: center;
    margin: auto auto;
  }
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 1rem 0;
  padding-bottom: 100px;
`;

const EventWrapper = styled('article')`
  padding: 0 1rem;
  width: 50%;
  order: ${props => props.order};
  position: relative;
  display: flex;
  justify-content: flex-end;
  ${theme.below.md} {
    width: 100%;
    padding-right: 0;
  }
  & > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 1rem;
    border-radius: 3px;
    max-width: ${props => props.imgWidth}px;
    box-shadow: 0 1.1px 1.7px rgba(0, 0, 0, 0.028),
      0 2.7px 4px rgba(0, 0, 0, 0.04), 0 5px 7.5px rgba(0, 0, 0, 0.05),
      0 8.9px 13.4px rgba(0, 0, 0, 0.06), 0 16.7px 25.1px rgba(0, 0, 0, 0.072),
      0 40px 60px rgba(0, 0, 0, 0.1);
    ${theme.below.md} {
      width: 100%;
    }
  }

  //Fix for top content?

  margin-top: 4rem;
  & + article {
    margin-top: 0;
  }

  img {
    max-width: 100%;
  }
  time {
    display: block;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 15px;
    padding: 0.5rem 0;
  }

  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    width: 3rem;
    background: ${theme.colors.accent};
    height: 1rem;
    transform: translatey(-50%);
    border-radius: 0.5rem;

    ${theme.below.md} {
      width: 1.5rem;
    }
  }

  &::before {
    display: block;
    content: '';
    position: absolute;
    top: -1rem;
    right: -0.5rem;
    width: 1rem;
    background: ${theme.colors.accent};
    height: calc(100% + 2rem);
    z-index: 9;
    border-radius: 0.5rem;
    ${theme.below.md} {
      left: -0.5rem;
      height: calc(100% + 3rem);
      right: auto;
    }
  }

  &:nth-child(odd) {
    justify-content: flex-start;
    padding-left: 4rem;
    margin-left: 50%;
    &::after {
      left: 0;
    }

    ${theme.below.md} {
      margin-left: 0;
      padding-left: 1rem;
    }
    &::before {
      left: -0.5rem;
      right: auto;
    }
  }

  &:nth-child(even) {
    margin-right: 50%;
    padding-right: 4rem;

    &::after {
      right: 0;
      ${theme.below.md} {
        right: auto;
        left: 0;
      }
    }

    ${theme.below.md} {
      margin-right: 0;
      padding-right: 0rem;
    }
  }

  ${theme.below.md} {
    margin-bottom: 2rem;
  }

  button {
    padding: 3px 1rem;
    font-size: 12px;
    background: transparent;
    margin-top: 0.5rem;
    border: 1px solid ${theme.colors.accent};
    color: ${theme.colors.accent};
    transition: 0.2s;
    &:hover {
      color: white;
      background: ${theme.colors.accent};
    }
    &:focus {
      outline: none;
    }
  }
`;

const EventContent = styled('div')`
  min-width: 300px;

  transition: 0.3s;
  overflow: hidden;
  p {
    //max-width:400px;
  }
  &.long-text {
    position: relative;
    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 1) 0%,
        75%,
        rgba(255, 255, 255, 0) 100%
      );
      width: 100%;
      left: 0;
      transition: 0.3s;
    }
    &.closed {
      max-height: 165px;
      &::before {
        height: 50px;
      }
    }
    &.open {
      max-height: ${props =>
        props.textLength ? `${props.textLength}px` : '9999px'};
      &::before {
        height: 0%;
      }
    }
  }
  ${theme.below.md} {
    min-width: unset;
  }
  br {
    display: none;
  }
`;

const Event = props => {
  const [open, setOpen] = useState(false);
  const [imgWidth, setImgWidth] = useState(null);
  const [textLength, setTextLength] = useState({ cls: '' });

  function getImgWidth(url, callback) {
    var img = new Image();
    img.src = url;
    img.onload = function () {
      callback(this.width);
    };
  }

  useEffect(() => {
    getImgWidth(props.image, setImgWidth);
  }, [props]);

  const textMounted = node => {
    if (node?.clientHeight && node.clientHeight > 150 && !textLength.num) {
      setTextLength({ cls: 'long-text', num: node.clientHeight });
    }
  };

  if (!imgWidth) return null;

  return (
    <EventWrapper imgWidth={imgWidth}>
      <div>
        {props?.image && <img src={props.image} alt="" />}
        <time>{dayjs(props.date).format('YYYY')}</time>
        <h2 dangerouslySetInnerHTML={{ __html: props.headline }} />
        <EventContent
          textLength={textLength.num}
          innerRef={textMounted}
          className={`${open ? 'open' : 'closed'} ${textLength.cls}`}
        >
          {props.children}
        </EventContent>
        {textLength?.num && (
          <button onClick={() => setOpen(!open)}>
            {open ? t('Read less') : t('Read more')}
          </button>
        )}
      </div>
    </EventWrapper>
  );
};

export default function Timeline({ content, ...rest }) {
  const cleanContent = useMemo(() => {
    const regex = /<(\/?)(DOCTYPE html|html|head|body|div)>/g;
    let tempContent = content.replace(regex, '');
    return tempContent;
  }, [content]);
  return (
    <MaxWidth>
      <TimelineWrapper>
        <h1>{rest.mainHeader}</h1>
        <DynamicContent
          content={cleanContent}
          additionalComponents={{ Event: Event }}
        />
      </TimelineWrapper>
    </MaxWidth>
  );
}
