import { useState, useEffect } from 'react';

// Helper function to parse numbers, considering ',' as decimal separator
const parseNumber = str => parseFloat(str.replace(',', '.'));

// Function to sort options containing inch symbols
const sortInchSizes = (a, b) => {
    // Updated to check for both double-quote and two single quotes
    const containsInchSymbol = str => str.includes('"') || str.includes("''");
  
    const aContainsInch = containsInchSymbol(a);
    const bContainsInch = containsInchSymbol(b);
  
    // Prioritize non-inch sizes over inch sizes
    if (aContainsInch !== bContainsInch) {
      return aContainsInch ? 1 : -1;
    }
  
    // If both are inch sizes, sort based on text and number
    if (aContainsInch && bContainsInch) {
      let [textA, numberA] = a.split(/(\d+,\d+|\d+)/).map(s => s.trim());
      let [textB, numberB] = b.split(/(\d+,\d+|\d+)/).map(s => s.trim());
  
      if (textA < textB) return -1;
      if (textA > textB) return 1;
  
      return parseNumber(numberA) - parseNumber(numberB);
    }
  
    // If neither is an inch size, default sort (alphabetical or numerical)
    if (isNaN(parseNumber(a)) || isNaN(parseNumber(b))) {
      return a.localeCompare(b);
    }
  
    return parseNumber(a) - parseNumber(b);
  };

  
const sortDiameterSizes = (a, b) => {
    const diameterRegex = /Ø (\d{2}) mm (\d+)/;
  
    const parseDiameter = str => {
      const match = str.match(diameterRegex);
      return match ? { diameter: parseInt(match[1]), mmValue: parseInt(match[2]) } : null;
    };
  
    const aDiameter = parseDiameter(a);
    const bDiameter = parseDiameter(b);
  
    if (!aDiameter || !bDiameter) {
      // Fallback for non-matching strings, possibly sort alphabetically or numerically
      return a.localeCompare(b);
    }
  
    // First sort by diameter
    if (aDiameter.diameter !== bDiameter.diameter) {
      return aDiameter.diameter - bDiameter.diameter;
    }
  
    // Then sort by the number after 'mm'
    return aDiameter.mmValue - bDiameter.mmValue;
  };


  const predefinedOrderList = [
    ['Mini', 'Shetl.', 'Ponny', "Small horse", 'Cob', 'Full', 'X-Full'], 
    ['Junior', 'Barn', 'Dam', 'Herr'], ['X Slim', 'Slim', 'Smal'], 
    ['Short', 'Låg'],
    ['Small', 'Medium', 'Normal', 'Regular', 'Large'],
    ['Tall', 'Long', 'Hög'],
    ['Wide', 'Vid'],
    ['X-Vid', 'X-Wide'],
    ['XXXS', 'XXS', 'XS', 'S', 'M', 'L', 'XL', 'XXL', 'XXXL'],
    ['Junior: 5-7 år', 'Junior: 8-10 år','Junior: 11-13 år', 'Junior: 11-13 år Vid'],
    ];

  const postdefinedOrderList = ['XST', 'ST  Small/Tall', 'MT Medium/Tall'];
  
  const sortPredefinedSizes = (a, b) => {
    const lastListIndex = predefinedOrderList.length - 1;
  
    const getPredefinedListOrder = (value, predefinedOrderList) => {
      for (let i = 0; i < predefinedOrderList.length; i++) {
        const index = predefinedOrderList[i].indexOf(value);
        if (index !== -1) {
          return { listIndex: i, valueIndex: index, isLastList: i === lastListIndex };
        }
      }
      return null; // Not found in any predefined list
    };
  
    const moveStlToTop = (a, b) => {
      const getStlNumber = str => {
        const match = str.match(/Stl (\d+)/);
        return match ? parseInt(match[1], 10) : null;
      };
  
      const stlNumberA = getStlNumber(a);
      const stlNumberB = getStlNumber(b);
  
      // Prioritize "Stl" sizes at the top and sort them by their number
      if (stlNumberA !== null || stlNumberB !== null) {
        if (stlNumberA !== null && stlNumberB !== null) {
          return stlNumberA - stlNumberB;
        }
        return stlNumberA !== null ? -1 : 1;
      }
      return 0;
    };
  
    // Handle predefined list order
    const aOrder = getPredefinedListOrder(a, predefinedOrderList);
    const bOrder = getPredefinedListOrder(b, predefinedOrderList);
  
    // Check if either a or b is in the last predefined list
    if (aOrder?.isLastList || bOrder?.isLastList) {
      if (aOrder?.isLastList && bOrder?.isLastList) {
        // Both in the last list, sort normally within the list
        return aOrder.valueIndex - bOrder.valueIndex;
      }
      // Prioritize the value from the last list
      return aOrder?.isLastList ? -1 : 1;
    }
  
    // Check for "Stl" sizes and prioritize them
    const stlResult = moveStlToTop(a, b);
    if (stlResult !== 0) return stlResult;
  
    // Sort by predefined list order if applicable
    if (aOrder && bOrder) {
      if (aOrder.listIndex !== bOrder.listIndex) {
        return aOrder.listIndex - bOrder.listIndex;
      }
      return aOrder.valueIndex - bOrder.valueIndex;
    }
  
    if (aOrder) return -1;
    if (bOrder) return 1;
  
    // Default alphabetical or numerical sorting for other cases
    if (isNaN(parseNumber(a)) || isNaN(parseNumber(b))) {
      return a.localeCompare(b);
    } else {
      return parseNumber(a) - parseNumber(b);
    }
  };
  
  
  

function useSortSizes(product) {
  const [sortedProduct, setSortedProduct] = useState(product);
  useEffect(() => {
    if (!product?.hasVariants) {
      return;
    }
    
    const sortingFunction = determineSortingFunction(product.variants.options[0].values);

    let sortedValues = [...product.variants.options[0].values].sort((a, b) => {

      //Check if a or b is in postdefinedOrderList
      const inPostdefinedA = postdefinedOrderList.includes(a);
      const inPostdefinedB = postdefinedOrderList.includes(b);
      // Sort within postDefinedOrderList based on their predefined order
      if (inPostdefinedA && inPostdefinedB) {
        return postdefinedOrderList.indexOf(a) - postdefinedOrderList.indexOf(b);
      }
      // Move postdefined values to the end
      if (inPostdefinedA && !inPostdefinedB) return 1;
      if (!inPostdefinedA && inPostdefinedB) return -1;

      return sortingFunction(a, b);
    });
    

    const newSortedProduct = {
      ...product,
      variants: {
        ...product.variants,
        options: [{...product.variants.options[0], values: sortedValues}],
        values: product.variants.values,
      },
    };

    setSortedProduct(newSortedProduct);
  }, [product]);

  return sortedProduct;
}


// Function to determine which sorting function to use
function determineSortingFunction(values) {
 
    const containsInchSymbol = str => str.includes('"') || str.includes("''");
    const isInPredefinedOrderList = str => predefinedOrderList.some(list => list.includes(str));
  
    if (values.some(val => containsInchSymbol(val))) {
      return sortInchSizes;
    }
    if (values.some(val => val.startsWith("Ø"))) {
      return sortDiameterSizes;
    }
    if (values.some(val => isInPredefinedOrderList(val))) {
      return sortPredefinedSizes;
    }
  
    return (a, b) => {
      if (isNaN(a) || isNaN(b)) {
        return a.localeCompare(b);
      } else {
        return parseFloat(a) - parseFloat(b);
      } 
    }  
  }
  

export default useSortSizes;
