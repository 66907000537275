import React, { Component } from 'react';
import { styled } from 'linaria/react';
import StandardPage from '../StandardPage/StandardPage';
import { customPageQuery } from './customPageQuery.gql';
import { Query } from 'react-apollo';
import LoadingPage from '../LoadingPage';
const Wrapper = styled('div')`
  iframe {
    min-height: 600px;
    display: block;
    margin: 0 auto;
    width: 1070px;
    max-width: 100%;
    overflow: hidden;
  }
`;

export default class Return extends Component {
  componentDidMount() {
    if (window.Easycom !== 'undefined') {
      const m = window.location.href.match(/#(.)+$/);
      if (m && m.length > 0) {
        const s = m[0].substr(1); // Remove leading "#"
        window.Easycom.init({ drToken: s });
      } else {
        const token = this.props.match.params.token
          ? this.props.match.params.token
          : '::newhouse';
        window.Easycom.init({
          drToken: btoa(token),
        });
      }
    }
  }
  render() {
    const id = 231;
    return (
      <Wrapper>
        <div>
          <Query query={customPageQuery} variables={{ id }}>
            {({ loading, data, error }) => {
              if (loading) return <LoadingPage />;
              if (error) return null;
              var temp = {
                data: {
                  route: {
                    object: data.page,
                  },
                },
              };
              return <StandardPage category={data.category} cacheHit={true} />;
            }}
          </Query>
        </div>
        <div style={{ margin: '0 10px' }}>
          <easycom-returns />
        </div>
        {/* <script src="https://returns.easycom.com/easycom-api.js" /> */}
        <script src="https://testreturns.easycom.com/easycom-api.js" />
      </Wrapper>
    );
  }
}
