import React from 'react';
import {  ProductCardWithStockStatus } from '../../CategoryPage/ProductCard';
import { styled } from 'linaria/react';
import { standardText } from './allcomponents';
import { cx } from 'linaria';
import { theme } from '../../Theme';

const ProductRowWrapper = styled('div')`
  max-width: 90rem;
  width: 100%;
  margin: 1rem auto;
  .text {
    max-width: 45rem;
    padding: 1rem;
    margin: auto;
  }
  a {
    color: black;
  }
  ul.products {
    max-width: 90rem;
    gap: 1rem;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(${props => props.perRow}, 1fr);
    li {
      width: 100%;
    }
    ${theme.below.lg} {
      grid-template-columns: repeat(3, 1fr);
      padding: 0 1rem;
    }
    ${theme.below.md} {
      grid-template-columns: repeat(2, 1fr);
    }
  }
`;

export const ProductRow = ({ children, text }) => {
  const perRow = children.length < 5 ? children.length : 5;

  return (
    <ProductRowWrapper perRow={perRow} className="contentComponent">
      {text?.value && (
        <div
          dangerouslySetInnerHTML={{ __html: text.value }}
          className={cx('text', standardText)}
        />
      )}
      <ul className="products">{children}</ul>
    </ProductRowWrapper>
  );
};

const ProductBox = ({ product }) => {
  if (!product.value) return null;
  return (
    <>
      <ProductCardWithStockStatus product={product.value} />
    </>
  );
};

export default ProductBox;
