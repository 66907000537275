import React from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
export const VideoWrapper = styled('div')`
  position: relative;
  padding-bottom: ${props => props.aspect + '%'}!important;
  padding-top: 0;
  height: 0;
  margin-bottom: 0px;
  pointer-events: none;
  
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
`;

function calculateAspectRatioPercentage(aspectRatioString) {
  const numbers = aspectRatioString?.split(':') ?? [1,1];
  const width = parseInt(numbers[0]);
  const height = parseInt(numbers[1]);
  const percentage = Math.round((height / width) * 100);
  return percentage;
}

const Video = ({ vimeoSrc, quality = '1080p', aspect }) => {
  let vUrl = vimeoSrc;

  //check if vimeo
  if (vimeoSrc?.includes('vimeo.com')) {
    const playerUrl = vimeoSrc.replace('vimeo.com/', 'player.vimeo.com/video/');
    vUrl = playerUrl + `?background=1&quality=${quality}`;
  } else {
    return null;
  }

  return (
    <VideoWrapper
      aspect={calculateAspectRatioPercentage(aspect)}
      className={cx('video','vimeo')}
    >
      <iframe src={vUrl} />
    </VideoWrapper>
  );
};

export default Video;
