import React, { useMemo, useEffect, useRef, useState } from 'react';
import { theme } from '../../../Theme';
import { ReactComponent as FilterIcon } from '../../../../svg/Filter.svg';
import { styled } from 'linaria/react';
import t, { Intl } from '@jetshop/intl';

const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  right: 2rem;
  ${theme.below.sm} {
    right: 0.75rem;
  }
`;

const Button = styled('button')`
  display: block;
  white-space: nowrap;
  color: #235837;
  border: 2px solid #235837;
  padding: 0.5rem 1rem;
  font-weight: bold;
  -webkit-text-decoration: none;
  text-decoration: none;
  background: transparent;
  transition: 0.2s ease-in-out;
  font-size: 0.75rem;
  padding: 0.5rem 0.5rem;
`;

const StyledFilterIcon = styled(FilterIcon)`
  background: ${theme.colors.blue};
  border-radius: 50%;
  padding: 10px;
  width: 40px;
  height: 40px;
  position: relative;
`;

const Badge = styled('div')`
  position: absolute;
  display: block;
  top: -7px;
  background: black;
  color: white;
  padding: 2px 4px;
  border-radius: 4px;
  left: 2px;
  font-size: 0.8rem;
  z-index: 2;
  line-height: 0.9rem;
`;

const IconWrapper = styled('div')`
  position: relative;
  pointer-events: auto;
  line-height: 0;
`;

const FilterButton = ({ showTarget, filters }) => {
  const numOfActiveFilters = useMemo(() => {
    let activeFilters = 0;
    filters.forEach(filter => {
      switch (filter.__typename) {
        case 'ListFilter':
        case 'MultiListFilter':
          if (filter.hasActiveItems) activeFilters++;
          break;
        case 'NumericRangeFilter':
          if (filter.isActive) activeFilters++;
          break;
        case 'BooleanFilter':
          if (filter.value) activeFilters++;
          break;
        default:
          break;
      }
    });

    return activeFilters;
  }, [filters]);

  return (
    <Button onClick={showTarget} className='filter-button'>
      <Intl>{t => `${t('Filter')} & ${t('Sort by')}`}</Intl>{' '}
      {numOfActiveFilters > 0 && `(${numOfActiveFilters})`}
    </Button>
  );
};

export default FilterButton;
