import { css } from 'linaria';
import { theme } from '../../../Theme';

export const contentPositionerStyle = css`
  position: relative;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  padding: 2rem;
  height: 100%;

  ${theme.below.lg} {
    padding: 2rem;
  }

  &.TOP {
    align-items: flex-start;
  }
  &.MIDDLE {
    align-items: center;
  }

  &.BOTTOM {
    align-items: flex-end;
  }

  &.LEFT {
    * {
      text-align: left;
      margin-left: 0;
      margin-right: auto;
    }
    justify-content: flex-start;
  }

  &.CENTER {
    justify-content: center;

    * {
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }
  }

  &.RIGHT {
    justify-content: flex-end;
    * {
      text-align: right;
      margin-left: auto;
      margin-right: 0;
    }
  }
`;
