import React from 'react';
import { ContentRenderer } from '@jetshop/ui/ContentRenderer';
import { useQuery } from 'react-apollo';
import { cx } from 'linaria';
import CategoryContentQuery from './CategoryContentQuery.gql';
import { allComponents } from '../allcomponents';
// import { useStyleApplier } from '../../ui/styles/useStyleApplier';

/**
 * The DynamicCategoryRenderer function is a React component that renders the content of a category.
 * It takes in an array of items and returns them as HTML elements.
 * @param {number} categoryId id for fetching correct category data from the api
 * @param {object} rendererComponents Overrides the default components
 * @param {object} extraRootProps Add extra props to the root component of the category
 * @param {object} extraItemProps} Add extra props to the items
 *
 * @return A div width rendered components
 *
 * @docauthor Anders Zetterström @ R3
 */
export const DynamicCategoryRenderer = ({ categoryId, rendererComponents }) => {
  const { data, error } = useQuery(CategoryContentQuery, {
    variables: {
      id: categoryId
    }
  });
  if (!data || error) {
    return null;
  }

  const items = data?.category?.data?.items;
  
  const components = rendererComponents ?? allComponents;

  return (
    <div
      className={cx('dynamic-category-wrapper')}
      style={{ width: '100%' }}
    >
      {items && <ContentRenderer items={items} components={components} />}
    </div>
  );
};
