import t from '@jetshop/intl';
import React from 'react';
import { styled } from "linaria/react";
import { Container as BaseContainer } from './styledComponents';
import { ProductGrid } from '../CategoryPage/ProductGrid';

import { theme } from "../Theme";

const Container = styled(BaseContainer)`
  background: transparent;
  padding: 0;
  padding-bottom: 3rem !important;
  h2 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0.75em;
    ${theme.below.md} {
      margin: auto;
    }
  }

  .product-grid {
    width: 100%;
    .product-card {
      width: 16.667%;
    }
    /* Let products overflow on smaller devices */
    ${theme.below.md} {
      overflow-x: auto;
      display: flex;
      flex-wrap: nowrap;
      .product-card {
        min-width: 34%;
        margin: 0 0.5em;
      }
    }
  }
  .product-card {
    font-size: 0.875em;
  }

  @supports (display: grid) {
    .product-grid {
      margin: 0;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: 1em;
      ${theme.below.lg} {
        grid-gap: 0.5em;
      }
      .product-card {
        width: auto;
      }
    }
  }
`;

export const RelatedProducts = ({
  products,
  loading,
  title = 'Related products',
}) => {
  if (!products || products.length === 0) return null;
  return (
    <Container>
      <h2>{t(title)}</h2>
      <ProductGrid
        listName="related-products"
        products={products}
        loading={loading}
        imageSizes={[1 / 3, 1 / 3, 1 / 6]}
      />
    </Container>
  );
};
