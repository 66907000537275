import CategoryLink from '@jetshop/ui/CategoryLink';
import UIMenuContainer from '@jetshop/ui/Menu/MenuContainer';
import React from 'react';
import { styled } from "linaria/react";
import SubMenuWrapper from './SubMenuWrapper';
import { theme } from "../../../Theme";
import { cleanTextString } from '../../../CategoryPage/CategoryHeader';

const Wrapper = styled('div')`
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
`;

const MainMenuItem = styled('li')`
  a {
    font-size: 15px;
    padding: 0px 0.75rem 18px;
    display: inline-block;
    color: ${theme.colors.primary};
    text-decoration: none;
    position: relative;

    &:after {
      content: '';
      height: 3px;
      background: ${theme.colors.accent};
      width: 100%;
      position: absolute;
      bottom: 10px;
      left: 1rem;
      right: 1rem;
      width: auto;
      opacity: 0;
      transition: all, 0.2s ease;
    }

    &:hover,
    &.active {
      color: ${theme.colors.accent};
      text-decoration: none!important;
      &:after {
        opacity: 1;
      }
    }
    ${theme.below.xl} {
      padding: 0px 0.5rem 18px;
    }
  }
`;


const CategoryMenuContainer = ({ queryData }) => {
  return (
    <UIMenuContainer>
      {props => (
        // When mousing outside the menu, close it
        <Wrapper onMouseLeave={props.clearActiveCategories}>
          <ul style={{ paddingTop: '20px' }}>
            {queryData && queryData.categories
              ? queryData.categories.map(cat =>
                  cat.id === 155 ? null : (
                    <MainMenuItem
                      key={cat.id}
                      // When mousing over a menu item, set it as the active nav
                      onClick={() => {
                        if (
                          cat.id !== 155 &&
                          cat.id !== 540
                        ) {
                          props.setActiveCategory(cat);
                        } else {
                          props.clearActiveCategories();
                        }
                        if( props?.isActiveCategory(cat) ) {
                          props.clearActiveCategories();
                        } 
                      }}
                    >
                      <CategoryLink
                        // When following a category link, close the menu
                        onClick={e => {
                          if (
                            cat.subcategories &&
                            cat.subcategories.length > 0 &&
                            cat.id !== 155 &&
                            cat.id !== 540
                          ) {
                            e.preventDefault();
                          } else {
                            props.clearActiveCategories();
                          }
                        }}
                        category={cat}
                        className={
                          (
                            (
                              props.activeCategories
                                ? props.activeCategories.length > 0
                                : null
                            )
                              ? props.activeCategories[0].id === cat.id
                              : null
                          )
                            ? 'active'
                            : null
                        }
                      >
                        {cleanTextString(cat.name)}
                      </CategoryLink>
                    </MainMenuItem>
                  )
                )
              : null}
          </ul>

          <SubMenuWrapper
            pose={
              props.activeCategories.length > 0 &&
              props.activeCategories[0].subcategories.length > 0
                ? 'open'
                : 'closed'
            }
            activeCategory={props.activeCategories[0]}
            closeNav={props.clearActiveCategories}
          />
        </Wrapper>
      )}
    </UIMenuContainer>
  );
};

export default CategoryMenuContainer;
