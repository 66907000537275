import React, { useState, useEffect } from 'react';
import { useExternalCustomer } from './useExternalCustomer';
import { withRouter } from 'react-router';
import queryString from 'query-string';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';

export const GlobalTokenContext = React.createContext({});

const GlobalTokenLogin = (props) => {
  const [url, setUrl] = useState(null);
  const [externalUser, setExternalUser] = useState(null);
  const { activateAndLoginExternalByToken } = useExternalCustomer();
  const { loggedIn } = useAuth();
  const values = queryString.parse(props.location.search);

  // useEffect(() => {
  //   if (!url && !loggedIn) {
  //     setUrl(props.location.pathname);
  //     if (values.eclub) {
  //       activateAndLoginExternalByToken(
  //         encodeURIComponent(values.eclub),
  //         setExternalUser
  //       );
  //     }
  //   }

  //   if (url && loggedIn) {
  //     props.history.push(url);
  //   }
  // }, [loggedIn]);

  // useEffect(() => {
  //   if (externalUser && externalUser.status === 'AdditionalUserDataRequired') {
  //     //props.history.push('/signup');
  //   }
  // }, [externalUser]);

  return (
    <GlobalTokenContext.Provider
      value={{ redirectUrl: url, externalUser, setExternalUser }}
    >
      {props.children}
    </GlobalTokenContext.Provider>
  );
};

export default withRouter(GlobalTokenLogin);
