import { theme } from "../Theme";
import ProductSpecifications from '@jetshop/ui/ProductSpecifications/ProductSpecifications';
import React from 'react';
import { styled } from "linaria/react";
import t from '@jetshop/intl';

const Description = styled('article')`
  margin-bottom: 42px;
  color: ${theme.colors.grey};
`;

export const SectionHeading = styled('h2')`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0.25em;
`;

export const ProductSection = styled('section')`
  line-height: 1.5;

  width: 100%;
  padding-bottom: 3rem;

  ${theme.below.md} {
    margin: 0;
  }

  ${theme.above.md} {
    width: ${`calc(50% - 16px)`};
  }
  ${theme.above.lg} {
    :nth-of-type(odd) {
      width: ${`calc(60% - 24px)`};
    }
    :nth-of-type(even) {
      width: ${`calc(40% - 24px)`};
    }
  }
  ul.payment-options-list {
    font-size: 16px;
    font-weight: 600;
  }

  .accordion-content {
    line-height: 1.5;
    font-size: 11px;
    margin-bottom: 24px;
    letter-spacing: 0.25px;
    p {
      margin-bottom: 1rem;
    }
    h2 {
      font-family: ${theme.fonts.body};
      font-size: 14px;
      font-weight: bold;
      margin-top: 1.5rem;
      &:first-of-type {
        margin-top: 0;
      }
    }
    h3,
    h4 {
      padding-top: 8px;
    }
    table tr th,
    table tr td {
      padding-right: 8px;
    }
  }
`;

const ProductInfo = ({ product }) => {
  return (
    <>
      <ProductSection>
        <SectionHeading>{t('Product description')}</SectionHeading>
        <Description
          dangerouslySetInnerHTML={{
            __html: product.description
          }}
        />
      </ProductSection>
      <ProductSection>
        <SectionHeading>{t('Specifications')}</SectionHeading>
        <ProductSpecifications fields={product.customFields} />
      </ProductSection>
    </>
  );
};

export default ProductInfo;
