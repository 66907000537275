import * as translations from '../translations';
import channelsQuery from './ChannelsQuery.gql';

/** Persisted query imports **/
import uspQuery from './components/Layout/Header/UspQuery.gql';
import homeCategoriesQuery from './components/Layout/Header/HomeCategoriesQuery.gql';
import FooterQuery from './components/Layout/Footer/FooterQuery.gql';
import InspirationQuery from './components/Layout/Footer/InspirationQuery.gql';
import { theme } from './components/Theme';

import routeQuery from './components/RouteQuery.gql';
import {
  LoadableProductPage,
  LoadableContentPage,
  LoadableCategoryPage
} from './components/Shop';

const config = {
  theme,
  apolloConfig: {
    shopid: process.env.REACT_APP_SHOP_ID || 'hooks',
    graphQLURI:
      process.env.REACT_APP_GRAPHQL_URI || 'https://storeapi.jetshop.io',
    token:
      process.env.REACT_APP_APOLLO_TOKEN ||
      '359fd7c1-8e72-4270-b899-2bda9ae6ef57',
    engineApiKey: process.env.ENGINE_API_KEY || '',
    enableGateway: false,
    channelsQuery
  },
  trackingID: process.env.REACT_APP_GA_TRACKING_ID,
  additionalGtagTrackingIds: [],
  tagManagerID: process.env.REACT_APP_GTM_CONTAINER_ID,
  relewareEnabled: true,

  intl: {
    translations,
    defaultLocale: 'en',
    options: {
      formats: {
        number: {
          EUR: {
            style: 'currency',
            currency: 'EUR'
          },
          SEK: {
            style: 'currency',
            currency: 'SEK'
          },
          NKR: {
            style: 'currency',
            currency: 'NOK',
            currencyDisplay: 'symbol'
          },
          DKR: {
            style: 'currency',
            currency: 'DKK',
            currencyDisplay: 'code'
          }
        }
      }
    }
  },
  persistedQueries: [
    {
      query: homeCategoriesQuery,
      variables: { levels: 1 }
    },
    {
      query: homeCategoriesQuery,
      variables: { levels: 3 }
    },
    {
      query: uspQuery
    },
    {
      query: FooterQuery
    },
    {
      query: InspirationQuery
    }
  ],

  channelOverrides: {},
  disableGeoRedirect: true,
  singleDomainMode: false,
  schemaExtensions: [],
  preserveRedirect: true,
  structuredData: {
    disableDefaultProductData: false
  },
  trackers: [],
  googleMapsApiKey:
    process.env.REACT_APP_GOOGLE_MAPS_API_KEY ||
    'AIzaSyCHHzR7UaxOfg2_yPRFRVI7LUOSCd1DPZQ',
  useNewFilterAPI: true,
  loginPath: 'login',
  deprecations: { useOldProductVariants: false },
  preload: {
    routeQuery,
    preloadComponents: {
      Product: [LoadableProductPage],
      Category: [LoadableCategoryPage],
      Page: [LoadableContentPage]
    }
  }
};

export default config;
