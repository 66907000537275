import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import { theme } from '../../Theme';
import React from 'react';
import { styled } from 'linaria/react';
import { ReactComponent as Cross } from '../../../svg/Cross.svg';
import { TrendLink } from '../../ui/Button';
import cartQuery from '../../Cart/CartQuery.gql';
import { ToastProductCard } from '../../CategoryPage/ToastProductCard';

const Container = styled('aside')`
  ${theme.above.sm} {
    width: 320px;
  }
  ${theme.below.sm} {
    width: 100%;
  }
  background-color: ${theme.colors.white};
  padding-bottom: 10px;
`;

const CheckoutButton = styled('a')`
  text-align: center;
  padding: 0 !important;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 38px;
  height: 42px;
  letter-spacing: 0.05em;
  font-weight: bold;
  background: ${theme.colors.accent};
  margin-left: 10px !important;
  margin-right: 10px;
  border: 2px solid ${theme.colors.accent};
  &:hover {
    color: ${theme.colors.accent}!important;
    background: white;
  }
  &:focus {
    outline: none;
  }
`;

const ProductCheckoutContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  a {
    text-decoration: none;
    :hover {
      opacity: 0.8;
      text-decoration: none;
      color: white;
    }
  }

  li.product-card {
    display: block;
    position: relative;
    & > a {
      padding-left: 0;
      padding-right: 0;
      margin-left: 10px;
      margin-right: 10px;
      display: block;
      position: relative;
    }
    .favorite-icon {
      display: none;
    }
  }
`;

const CloseButton = styled('button')`
  appearance: none;
  border-radius: 0;
  background: transparent;
  padding: 0 !important;
  position: absolute;
  top: 1rem;
  right: 15px;
  svg {
    width: 0.7rem;
    height: 0.7rem;
    font-size: 18px;
    use {
      fill: #000000;
    }
  }
`;

const Header = styled('h3')`
  font-size: ${theme.fontSizes[2]};
  color: #000;
  text-align: center;
  padding: 1rem;
  padding-bottom: 5px;
`;

const Error = styled('p')`
  color: red;
  margin-top: ${theme.space[1]};
  font-size: ${theme.fontSizes[0]};
`;

const ProductToast = ({
  product,
  cart,
  closeToast,
  selectedVariation,
  error
}) => {
  const pricing = selectedVariation || product;
  const track = useTracker();
  return (
    <Container data-testid="product-toast">
      <ProductCheckoutContainer>
        {error ? (
          <Header>{t('Failed adding to bag')}</Header>
        ) : (
          <Header>{t('Added to bag')}</Header>
        )}
        <CloseButton onClick={closeToast}>
          <Cross />
        </CloseButton>
        <ToastProductCard
          product={product}
          selectedVariation={selectedVariation}
        />
        {cart && cart.externalCheckoutUrl && (
          <ChannelContext.Consumer>
            {({ selectedChannel }) => (
              <CheckoutButton
                href={`${cart.externalCheckoutUrl}&channelCountry=${selectedChannel.country.code}`}
                onClick={() => {
                  track(trackCartCheckoutEvent({ cart }));
                }}
              >
                {t('To checkout')}
              </CheckoutButton>
            )}
          </ChannelContext.Consumer>
        )}
      </ProductCheckoutContainer>
    </Container>
  );
};
const ProductToastWrapper = props => (
  <CartProvider query={cartQuery}>
    {({ data }) => <ProductToast {...props} cart={data && data.cart} />}
  </CartProvider>
);

export default ProductToastWrapper;
