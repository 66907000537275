import { theme } from '../Theme';
import React, { useState } from 'react';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import { Below } from '@jetshop/ui/Breakpoints';
import t from '@jetshop/intl';
import { cx } from 'linaria';
const SubcategoryRow = styled('div')`
  display: flex;
  gap: 0.5rem;
  justify-content: center;
  flex-wrap: wrap;
  margin: 2rem auto;
  max-width: calc(1440px + 0.5rem);
  ${theme.below.lg} {
    .slick-list {
      border-radius: 20px;
    }
  }
  @media (max-width: 90rem) {
    padding: 0 0.75rem;
    max-width: calc(100% - 0.5rem);
  }
  .regular.slider {
    text-align: center;
  }
  .slick-arrow {
    position: absolute;
    height: 38px;
    width: 38px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 100%;
    background: #fff;
    display: none;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    cursor: pointer;
    transition: 0.2s;
    padding: 0;
    display: flex !important;

    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
    svg {
      height: 14px;

      use {
        fill: #000;
      }
    }
    &.slick-prev {
      left: 0px;

      svg {
        transform: rotate(90deg);
      }
    }
    &.slick-next {
      right: 0px;
      align-items: center;
      svg {
        transform: rotate(-90deg);
      }
    }
    &:hover {
      transform: translateY(-50%) scale(1.05);
      box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.3);
    }
    ${theme.below.lg} {
      &.slick-prev {
        left: -8px;
        svg {
          transform: rotate(90deg);
        }
      }
      &.slick-next {
        right: -8px;
        align-items: center;
        svg {
          transform: rotate(-90deg);
        }
      }
    }
  }

  .slick-slide > div {
    display: flex;
  }
  a,
  button {
    display: inline-block;
    white-space: nowrap;
    color: ${theme.colors.accent};
    border: 2px solid ${theme.colors.accent};
    padding: 0.5rem 1rem;
    font-weight: bold;
    text-decoration: none;

    background: transparent;
    transition: 0.2s ease-in-out;
    &.active {
      background: ${theme.colors.accent};
      color: #fff;
    }
    ${theme.below.lg} {
      font-size: 0.7rem;
      padding: 0.25rem 0.5rem;
    }
  }
  ${theme.below.md} {
    grid-area: 2 / 1 / 3 / 3;
    width: 100%;
    padding: 0;
    margin: 0;
    max-width: 100%;
    overflow: hidden;
    gap: 0.5rem;
    transition: 0.4s ease-in-out;
    &.active {
      max-height: 1000px;
    }
    &.extraPadding {
      padding-bottom: 2rem;
    }
    &.inactive {
      max-height: 0;
    }
  }
`;

const SubCatButton = styled('button')`
  display: block;
  white-space: nowrap;
  background: #235837;
  color: #fff;
  border: 2px solid #235837;
  padding: 0.5rem 1rem;
  font-weight: bold;
  -webkit-text-decoration: none;
  text-decoration: none;
  transition: 0.2s ease-in-out;
  font-size: 0.75rem;
  padding: 0.5rem 0.5rem;
  grid-area: 1 / 1 / 2 / 2;
`;

export const SubcategoryPills = ({
  category,
  keepDash = false,
  alwaysShow = false,
  extraPadding = false
}) => {
  const [showSubCats, setShowSubCats] = useState(true);
  const categorySort = (a, b) => {
    if (a?.name && b?.name) {
      if (a.name > b.name) return 1;
      if (a.name < b.name) return -1;
    }
    if (a?.text && b?.text) {
      if (a.text > b.text) return 1;
      if (a.text < b.text) return -1;
    }
    return 0;
  };
  const subCats = [...category?.subcategories];

  const toggleShowSubCats = () => {
    setShowSubCats(!showSubCats);
  };

  if (subCats && subCats.length > 0) {
    return (
      <>
        <Below breakpoint="md">
          {matches =>
            matches &&
            !alwaysShow && (
              <SubCatButton onClick={toggleShowSubCats}>
                {showSubCats ? t('Hide subcategories') : t('Show subcategories')}
              </SubCatButton>
            )
          }
        </Below>
        <SubcategoryRow
          className={cx(
            !showSubCats && !alwaysShow ? 'inactive' : 'active',
            extraPadding && 'extraPadding'
          )}
        >
          {subCats
            ?.sort(categorySort)
            .filter(cat => {
              if (keepDash) {
                return cat?.name?.startsWith('-');
              } else {
                return !cat?.name?.startsWith('-');
              }
            })
            ?.map(cat => {
              if (cat.name) {
                const categoryName = (
                  cat?.name?.startsWith('-')
                    ? cat?.name.replace('-', '')
                    : cat.name
                )
                  .replace('*auto*', '')
                  .trim();
                return (
                  <Link to={cat?.primaryRoute?.canonicalPath}>
                    {categoryName}
                  </Link>
                );
              }
            })}
        </SubcategoryRow>
      </>
    );
  }

  return null;
};
