import React, { useContext } from 'react';
import { useQuery } from 'react-apollo';
import { styled } from "linaria/react";

import { theme } from "../../Theme";
import { Link } from 'react-router-dom';

import MaxWidth from '../MaxWidth';
import NewsletterField from '../../Cart/Newsletter/NewsletterField';
import FooterQuery from './FooterQuery.gql';
import InspirationQuery from './InspirationQuery.gql';

import { ReactComponent as InstagramIcon } from '../../../svg/Instagram.svg';
import { ReactComponent as LinkedinIcon } from '../../../svg/Linkedin.svg';
import { ReactComponent as FacebookIcon } from '../../../svg/Facebook.svg';
import FooterLinks from './FooterLinks';
import ChannelContext from '@jetshop/core/components/ChannelContext';

import klarna from './footer-logos/klarna-1.png';
import postnord from './footer-logos/POSTNORD.png';
import trygg from './footer-logos/trygg_e-handel_hanglas_300.png';
import dkehandel from './footer-logos/DKehandel.png';
import visa from './footer-logos/VISA.png';
import mastercard from './footer-logos/mastercard.png';
import notrygg from './footer-logos/no-trygg-ehandel.png';
import posti from './footer-logos/posti.png';
import asml from './footer-logos/asml.png';
import ups from './footer-logos/ups.png';
import { useIntl } from '@jetshop/intl';

const PoweredByWrapper = styled('div')``;

const Wrapper = styled('div')`
  background: ${theme.colors.primary};
  padding-top: 80px;

  ${theme.below.md} {
    padding-top: 30px;
  }
`;

const StyledMaxWidth = styled(MaxWidth)`
  ${theme.below.md} {
    padding: 0 30px;
  }
`;

const Row = styled('div')`
  display: flex;
  justify-content: space-between;
  ${theme.below.lg} {
    flex-wrap: wrap;
    justify-content: center;
  }

  &.middle {
    align-items: center;
    & > div:first-of-type {
      display: flex;
      justify-content: center;
      align-items: center;
      ${theme.above.lg} {
        justify-content: end;
      }
      img {
        max-width: 100%;
        height: 100%;
        margin-left: 5px;
        margin-right: 10px;
        &.klarna {
          max-width: 62px;
        }
        &.trygg {
          max-width: 38px;
        }
        &.post {
          max-width: 76px;
        }
      }
    }
    ${theme.below.md} {
      & > div {
        margin-bottom: 2.5rem;
      }
    }
  }
`;
const Column = styled('div')`
  color: white;
  margin-bottom: 2rem;
  width: 25%;

  ${theme.below.lg} {
    width: 100%;
    text-align: center;
    &:last-child {
      width: 100%;
    }
  }

  a,
  a:visited {
    color: white;
    text-decoration: none;
  }
  h2 {
    font-size: 14px;
    line-height: 17px;
    text-align: left;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 5px;
    ${theme.below.lg} {
      letter-spacing: 0.1em;
    }
  }

  li a,
  p {
    font-size: 12px;
    line-height: 1.6;
    text-align: left;
    display: block;
  }
`;

const BottomRow = styled('div')`
  text-align: center;
  color: white;
  padding: 30px 10px;
  font-size: 12px;
  p {
    font-size: 12px;
  }
  ${theme.below.lg} {
    max-width: 230px;
    margin: auto;
  }

  ${theme.below.md} {
    padding-top: 0;
    padding-bottom: 20px;
    margin-bottom: 60px;
  }
`;

const SocialLink = styled('a')`
  margin: 10px 15px;
  display: block;
`;

const SocialLinkWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ChannelInfoImages = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    max-width: 100px !important;
    height: auto !important;
    max-height: 40px;
    margin: 0 4px;
  }

  svg {
    max-width: 100px !important;
    min-width: 70px;
    height: auto !important;
    max-height: 40px;
    margin: 0 4px;
  }
`;

export function BringLogo() {
  return (
    <svg viewBox="0 0 135 51" xmlns="http://www.w3.org/2000/svg">
      <g fill="none" fillRule="evenodd">
        <path
          className="logo-part-1"
          d="M89.174 21.286h4.58v-4.524h-4.58v4.524zm0 21.286h4.58V23.06h-4.58v19.512zm36.02-4.083c-3.405 0-4.095-1.243-4.095-5.806 0-4.564.69-5.965 4.094-5.965 3.933 0 4.988 1.08 4.988 5.964 0 4.883-1.055 5.805-4.988 5.805zm5.392-15.43v1.538c-1.54-1.36-4.053-1.882-6.162-1.882-6.81 0-7.905 4.044-7.905 9.968 0 4.684.445 9.888 7.945 9.888 1.905 0 4.256-.44 5.676-1.723 0 1 .042 2.41-.08 3.41-.244 1.84-2.15 2.642-4.784 2.642-2.555 0-3.362-.712-3.362-2.113h-4.99c0 4.964 4.5 5.955 8.472 5.955 3.65 0 8.435-.96 9.164-5.604.162-1.08.162-2.848.162-4.65V23.06h-4.136zm-24.17-.344c-2.796 0-4.58.88-5.715 1.882v-1.54H96.73v19.514h4.58v-9.088c0-3.402-.363-6.765 4.582-6.765 3.975 0 3.61 1.8 3.61 5.564V42.57h4.58V31.92c0-4.24.326-9.206-7.663-9.206zm-25.844 0c-2.312 0-4.136.72-5.434 2.083v-1.74h-3.972V42.57h4.58v-8.487c0-1.52-.08-3.682.448-5.084.485-1.32 1.662-2.32 3.567-2.32 1.907 0 2.663.4 2.663 2.202h4.554c0-4.284-2.148-6.166-6.405-6.166zM60.076 38.81c-3.934 0-5.07-1.082-5.07-5.965 0-4.886 1.136-5.965 5.07-5.965 3.405 0 4.134 1.4 4.134 5.965 0 4.563-.73 5.964-4.134 5.964zm1.012-16.094c-2.148 0-4.5.24-6 1.763v-7.73h-4.58V42.57h4.133v-1.56c1.218 1.08 3.084 1.88 5.72 1.88 7.904 0 8.43-5.282 8.43-10.045 0-5.925-.93-10.13-7.702-10.13z"
          fill="#7bc144"
        />
        <path
          className="logo-part-2"
          d="M13.003 33.26c-5.9 0-10.732-4.493-11.19-10.2H0c.472 11.834 10.333 21.286 22.435 21.286a22.5 22.5 0 0018.922-10.234A21.867 21.867 0 0044.87 23.06H24.192c-.458 5.707-5.29 10.2-11.19 10.2z"
          fill="#C1C1C1"
        />
        <path
          className="logo-part-3"
          d="M1.813 21.285c.458-5.707 5.29-10.2 11.19-10.2 5.898 0 10.73 4.493 11.19 10.2H44.87C44.397 9.45 34.536 0 22.435 0 10.333 0 .473 9.45 0 21.285h1.813"
          fill="#7bc144"
        />
      </g>
    </svg>
  );
}

const Socials = () => {
  const {selectedChannel,channels} = useContext(ChannelContext);

  const fbLinks = {
    'SV':'https://www.facebook.com/hookssverige',
    'NO':'https://www.facebook.com/hooksnorge',
    'DK':'https://www.facebook.com/hooksdanmark',
    'FI':'https://www.facebook.com/hookssuomi',
    'EU':'https://www.facebook.com/hookseurope',
    'NL':'https://www.facebook.com/hooksnederland'
  }

  return(
  <>
    <SocialLinkWrapper>
      <SocialLink
        href="https://www.instagram.com/hooksofficial/"
        target="_blank"
      >
        <InstagramIcon />
      </SocialLink>
      <SocialLink href={fbLinks[selectedChannel.name]} target="_blank">
        <FacebookIcon />
      </SocialLink>
      <SocialLink
        href="https://www.linkedin.com/company/h%C3%B6%C3%B6ks-h%C3%A4stsport"
        target="_blank"
      >
        <LinkedinIcon />
      </SocialLink>
    </SocialLinkWrapper>
  </>
)};

const Bottom = () => (
  <BottomRow>
    © Hööks.se 2020
    <PoweredByWrapper>
    </PoweredByWrapper>
  </BottomRow>
);

const Inspiration = () => {
  const { loading, data, error } = useQuery(InspirationQuery);
  const t = useIntl();
  if (loading) return null;
  if (error) return `Error!: ${error}`;
  return (
    <>
      <h2>{t('Inspiration')}</h2>
      <ul>
        <li>
          {data.category && data.category.primaryRoute && (
            <Link to={data.category.primaryRoute.path}>
              {data.category.name}
            </Link>
          )}
        </li>
        <li>
          {data.page && data.page.primaryRoute && (
            <Link to={data.page.primaryRoute.path}>{data.page.name}</Link>
          )}
        </li>
      </ul>
    </>
  );
};

// const CategoryColumn = ({ id }) => (
//   <Query query={FooterQuery} variables={{ id }}>
//     {({ loading, error, data }) => {
//       if (loading) return null;
//       if (error) return `Error!: ${error}`;
//       return (
//         <>
//           {data.category && (
//             <React.Fragment>
//               <h2>{data.category.name}</h2>
//               <ul>
//                 {data.category.subcategories.map((subcat, i) => (
//                   <li key={i}>
//                     <CategoryLink category={subcat}>{subcat.name}</CategoryLink>
//                   </li>
//                 ))}
//               </ul>
//             </React.Fragment>
//           )}
//         </>
//       );
//     }}
//   </Query>
// );

const AddressInfo = () => {
  const id = 1961;
  const { loading, data, error } = useQuery(FooterQuery, {
    variables: { id: id },
  });
  if (loading) return null;
  if (error) return `Error!: ${error}`;
  return (
    <>
      {data.category && data.category.content ? (
        <div
          dangerouslySetInnerHTML={{
            __html: data.category.content,
          }}
        />
      ) : null}
    </>
  );
};

const Footer = () => {
  const { selectedChannel } = useContext(ChannelContext);

  const getChannelLogos = () => {
    switch (selectedChannel.name) {
      case 'SV':
        return (
          <>
            <img src={klarna} />
            <img src={trygg} />
            <img src={postnord} />
          </>
        );

      case 'DK':
        return (
          <>
            <img src={klarna} />
            <img src={dkehandel} />
            <img className="post" src={postnord} />
          </>
        );

      case 'NO':
        return (
          <>
            <img src={klarna} />
            <img src={notrygg} />
            <BringLogo />
          </>
        );

      case 'FI':
        return (
          <>
            <img src={klarna} />
            <img src={posti} />
            <img src={asml} />
          </>
        );

      case 'EU':
        return (
          <>
            <img className="visa" src={visa} />
            <img className="mastercard" src={mastercard} />
            <BringLogo />
            <img className="ups" src={ups} />
          </>
        );

      default:
        break;
    }
  };

  return (
    <>
      <Wrapper>
        <StyledMaxWidth>
          <Row>
            <Column>
              <AddressInfo />
            </Column>

            <Column>
              <FooterLinks />
            </Column>

            <Column>
              <Inspiration />
            </Column>
          </Row>

          <Row className="middle">
            <Column>
              <ChannelInfoImages>{getChannelLogos()}</ChannelInfoImages>
            </Column>

            <Column>
              <NewsletterField />
            </Column>

            <Column>
              <Socials />
            </Column>
          </Row>
        </StyledMaxWidth>

        <Bottom />
      </Wrapper>
    </>
  );
};

export default Footer;
