export function badgePriority(myArr, prop) {
  let newArr = myArr?.filter((obj, pos, arr) => {
    return arr?.map(mapObj => mapObj[prop])?.indexOf(obj[prop]) === pos;
  });

  if (newArr?.find(t => t.text === 'SALE')) {
    newArr = newArr?.filter(t => t.text !== 'NOW');
  }

  return newArr;
}
