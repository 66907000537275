import React from 'react';
import CategoryMenuContainer from './CategoryMenuContainer';
import { styled } from 'linaria/react';
import { useQuery } from 'react-apollo';
import homeCategoriesQuery from '../HomeCategoriesQuery.gql';

const Nav = styled('nav')`
  button {
    background: none;
    color: #777;
  }
`;

const PosedMenuContainer = styled('div')``;

export function CategoryMenu() {
  const result = useQuery(homeCategoriesQuery, {
    variables: {
      levels: 1
    }
  });
  return (
    <Nav>
      <PosedMenuContainer key={'menuContainerPosed'}>
        <CategoryMenuContainer
          queryData={result?.data}
        />
      </PosedMenuContainer>
    </Nav>
  );
}
