import { styled } from 'linaria/react';
import React from 'react';

const IEBumperWrapper = styled('div')`
  display: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: url('https://www.hooks.se/pub_images/original/ie-background.jpg?v=2');
  background-color: white;
  background-size: cover;
  background-position: center center;
  z-index: -999999999999999999999999999999;
  flex-direction: column;
  align-items: center;
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    display: flex;
    z-index: 999999999999999999999999999999;
  }
  svg {
    margin-bottom: 1rem;
  }
  .textBox {
    font-weight: bold;
    padding: 3rem;
    p {
      text-align: center;
      &.fakeTitle {
        font-size: 72px;
      }
      a {
        color: black;
      }
    }
  }
`;

export const IEBumper = () => (
  <IEBumperWrapper>
    <div className="textBox">
      <p className="fakeTitle">BOM!</p>
      <p>Webbläsaren du använder är diskad.</p>
      <p>
        Vi rekommenderar dig att uppleva nya hemsidan med någon av följande
        webbläsare:
      </p>
      <p>
        <a target="_blank" href="https://www.google.com/chrome/" rel="noreferrer">
          Google Chrome
        </a>
        ,{' '}
        <a target="_blank" href="https://support.apple.com/downloads/safari" rel="noreferrer">
          Safari
        </a>
        ,&nbsp;
        <a target="_blank" href="https://www.mozilla.org/sv-SE/firefox/new/" rel="noreferrer">
          Firefox
        </a>
        &nbsp;eller&nbsp;
        <a target="_blank" href="https://www.microsoft.com/sv-se/edge" rel="noreferrer">
          Microsoft Edge.
        </a>
      </p>
    </div>
  </IEBumperWrapper>
);
