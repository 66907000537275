import { css } from 'linaria';
import React from 'react';
import { DropdownMenu } from '@jetshop/ui/DropdownMenu';
import { theme } from '../../Theme';

const menu = css`
  margin-bottom: 1em;
  font-size: 0.875rem;

  [data-flight-dropdown-items] {
    top: calc(100% - 1px);

    border: 1px solid ${theme.colors.lightgrey};
    border-radius: 0 0 3px 3px;
    z-index: 999;
    min-width: 100%;
    box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
    max-height: 280px;
    overflow: scroll;
  }
  [data-flight-dropdown-item] {
    overflow: hidden;
    display: block;
    padding: 0.75rem;
    border: none;
    min-width: 140px;
    width: 100%;
    text-align: left;
    background: transparent;
    display: flex;
    align-items: center;
    span + * {
    }
    &:hover {
      background: ${theme.colors.background};
      color: ${theme.colors.black};
    }
    .item-dot {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;
      span {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        border: 1px solid ${theme.colors.grey};
      }
      &.active-item {
        span {
          background: ${theme.colors.accent};
          border: 1px solid ${theme.colors.accent};
        }
      }
      svg {
        width: 12px;
        margin-left: 0;
        path {
          fill: white;
        }
      }
    }
    /* qty text */
    span + span {
      padding-left: 0.5em;
      font-size: 80%;
    }

    svg path {
      fill: black;
    }
  }

  [data-flight-dropdown-button] {
    padding: 0.75em;
    border: none;
    width: 100%;
    text-align: left;
    background: ${theme.colors.white};
    font-size: 14px;
    font-weight: normal;
    padding-right: 10px;
    border-radius: 3px;
    outline: none;
    display: flex;
    align-items: center;
    svg path {
      fill: black;
    }
    svg {
      margin-left: auto;
    }

    &[aria-expanded='true'] {
      border-radius: 3px 3px 0 0;
      border-color: ${theme.colors.blue};

      .carot {
        transform: rotate(180deg);
      }
    }
  }
`;

export function FilterDropdownMenu(props) {
  return <DropdownMenu className={menu} {...props} />;
}
