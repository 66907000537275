import React from 'react';
import UIButton from '@jetshop/ui/Button';
import { styled } from 'linaria/react';
import { Link } from 'react-router-dom';
import Spinner from './Spinner';
import t from '@jetshop/intl';
import { theme } from '../Theme';
import { isRelativeUrl } from '@jetshop/core/helpers/isRelativeUrl';


export const TrendButtonStyle = `
  background-color: white;
  border-color: white;
  color: black;
  font-size: 20px;
  font-weight: bold;
  border-radius: 0;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 53px;
  line-height: 51px;
  padding: 0 20px;
  display: inline-block;
  font-family: ${theme.fonts.primary};

  transition: all, 0.2s ease;

  &:hover {
    background: #e6e6e6;
    color: black;
  }

  &.primary {
    background: ${theme.colors.accent};
    color: white;
    border-color: ${theme.colors.accent};
    &:hover {
      background: ${theme.colors.lightgreen};
      color: white;
      border-color: ${theme.colors.lightgreen};
    }

    &.ghost {
    background: transparent;
    color: white;
    border: 1px solid white;
    &:hover {
      background: white;
      color: black;
    }
  }

  &.startPage {

  }

  svg {
    fill: black;
    height: 1em;
    width: 1em;
    margin-right: 1em;
  }
  ${theme.above.md} {
    max-width: 100%;
  }
  ${theme.below.md} {
    max-width: 100%;
    height:40px;
    line-height: 36px;
    font-size:1rem;
    padding: 0 .5rem;
  }

  :disabled {
    background: #f7f7f7;
    border: 1px solid #dedede;
    color: #808080;
    opacity: 0.7;
  }
`;

const TrendABase = styled('a')`
  ${TrendButtonStyle};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
`;

const TrendLinkBase = styled(Link)`
  ${TrendButtonStyle};
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 54px;
`;

export const Button = styled(UIButton)`
  ${TrendButtonStyle};
  font-family: ${theme.fonts.primary};
`;

export const TrendLink = styled(Link)`
  ${TrendButtonStyle};
  font-family: ${theme.fonts.primary};
`;

const StyledSpinner = styled(Spinner)`
  height: 25px;
  circle.path {
    stroke: white;
  }
`;

export function TrendLinkFunction({ to, ...props }) {
  return typeof to === 'string' && !isRelativeUrl(to) ? (
    <TrendABase href={to} {...props} />
  ) : (
    <TrendLinkBase to={to} {...props} />
  );
}

const ButtonWithLoading = ({
  loading,
  disabled,
  loadingText = t('Hold on a moment...'),
  isMax,
  ...props
}) =>
  loading ? (
    <Button {...props}>
      {loadingText}
      <StyledSpinner />
    </Button>
  ) : (
    <Button {...props} disabled={isMax} />
  );

export default ButtonWithLoading;
