import React from 'react';
import { css, cx } from "linaria";
import { toast } from 'react-toastify';
import { styled } from "linaria/react";

import uniqueId from '@jetshop/core/helpers/uniqueId';
import {
  useProductList,
  useProductListItems
} from '@jetshop/core/hooks/ProductList';
import { useAddMultipleToCart } from '@jetshop/core/hooks/useAddMultipleToCart';
import t from '@jetshop/intl';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';

import addMultipleToCartMutation from '../Cart/addMultipleToCart.gql';
import cartQuery from '../Cart/CartQuery.gql';

import { ReactComponent as Cart } from '../../svg/Cart.svg';
import { smallCaps } from '../ui/Headings';
import { theme } from '../Theme';
import Button from '../ui/Button';
import { Product } from './Product';
import ErrorBoundary from '@jetshop/ui/ErrorBoundary/Generic';
import PassThrough from '@jetshop/ui/ErrorBoundary/PassThrough';

const FavoriteList = styled('div')`
  background: #fff;
  height: calc(100% - 100px);
  ${theme.below.xl} {
    height: calc(100% - 116px);
  }
  ${theme.below.lg} {
    height: calc(100% - 92px);
  }
  ${theme.below.md} {
    height: calc(100% - 136px);
  }
  ${theme.below.sm} {
    height: calc(100% - 60px);
  }

  h2 {
    border-bottom: 1px solid #ddd;
    font-size: 1rem;
    padding: 1rem 0;
    text-align: center;
  }

  p {
    font-size: 14px;
    letter-spacing: 0.2px;
    line-height: 1.3;
    margin-top: calc(50vh - 108px - 3rem);
    padding: 0 1rem;
    text-align: center;
  }
`;

const StyledAddButton = styled(Button)`
  background: ${theme.colors.accent} !important;
  color: #fff !important;
  font-size: 16px;
  line-height: 1.2;
  height: fit-content;
  width: 100%;
  outline: none;
  padding: 1rem 1.5rem;
  text-transform: uppercase;
  transition: all ease 0.3s;
  font-size: 0.9rem;
  padding-left: 10px;
  padding-right: 10px;
  &:hover {
    background: #2b2b2b !important;
  }
`;

const StyledClearButton = styled(Button)`
  background: transparent;
  border: none;
  color: rgb(165, 165, 165) !important;
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0.15rem;
  outline: none;
  padding: 0;
  text-transform: uppercase;
  width: 100%;
  margin: 0;
  :hover {
    background: transparent;
  }
`;

const FlexedList = styled('div')`
  min-height: calc(100% + 100px);
  ${theme.below.xl} {
    min-height: calc(100% + 116px);
  }
  ${theme.below.lg} {
    min-height: calc(100% + 92px);
  }
  ${theme.below.md} {
    min-height: calc(100% + 136px);
  }
  ${theme.below.sm} {
    min-height: calc(100% + 60px);
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-content: space-between;

  .add-clear {
    padding: 20px;
  }
`;

const FavouritesWrapper = styled('div')`
  & > div {
    margin-top: 100px;
    height: calc(100% - 100px);
    z-index: 9;
    ${theme.below.xl} {
      margin-top: 116px;
      height: calc(100% - 116px);
    }
    ${theme.below.lg} {
      margin-top: 92px;
      height: calc(100% - 92px);
    }
    ${theme.below.md} {
      margin-top: 136px;
      height: calc(100% - 136px);
    }
    ${theme.below.sm} {
      margin-top: 60px;
      height: calc(100% - 60px);
    }
  }
`;

export const Favourites = () => (
  <FavouritesWrapper>
    <DrawerTarget id="favourites-drawer">
      {drawer => (
        <Drawer isOpen={drawer.isOpen} right>
          <RenderFavourites {...drawer} />
        </Drawer>
      )}
    </DrawerTarget>
  </FavouritesWrapper>
);

function RenderFavourites({ hideTarget }) {
  const { products, loading } = useProductListItems();

  const validItems = getItemsForAddToCart(products);
  const validItemCount = validItems.length;

  const [addToCart, { loading: mutationLoading }] = useAddMultipleToCart(
    validItems,
    {
      addMultipleToCartMutation,
      cartQuery
    },
    {
      onCompleted: () => {
        toast(
          <AddAllSuccessToast>
            <Cart />
            {t(
              '{productCount, plural, =1 {# product added to the cart.} other {# products added to the cart.}}',
              { productCount: validItemCount }
            )}
          </AddAllSuccessToast>,
          {
            toastId: uniqueId(),
            autoClose: 2000
          }
        );
      }
    }
  );

  const noProductsInList = products.length === 0 && !loading;
  const loadingInitialRender = products.length === 0 && loading;

  if (noProductsInList) {
    return (
      <FavoriteList className={cx(container, loading ? 'loading' : null)}>
        <h2>{t('Your favorites')}</h2>
        <p>
          {t('Add products by clicking the heart next to the product image.')}
        </p>
      </FavoriteList>
    );
  }

  if (loadingInitialRender) {
    return (
      <FavoriteList className={cx(container, loading ? 'loading' : null)}>
        <h2>{t('Your favorites')}</h2>
        <p style={{ marginTop: '1.5rem' }}>{t('Loading products...')}</p>
      </FavoriteList>
    );
  }

  return (
    <ErrorBoundary component={PassThrough} key={'favouritelist'}>
    <FavoriteList className={cx(container, loading ? 'loading' : null)}>
      <FlexedList>
        <div>
          <h2>{t('Your favorites')}</h2>

          <ul className="product-grid">
            {products.map((product, index) => {
              return (
                <li
                  key={product.variant?.articleNumber || product.articleNumber}
                  data-valid={product.validation.status}
                >
                  <Product product={product} hideTarget={hideTarget} />
                </li>
              );
            })}
          </ul>
        </div>

        <div className="add-clear">
          <StyledAddButton
            style={{ marginTop: '2em' }}
            onClick={addToCart}
            loading={mutationLoading}
            className="add-all"
            disabled={validItemCount === 0}
          >
            {validItemCount > 0
              ? mutationLoading
                ? t('One moment…')
                : t(
                    '{productCount, plural, =1 {Add # product to cart} other {Add # products to cart}}',
                    { productCount: validItemCount }
                  )
              : t('No valid items to add to cart')}
          </StyledAddButton>
          <ClearList>{t('Clear favorites')}</ClearList>
        </div>
      </FlexedList>
    </FavoriteList>
    </ErrorBoundary>
  );
}

function AddAllSuccessToast({ children }) {
  return <div className={addAllToastStyles}>{children}</div>;
}

function ClearList({ children }) {
  const { clear } = useProductList();
  return (
    <StyledClearButton secondary onClick={clear}>
      {children}
    </StyledClearButton>
  );
}

function getItemsForAddToCart(products) {
  // When adding to cart we only want valid items, and each articleNumber needs
  // to be the variant rather than the base product
  return products
    .filter(product => {
      return product.validation.status === 'valid';
    })
    .map(product => ({
      ...product,
      articleNumber: product.isVariant
        ? product.variant.articleNumber
        : product.articleNumber
    }));
}

const container = css`
  &.loading {
    opacity: 0.6;
  }

  .product-grid {
    margin-top: 0.5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;

    button {
      padding: 0.75rem 0.5em;
      // margin-left: 10px !important;
      // margin-right: 10px !important;
      // width: calc(100% - 20px);
    }

    li > button {
      width: calc(100% - 20px);
    }

    > * {
      min-width: 0;
      width: 100%;
    }
  }

  .product-card {
    a {
      margin: 0;
      border: 0;
    }
  }
  .product-card-detail,
  .product-card,
  .product-card a {
    background: white;
  }
    .price-wrapper{
    margin-top:.5rem;
  }
  li:not([data-valid='valid']) .product-card {
    background: rgba(255, 255, 255, 0.2);
    a {
      opacity: 0.5;
      background: rgba(255, 255, 255, 0.2);
    }
  }

  .select-variant {
    margin: 0;
    padding: 0 10px;
    position: relative;
    [data-flight-dropdown] {
      + [data-flight-dropdown] {
        margin-top: 0.5em;
      }
      button,
      ul,
      li {
        width: 100%;
        margin-left: 0 !important;
      }
      li {
        display: flex;
        justify-content: space-between;
      }
    }
  }
`;

const addAllToastStyles = css`
  background: white;
  color: ${theme.colors.black};
  padding: 1em;
  display: flex;
  align-items: center;

  svg {
    max-width: 1.5em;
    max-height: 1.5em;
    margin-right: 0.5em;
  }
`;
