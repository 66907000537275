import React from 'react';
import { styled } from "linaria/react";
import { theme } from '../Theme';
import Button from '../ui/Button';

import IntlContext from '@jetshop/intl/context';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { StyledCarrot } from '@jetshop/ui/Select/Components';

const NewsletterFieldWrapper = styled('div')``;

const MailChimpSubmit = styled(Button)`
  background-color: ${theme.colors.primary};
  display: flex;
  margin-bottom: 20px;
  justify-content: center;
  text-align: center;
  text-decoration: none !important;
  text-transform: uppercase;
  width: 100%;
  max-width: 300px !important;
  font-family: ${theme.fonts.body};
  letter-spacing: 0.15em;
  font-weight: bold;
  font-size: 14px;
  padding: 0;
  height: 60px;
  color: white;
  margin: 3rem auto 0 auto;
  &:hover {
    background-color: ${theme.colors.primary};
    color: white;
  }
  &:focus {
    outline: none;
  }
`;

const MailChimpWrapper = styled('div')``;

const Field = styled('div')`
  display: block;
  margin-bottom: 20px;

  label {
    display: block;
    width: 100%;
    font-size: 12px;
  }

  input {
    font-family: ${theme.fonts.primary};
    font-weight: normal;
    font-size: 16px;
  }

  input[type='text'],
  input[type='email'] {
    height: 40px;
    background: #fcfcfc;
    border: 1px solid #dedede;
    font-size: 16px;
    padding: 10px;
    width: 100%;
    margin-bottom: 0.25rem;
    padding-right: 40px;
    font-weight: normal;
    &:focus {
      outline: none;
      background: white;
    }
  }

  select {
    height: 40px;
    background: #fcfcfc;
    border: 1px solid #dedede;
    font-weight: normal;
    font-size: 16px;
    padding: 10px;
    width: 100%;
    margin-bottom: 0.25rem;
    padding-right: 40px;
    appearance: none;
    border-radius: 0;
    &:focus {
      outline: none;
    }
  }

  .select-field {
    position: relative;
    svg {
      position: absolute;
      right: 15px;
      bottom: 20px;
      margin: 0;
    }
  }

  .datefield {
    span {
      font-size: 12px;
    }
    .monthfield input {
      margin-right: 5px;
    }
    .dayfield input {
      margin-left: 5px;
      margin-right: 5px;
    }
    input {
      width: auto;
      padding: 0;
      width: 50px;
      text-align: center;
    }
  }

  .mc-address-group {
    > div {
      margin-bottom: 20px;
    }
  }
`;
const Column = styled('div')`
  width: 100%;
  &:first-of-type {
    margin-right: 2rem;
  }
`;
const Group = styled('div')`
  display: flex;
  justify-content: space-between;
`;

const MailChimp = () => (
  <React.Fragment>
    <MailChimpWrapper id="mc_embed_signup">
      <form
        action="https://newhouse.us20.list-manage.com/subscribe/post?u=6e8d99c5bbff4a7700e6f5347&amp;id=fd34f69544"
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank"
        noValidate
      >
        <div id="mc_embed_signup_scroll">
          <div className="mc-field-group">
            <IntlContext.Consumer>
              {t => (
                <React.Fragment>
                  <Group>
                    <Column>
                      <Field>
                        <label for="mce-EMAIL">
                          {t('Email Address')}
                          <span className="asterisk">*</span>
                        </label>
                        <input
                          type="email"
                          name="EMAIL"
                          className="required email"
                          id="mce-EMAIL"
                        />
                      </Field>

                      <Field>
                        <label for="mce-FNAME">
                          {t('First Name')}
                          <span className="asterisk">*</span>
                        </label>
                        <input
                          type="text"
                          name="FNAME"
                          className="required email"
                          id="mce-FNAME"
                        />
                      </Field>

                      <Field>
                        <label for="mce-LNAME">
                          {t('Last Name')}
                          <span className="asterisk">*</span>
                        </label>
                        <input
                          type="text"
                          name="LNAME"
                          className="required email"
                          id="mce-LNAME"
                        />
                      </Field>

                      <Field>
                        <label for="mce-PHONE">{t('Phone Number')}</label>
                        <input type="text" name="PHONE" id="mce-PHONE" />
                      </Field>

                      <Field>
                        <label for="mce-BIRTHDAY-month">
                          {t('Birthday')} (mm/dd)
                        </label>
                        <div className="datefield">
                          <span className="subfield monthfield">
                            <input
                              className="birthday "
                              type="text"
                              pattern="[0-9]*"
                              placeholder="MM"
                              size="2"
                              maxlength="2"
                              name="BIRTHDAY[month]"
                              id="mce-BIRTHDAY-month"
                            />
                          </span>
                          /
                          <span className="subfield dayfield">
                            <input
                              className="birthday "
                              type="text"
                              pattern="[0-9]*"
                              placeholder="DD"
                              size="2"
                              maxlength="2"
                              name="BIRTHDAY[day]"
                              id="mce-BIRTHDAY-day"
                            />
                          </span>
                          {/* <span className="small-meta nowrap">( mm / dd )</span> */}
                        </div>
                      </Field>

                      <Field>
                        <div className="mc-field-group select-field">
                          <label for="mce-MMERGE6">Select store </label>
                          <select name="MMERGE6" id="mce-MMERGE6">
                            <option></option>
                            <option value="Newhouse, NK, Stockholm">
                              Newhouse, NK, Stockholm
                            </option>
                            <option value="Newhouse, NK, Göteborg">
                              Newhouse, NK, Göteborg
                            </option>
                            <option value="Newhouse, Vallgatan 26, Göteborg">
                              Newhouse, Vallgatan 26, Göteborg
                            </option>
                            <option value="Newhouse, Stortorget 18, Helsingborg">
                              Newhouse, Stortorget 18, Helsingborg
                            </option>
                            <option value="Newhouse, Stockholm Quality Outlet, Järfälla">
                              Newhouse, Stockholm Quality Outlet, Järfälla
                            </option>
                            <option value="Newhouse, Lantmätargränd 11, Jönköping">
                              Newhouse, Lantmätargränd 11, Jönköping
                            </option>
                            <option value="Newhouse, Västra Storgatan 20A, Kristianstad">
                              Newhouse, Västra Storgatan 20A, Kristianstad
                            </option>
                            <option value="Newhouse, Nygatan 32, Linköping">
                              Newhouse, Nygatan 32, Linköping
                            </option>
                            <option value="Newhouse, Lilla Fiskaregatan 11, Lund">
                              Newhouse, Lilla Fiskaregatan 11, Lund
                            </option>
                            <option value="Newhouse, Hansacompagniet, Malmö">
                              Newhouse, Hansacompagniet, Malmö
                            </option>
                            <option value="Newhouse, Åhléns City, Stockholm">
                              Newhouse, Åhléns City, Stockholm
                            </option>
                            <option value="Newhouse, Nybrogatan 24, Stockholm">
                              Newhouse, Nybrogatan 24, Stockholm
                            </option>
                            <option value="Newhouse, Fältöversten, Stockholm">
                              Newhouse, Fältöversten, Stockholm
                            </option>
                            <option value="Newhouse, Galleria Dragarbrunn, Uppsala">
                              Newhouse, Galleria Dragarbrunn, Uppsala
                            </option>
                            <option value="Newhouse, Storgatan 9, Växjö">
                              Newhouse, Storgatan 9, Växjö
                            </option>
                          </select>
                          <StyledCarrot />
                        </div>
                      </Field>
                    </Column>

                    <Column>
                      <Field>
                        <div className="mc-address-group">
                          <div className="mc-field-group">
                            <label for="mce-ADDRESS-addr1">
                              {t('Address')}{' '}
                            </label>
                            <input
                              type="text"
                              maxlength="70"
                              name="ADDRESS[addr1]"
                              id="mce-ADDRESS-addr1"
                            />
                          </div>
                          <div className="mc-field-group">
                            <label for="mce-ADDRESS-addr2">
                              {t('Address Line 2')}
                            </label>
                            <input
                              type="text"
                              maxlength="70"
                              name="ADDRESS[addr2]"
                              id="mce-ADDRESS-addr2"
                            />
                          </div>
                          <div className="mc-field-group size1of2">
                            <label for="mce-ADDRESS-city">{t('City')}</label>
                            <input
                              type="text"
                              maxlength="40"
                              name="ADDRESS[city]"
                              id="mce-ADDRESS-city"
                            />
                          </div>
                          <div className="mc-field-group size1of2">
                            <label for="mce-ADDRESS-state">
                              {t('State/Province/Region')}
                            </label>
                            <input
                              type="text"
                              maxlength="20"
                              name="ADDRESS[state]"
                              id="mce-ADDRESS-state"
                            />
                          </div>
                          <div className="mc-field-group size1of2">
                            <label for="mce-ADDRESS-zip">
                              {t('Postal / Zip Code')}
                            </label>
                            <input
                              type="text"
                              maxlength="10"
                              name="ADDRESS[zip]"
                              id="mce-ADDRESS-zip"
                            />
                          </div>
                          <div className="mc-field-group size1of2 select-field">
                            <label for="mce-ADDRESS-country">
                              {t('Country')}
                            </label>
                            <select
                              name="ADDRESS[country]"
                              id="mce-ADDRESS-country"
                            >
                              <option value="164">USA</option>
                              <option value="286">Aaland Islands</option>
                              <option value="274">Afghanistan</option>
                              <option value="2">Albania</option>
                              <option value="3">Algeria</option>
                              <option value="178">American Samoa</option>
                              <option value="4">Andorra</option>
                              <option value="5">Angola</option>
                              <option value="176">Anguilla</option>
                              <option value="175">Antigua And Barbuda</option>
                              <option value="6">Argentina</option>
                              <option value="7">Armenia</option>
                              <option value="179">Aruba</option>
                              <option value="8">Australia</option>
                              <option value="9">Austria</option>
                              <option value="10">Azerbaijan</option>
                              <option value="11">Bahamas</option>
                              <option value="12">Bahrain</option>
                              <option value="13">Bangladesh</option>
                              <option value="14">Barbados</option>
                              <option value="15">Belarus</option>
                              <option value="16">Belgium</option>
                              <option value="17">Belize</option>
                              <option value="18">Benin</option>
                              <option value="19">Bermuda</option>
                              <option value="20">Bhutan</option>
                              <option value="21">Bolivia</option>
                              <option value="325">
                                Bonaire, Saint Eustatius and Saba
                              </option>
                              <option value="22">Bosnia and Herzegovina</option>
                              <option value="23">Botswana</option>
                              <option value="181">Bouvet Island</option>
                              <option value="24">Brazil</option>
                              <option value="180">Brunei Darussalam</option>
                              <option value="25">Bulgaria</option>
                              <option value="26">Burkina Faso</option>
                              <option value="27">Burundi</option>
                              <option value="28">Cambodia</option>
                              <option value="29">Cameroon</option>
                              <option value="30">Canada</option>
                              <option value="31">Cape Verde</option>
                              <option value="32">Cayman Islands</option>
                              <option value="33">
                                Central African Republic
                              </option>
                              <option value="34">Chad</option>
                              <option value="35">Chile</option>
                              <option value="36">China</option>
                              <option value="185">Christmas Island</option>
                              <option value="37">Colombia</option>
                              <option value="204">Comoros</option>
                              <option value="38">Congo</option>
                              <option value="183">Cook Islands</option>
                              <option value="268">Costa Rica</option>
                              <option value="275">Cote D'Ivoire</option>
                              <option value="40">Croatia</option>
                              <option value="276">Cuba</option>
                              <option value="298">Curacao</option>
                              <option value="41">Cyprus</option>
                              <option value="42">Czech Republic</option>
                              <option value="318">
                                Democratic Republic of the Congo
                              </option>
                              <option value="43">Denmark</option>
                              <option value="44">Djibouti</option>
                              <option value="289">Dominica</option>
                              <option value="187">Dominican Republic</option>
                              <option value="45">Ecuador</option>
                              <option value="46">Egypt</option>
                              <option value="47">El Salvador</option>
                              <option value="48">Equatorial Guinea</option>
                              <option value="49">Eritrea</option>
                              <option value="50">Estonia</option>
                              <option value="51">Ethiopia</option>
                              <option value="189">Falkland Islands</option>
                              <option value="191">Faroe Islands</option>
                              <option value="52">Fiji</option>
                              <option value="53">Finland</option>
                              <option value="54">France</option>
                              <option value="193">French Guiana</option>
                              <option value="277">French Polynesia</option>
                              <option value="56">Gabon</option>
                              <option value="57">Gambia</option>
                              <option value="58">Georgia</option>
                              <option value="59">Germany</option>
                              <option value="60">Ghana</option>
                              <option value="194">Gibraltar</option>
                              <option value="61">Greece</option>
                              <option value="195">Greenland</option>
                              <option value="192">Grenada</option>
                              <option value="196">Guadeloupe</option>
                              <option value="62">Guam</option>
                              <option value="198">Guatemala</option>
                              <option value="270">Guernsey</option>
                              <option value="63">Guinea</option>
                              <option value="65">Guyana</option>
                              <option value="200">Haiti</option>
                              <option value="66">Honduras</option>
                              <option value="67">Hong Kong</option>
                              <option value="68">Hungary</option>
                              <option value="69">Iceland</option>
                              <option value="70">India</option>
                              <option value="71">Indonesia</option>
                              <option value="278">Iran</option>
                              <option value="279">Iraq</option>
                              <option value="74">Ireland</option>
                              <option value="323">Isle of Man</option>
                              <option value="75">Israel</option>
                              <option value="76">Italy</option>
                              <option value="202">Jamaica</option>
                              <option value="78">Japan</option>
                              <option value="288">
                                Jersey (Channel Islands)
                              </option>
                              <option value="79">Jordan</option>
                              <option value="80">Kazakhstan</option>
                              <option value="81">Kenya</option>
                              <option value="203">Kiribati</option>
                              <option value="82">Kuwait</option>
                              <option value="83">Kyrgyzstan</option>
                              <option value="84">
                                Lao People's Democratic Republic
                              </option>
                              <option value="85">Latvia</option>
                              <option value="86">Lebanon</option>
                              <option value="87">Lesotho</option>
                              <option value="88">Liberia</option>
                              <option value="281">Libya</option>
                              <option value="90">Liechtenstein</option>
                              <option value="91">Lithuania</option>
                              <option value="92">Luxembourg</option>
                              <option value="208">Macau</option>
                              <option value="93">Macedonia</option>
                              <option value="94">Madagascar</option>
                              <option value="95">Malawi</option>
                              <option value="96">Malaysia</option>
                              <option value="97">Maldives</option>
                              <option value="98">Mali</option>
                              <option value="99">Malta</option>
                              <option value="207">Marshall Islands</option>
                              <option value="210">Martinique</option>
                              <option value="100">Mauritania</option>
                              <option value="212">Mauritius</option>
                              <option value="241">Mayotte</option>
                              <option value="101">Mexico</option>
                              <option value="102">Moldova, Republic of</option>
                              <option value="103">Monaco</option>
                              <option value="104">Mongolia</option>
                              <option value="290">Montenegro</option>
                              <option value="294">Montserrat</option>
                              <option value="105">Morocco</option>
                              <option value="106">Mozambique</option>
                              <option value="242">Myanmar</option>
                              <option value="107">Namibia</option>
                              <option value="108">Nepal</option>
                              <option value="109">Netherlands</option>
                              <option value="110">Netherlands Antilles</option>
                              <option value="213">New Caledonia</option>
                              <option value="111">New Zealand</option>
                              <option value="112">Nicaragua</option>
                              <option value="113">Niger</option>
                              <option value="114">Nigeria</option>
                              <option value="217">Niue</option>
                              <option value="214">Norfolk Island</option>
                              <option value="272">North Korea</option>
                              <option value="116">Norway</option>
                              <option value="117">Oman</option>
                              <option value="118">Pakistan</option>
                              <option value="222">Palau</option>
                              <option value="282">Palestine</option>
                              <option value="119">Panama</option>
                              <option value="219">Papua New Guinea</option>
                              <option value="120">Paraguay</option>
                              <option value="121">Peru</option>
                              <option value="122">Philippines</option>
                              <option value="221">Pitcairn</option>
                              <option value="123">Poland</option>
                              <option value="124">Portugal</option>
                              <option value="126">Qatar</option>
                              <option value="315">Republic of Kosovo</option>
                              <option value="127">Reunion</option>
                              <option value="128">Romania</option>
                              <option value="129">Russia</option>
                              <option value="130">Rwanda</option>
                              <option value="205">Saint Kitts and Nevis</option>
                              <option value="206">Saint Lucia</option>
                              <option value="324">Saint Martin</option>
                              <option value="237">
                                Saint Vincent and the Grenadines
                              </option>
                              <option value="132">Samoa (Independent)</option>
                              <option value="227">San Marino</option>
                              <option value="255">Sao Tome and Principe</option>
                              <option value="133">Saudi Arabia</option>
                              <option value="134">Senegal</option>
                              <option value="326">Serbia</option>
                              <option value="135">Seychelles</option>
                              <option value="136">Sierra Leone</option>
                              <option value="137">Singapore</option>
                              <option value="302">Sint Maarten</option>
                              <option value="138">Slovakia</option>
                              <option value="139">Slovenia</option>
                              <option value="223">Solomon Islands</option>
                              <option value="140">Somalia</option>
                              <option value="141">South Africa</option>
                              <option value="257">
                                South Georgia and the South Sandwich Islands
                              </option>
                              <option value="142">South Korea</option>
                              <option value="311">South Sudan</option>
                              <option value="143">Spain</option>
                              <option value="144">Sri Lanka</option>
                              <option value="293">Sudan</option>
                              <option value="146">Suriname</option>
                              <option value="225">
                                Svalbard and Jan Mayen Islands
                              </option>
                              <option value="147">Swaziland</option>
                              <option value="148" selected>
                                Sweden
                              </option>
                              <option value="149">Switzerland</option>
                              <option value="285">Syria</option>
                              <option value="152">Taiwan</option>
                              <option value="260">Tajikistan</option>
                              <option value="153">Tanzania</option>
                              <option value="154">Thailand</option>
                              <option value="233">Timor-Leste</option>
                              <option value="155">Togo</option>
                              <option value="232">Tonga</option>
                              <option value="234">Trinidad and Tobago</option>
                              <option value="156">Tunisia</option>
                              <option value="157">Turkey</option>
                              <option value="158">Turkmenistan</option>
                              <option value="287">
                                Turks &amp; Caicos Islands
                              </option>
                              <option value="159">Uganda</option>
                              <option value="161">Ukraine</option>
                              <option value="162">United Arab Emirates</option>
                              <option value="262">United Kingdom</option>
                              <option value="163">Uruguay</option>
                              <option value="165">Uzbekistan</option>
                              <option value="239">Vanuatu</option>
                              <option value="166">
                                Vatican City State (Holy See)
                              </option>
                              <option value="167">Venezuela</option>
                              <option value="168">Vietnam</option>
                              <option value="169">
                                Virgin Islands (British)
                              </option>
                              <option value="238">Virgin Islands (U.S.)</option>
                              <option value="188">Western Sahara</option>
                              <option value="170">Yemen</option>
                              <option value="173">Zambia</option>
                              <option value="174">Zimbabwe</option>
                            </select>
                            <StyledCarrot />
                          </div>
                        </div>
                      </Field>
                    </Column>
                  </Group>

                  <div style={{ display: 'none' }}>
                    <ChannelContext.Consumer>
                      {({ selectedChannel }) => {
                        return (
                          <React.Fragment>
                            <div className="mc-field-group input-group">
                              <strong>Channel </strong>
                              <ul>
                                <li>
                                  {selectedChannel.name === 'B2C-sv' ? (
                                    <input
                                      type="radio"
                                      value="SE"
                                      name="MMERGE7"
                                      id="mce-MMERGE7-0"
                                      checked="checked"
                                      readOnly
                                    />
                                  ) : (
                                    <input
                                      type="radio"
                                      value="SE"
                                      name="MMERGE7"
                                      id="mce-MMERGE7-0"
                                      readOnly
                                    />
                                  )}
                                  <label for="mce-MMERGE7-0">SE</label>
                                </li>
                                <li>
                                  {selectedChannel.name !== 'B2C-sv' ? (
                                    <input
                                      type="radio"
                                      value="EN"
                                      name="MMERGE7"
                                      id="mce-MMERGE7-1"
                                      checked="checked"
                                      readOnly
                                    />
                                  ) : (
                                    <input
                                      type="radio"
                                      value="EN"
                                      name="MMERGE7"
                                      id="mce-MMERGE7-1"
                                      readOnly
                                    />
                                  )}
                                  <label for="mce-MMERGE7-1">EN</label>
                                </li>
                              </ul>
                            </div>
                            <div className="mc-field-group input-group">
                              <strong>Kundtyp </strong>
                              <ul>
                                <li>
                                  <input
                                    type="radio"
                                    value="1"
                                    name="group[5657]"
                                    id="mce-group[5657]-5657-0"
                                  />
                                  <label for="mce-group[5657]-5657-0">
                                    Prenumerant
                                  </label>
                                </li>
                                <li>
                                  <input
                                    type="radio"
                                    value="2"
                                    name="group[5657]"
                                    id="mce-group[5657]-5657-1"
                                    readOnly
                                    checked="checked"
                                  />
                                  <label for="mce-group[5657]-5657-1">
                                    VIP
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </React.Fragment>
                        );
                      }}
                    </ChannelContext.Consumer>
                  </div>

                  <Field>
                    <MailChimpSubmit
                      onClick={() =>
                        document
                          .getElementById('mc-embedded-subscribe-form')
                          .submit()
                      }
                      id="mc-embedded-subscribe"
                    >
                      {t('Submit')}
                    </MailChimpSubmit>
                  </Field>

                  <Field>
                    <div id="mce-responses" className="clear">
                      <div className="response" id="mce-error-response" />
                      <div className="response" id="mce-success-response" />
                    </div>
                  </Field>
                </React.Fragment>
              )}
            </IntlContext.Consumer>
          </div>
        </div>
      </form>
    </MailChimpWrapper>
  </React.Fragment>
);

const CustomerClubForm = () => {
  return (
    <NewsletterFieldWrapper>
      <IntlContext.Consumer>{t => <MailChimp />}</IntlContext.Consumer>
    </NewsletterFieldWrapper>
  );
};

export default CustomerClubForm;
