import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackListEvent } from '@jetshop/core/analytics/tracking';
import React, { useEffect } from 'react';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { Price } from '../Price';
import { ProductCard } from './ProductCard';
import { LargeProductCard } from './LargeProductCard';
import { Above, Between } from '@jetshop/ui/Breakpoints';

import { theme } from '../Theme';

const Wrapper = styled('ul')`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: stretch;
  margin-right: -${theme.space[1]};
  margin-left: -${theme.space[1]};
  width: 100%;

  .new-price {
    color: #b13827;
    margin-right: 8px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-family: 'RedRose';
    font-size: 1.5rem;
    line-height: 1rem;
  }
  .price {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-family: 'RedRose';
    font-size: 1.5rem;
    line-height: 1rem;
    margin-right: 0;
  }
  .old-price {
    color: ${theme.colors.grey};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .product-card {
    line-height: 19px;
    min-width: 0;
    /* 
       * For browsers that do not support grid, we need to specify the width of
       * each product
       */
    width: 50%;
    ${theme.above.md} {
      width: 33.333%;
    }
    ${theme.above.lg} {
      width: 25%;
    }
    a {
      margin: 0 0.5em 2em 0.5em;
    }
  }
  

  @supports (display: grid) {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 1em;
    grid-row-gap: 1em;
    margin-bottom: 2em;
    margin-right: 0px;
    margin-left: 0px;
    .product-card {
      width: auto;
      a {
        margin: 0;
      }
    }
    .big-card01 {
      width: 100%;
      grid-area: ${props => props?.gridAreasCard01?.desktop || 'auto'};
    }
    .big-card02 {
      width: 100%;
      grid-area: ${props => props?.gridAreasCard02?.desktop || 'auto'};
    }

    ${theme.below.lg} {
      grid-template-columns: repeat(3, 1fr);
      .big-card01 {
        grid-area: ${props => props?.gridAreasCard01?.tablet || 'auto'};
      }
      .big-card02 {
        grid-area: ${props => props?.gridAreasCard02?.tablet || 'auto'};
      }
    }

    ${theme.below.md} {
      grid-template-columns: repeat(2, 1fr);
      .big-card01 {
        grid-area: ${props => props?.gridAreasCard01?.mobile || 'auto'};
      }
      .big-card02 {
        grid-area: ${props => props?.gridAreasCard02?.mobile || 'auto'};
      }
    }
  }
`;

export function ProductGrid({
  id,
  products,
  prevOffset,
  listName,
  loading,
  className,
  content,
  ...rest
}) {
  const track = useTracker();
  useEffect(() => {
    // Dont track anything if there are no products to render
    if (!products || products.length === 0) return;

    // Otherwise track a list view event
    track(trackListEvent({ listName, products }));
  }, [listName, products, track]);

  const hasLargeCard = content && content?.length > 0;
  const largeCard01 = content?.[0]?.properties?.find( p => p?.name === 'product' )?.value;
  const largeCard01Image = content?.[0]?.properties?.find( p => p?.name === 'image' )?.value?.value;
  const largeCard01Position = content?.[0]?.properties?.find( p => p?.name === 'position' )?.value?.value;

  const largeCard02 = content?.[1]?.properties?.find( p => p.name === 'product' )?.value;
  const largeCard02Image = content?.[1]?.properties?.find( p => p?.name === 'image' )?.value?.value;
  const largeCard02Position = content?.[1]?.properties?.find( p => p?.name === 'position' )?.value?.value;
  const gridAreasCard01 = largeCard01Position === 'right' ? {
    desktop: '2 / 3 / 4 / 5',
    tablet: '2 / 2 / 4 / 4',
    mobile: '2 / 1 / 4 / 3',
  }
  : {
    desktop: '2 / 1 / 4 / 3',
    tablet: '2 / 1 / 4 / 3',
    mobile: '2 / 1 / 4 / 3',
 };
  const gridAreasCard02 = largeCard02Position === 'right' ? {
    desktop: '5 / 3 / 7 / 5',
    tablet: '5 / 2 / 7 / 4',
    mobile: '6 / 1 / 8 / 3',
  }
  : {
    desktop: '5 / 1 / 7 / 3',
    tablet: '5 / 1 / 7 / 3',
    mobile: '6 / 1 / 8 / 3',
  };

  const filteredProducts = products?.filter(product => product?.articleNumber !== largeCard01?.articleNumber && product?.articleNumber !== largeCard02?.articleNumber);

  if (!products) return null;

  return (
    <>
    {hasLargeCard ? (
      <Wrapper
      data-testid="product-grid"
      className={cx('product-grid', className)}
      gridAreasCard01={gridAreasCard01}
      gridAreasCard02={gridAreasCard02}
    >
      {filteredProducts?.map((product, index) => {
        return (
          <ProductCard
            key={index + ':' + product.articleNumber}
            product={product}
            style={{ opacity: loading ? 0.5 : 1 }}
            {...rest}
          />
        );
      })}
      {largeCard01 && (
      <LargeProductCard
        className={cx('product-card', 'big-card01')}
        product={largeCard01}
        optionalImage={largeCard01Image}
        style={{ opacity: loading ? 0.5 : 1 }}
        {...rest}
        />
      )}
        {largeCard02 && (
        <LargeProductCard
          className={cx('product-card', 'big-card02')}
          product={largeCard02}
          optionalImage={largeCard02Image}
          style={{ opacity: loading ? 0.5 : 1 }}
          {...rest}
        />
      )}
    </Wrapper>       
    ) : (
      <Wrapper
      data-testid="product-grid"
      className={cx('product-grid', className)}
    >
      {products?.map((product, index) => {
        return (
          <ProductCard
            key={index + ':' + product.articleNumber}
            product={product}
            style={{ opacity: loading ? 0.5 : 1 }}
            {...rest}
          />
        );
      })}
    </Wrapper>
    )}
    </>
  );
}
