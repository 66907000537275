import React from 'react';
import { cx } from 'linaria';
import { contentPositionerStyle } from './ContentPositioner.style';

/**
 * The ContentPlacer function is a helper function that places content in the desired place (to first relative element above) of the div.
 *
 *
 * @param {string} verticalPosition Determine the vertical position of the content
 * @param {string} horizontalPosition Set the horizontal position of the content
 * @param children} Pass the content to be placed in the contentplacer
 *
 * @return A div with a classname with its children placed
 *
 * @docauthor Anders Zetterström @ R3
 */
export const ContentPositioner = ({
  verticalPosition,
  horizontalPosition,
  children
}) => {
  return (
    <div
      className={cx(
        'content-positioner',
        contentPositionerStyle,
        verticalPosition,
        horizontalPosition
      )}
    >
      {children}
    </div>
  );
};
