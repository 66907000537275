import ChannelContext from '@jetshop/core/components/ChannelContext';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useQuery } from '@apollo/react-hooks';
import customerIdQuery from './CustomerIdQuery.gql';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';

export const GamifieraContext = createContext();


// Create the provider component
export const GamifieraProvider = ({ children }) => {
    const [token, setToken] = useState(null);
    const [gmfLoaded, setGmfLoaded] = useState(false);
      const { loggedIn } = useAuth();


    const { selectedChannel } = useContext(ChannelContext);
    const { data, loading, error } = useQuery(customerIdQuery, {
      errorPolicy: 'ignore',
      skip: !loggedIn,
      fetchPolicy: 'no-cache'
    });


    const scriptAlreadyExists = () =>
      document.querySelector('script#gmf-loader') !== null;

        useEffect(() => {
          if (!scriptAlreadyExists()) {
            appendGMFScript();
          }
        }, []);

          const appendGMFScript = () => {
            const script = document.createElement('script');
            script.id = 'gmf.loader';
            script.src = 'https://cdn.gamifiera.com/static/gmf/loader.js';
            script.async = true;
            script.defer = true;
            script.crossOrigin = 'anonymous';
            script.onload = () => setGmfLoaded(true);
            document.body.append(script);


            window.gmf = function () {(window._gmf=window._gmf||[]).push(Object.values(arguments));};

            window.gmf('init', {

            merchantId: 1990,
            locale: selectedChannel.language.culture.replace('-', '_'),
            customerToken: token ?? null,

            });
            
          };

    useEffect(() => {

      if (data && data.customer && data.customer.externalId && loggedIn) {
        fetch(`/api/gamifiera_token?cid=${data.customer.externalId}`, {
          cache: 'no-store'
        })
          .then(response => response.json())
          .then(tokenData => {
            setToken(tokenData.token);
          })
          .catch(error => {
            console.error('Error fetching customer token:', error);
          });
      }else{
        setToken(null);
      }
    }, [data, loading, error,loggedIn]);


    useEffect(() => {
      if (typeof window !== 'undefined' && window?.gmf && token && loggedIn) {
        window.gmf('update', { customerToken: token });
      }
      if (typeof window !== 'undefined' && window?.gmf && !token) {
        window.gmf('update', { customerToken: null });
      }
    }, [typeof window !== 'undefined' && window?.gmf, token, loggedIn]);


    return (
      <GamifieraContext.Provider>
        {children}
      </GamifieraContext.Provider>
    );
};


