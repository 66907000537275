import React from 'react'
import { styled } from 'linaria/react'
import { ReactComponent as Truck } from '../../svg/Truck.svg';
import { ReactComponent as Return } from '../../svg/Undo.svg';
import { ReactComponent as Klarna } from '../../svg/Klarna_Logo_black.svg';


const USPWrapper = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 1rem  1.5rem;
    background-color: #f6f6f6;
    border-radius: 6px;
    margin-bottom: 1.5rem;

    p{
        font-size: 14px;
        color: #474747;
    }

    svg{
      fill: #000;
      color: #000;
      width: 3rem;
      height: 1.5rem;
}

    .shipping-usp-line{
        display: flex;
        column-gap: 1.2rem;
        row-gap: 0.5rem;
        align-items: center;
    }

`;

const IconWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 1.9rem;
`;

export const ProductPageUSP = ({ children }) => {
  return (
  <USPWrapper>
      {children}
  </USPWrapper>
  );
  };

 export const ProductPageUSPItem = ({
  icon,
  text
}) => {
  switch (icon?.value) {
    case 'truck':
      return (
        <div className='shipping-usp-line'>
          <IconWrapper>
            <Truck />
          </IconWrapper>
          <p>{text?.value}</p>
        </div>
      )
    case 'return':
      return (
        <div className='shipping-usp-line'>
          <IconWrapper>
            <Return />
          </IconWrapper>
          <p>{text?.value}</p>
        </div>
      )
    case 'klarna':
      return (
        <div className='shipping-usp-line'>
          <IconWrapper>
            <Klarna />
          </IconWrapper>
          <p>{text?.value}</p>
        </div>
      )
    case 'no icon':
      return (
        <div className='shipping-usp-line'>
          <p>{text?.value}</p>
        </div>
      )
    default:
      return (
        <div className='shipping-usp-line'>
          <p>{text?.value}</p>
        </div>
      )
  }
}