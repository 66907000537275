import get from 'lodash.get';
import React from 'react';
import { styled } from "linaria/react";
import { theme } from '../Theme';
import Image from '@jetshop/ui/Image/Image';
import {
  StyledBreadcrumbs,
  CategoryName,
  CategoryContent,
  Inner,
} from '../CategoryPage/CategoryHeader';
import CategoryHeaderLoadingState from '../CategoryPage/CategoryHeaderLoadingState';
import CustomerClubForm from './CustomerClubForm';

const CategoryWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  width: 60rem;
  max-width: 100%;
  margin-top: 2rem;
  margin-bottom: 4rem;
  margin-left: auto;
  margin-right: auto;

  padding: 1rem 3rem 3rem 3rem;
  background: #f7f7f7;
  ${theme.below.lg} {
    padding: 1.5rem;
    padding-top: 0.5rem;
  }

  label {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  ${theme.below.sm} {
    width: 100%;
  }
`;
const CategoryImage = styled('div')`
  max-width: 90rem;
  margin: auto;
  padding: 0 2rem;
`;
const StyledInner = styled(Inner)`
  padding-top: 10px;
  margin-top: 1.5rem;
`;
const StyledCategoryContent = styled(CategoryContent)`
  max-height: none;
  text-align: left;
  max-width: 800px;
  width: 100%;
  margin: 2rem auto 4rem auto;
  padding: 0 1rem;
  text-align: center;

  font-family: ${theme.fonts.primary};

  p {
    margin-bottom: 1rem;
    font-family: 'Times New Roman', serif;
  }
  p a,
  p a:visited {
    color: ${theme.colors.primary};
    text-decoration: underline;
  }

  h2,
  h3,
  h4,
  strong,
  b {
    font-family: ${theme.fonts.primary};
    text-transform: uppercase;
    letter-spacing: 0.08rem;
  }
  h2 {
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
  h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  strong,
  b {
    font-weight: bold;
  }

  &:after {
    display: none;
  }
  img {
    max-width: 100%;
  }

  table {
    width: 100%;
    tr {
      &:first-of-type {
        font-weight: 600;
      }
      td {
        text-align: right;
      }
      td:first-of-type {
        width: 20%;
        text-align: left;
      }
    }
  }
`;

const CustomerClub = ({ category, cacheHit }) => {
  const parents = get(category, 'route.parents');
  const breadcrumbProps = {
    breadcrumbText: category?.breadcrumbText,
    parents,
  };
  return (
    <CategoryWrapper>
      <>
        {!cacheHit ? (
          <CategoryHeaderLoadingState />
        ) : (
          <>
            {category.images && category.images.length > 0 ? (
              <CategoryImage>
                <Image
                  src={category.images}
                  quality={80}
                  crop={true}
                  cover={true}
                  gravity="north"
                  aspect={'5:2'}
                  alt={category.name}
                />
              </CategoryImage>
            ) : null}
            <StyledInner>
              <CategoryName data-testid="page-header">
                {category.name}
              </CategoryName>
              <StyledCategoryContent
                data-testid="category-description"
                dangerouslySetInnerHTML={{
                  __html: category.content,
                }}
              />
              <CustomerClubForm />
            </StyledInner>
          </>
        )}
      </>
    </CategoryWrapper>
  );
};

export default CustomerClub;
