import React, { useState, useEffect, useContext } from 'react';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { useCookies } from 'react-cookie';

export function useScript(src) {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? 'loading' : 'idle');

  useEffect(
    () => {
      // Allow falsy src value if waiting on other data needed for
      // constructing the script URL passed to this hook.
      if (!src) {
        setStatus('idle');
        return;
      }

      // Fetch existing script element by src
      // It may have been added by another intance of this hook
      let script = document.querySelector(`script[src="${src}"]`);

      if (!script) {
        // Create script
        script = document.createElement('script');
        script.src = src;
        script.async = true;
        script.setAttribute('data-status', 'loading');
        // Add script to document body
        document.body.appendChild(script);

        // Store status in attribute on script
        // This can be read by other instances of this hook
        const setAttributeFromEvent = event => {
          script.setAttribute(
            'data-status',
            event.type === 'load' ? 'ready' : 'error'
          );
        };

        script.addEventListener('load', setAttributeFromEvent);
        script.addEventListener('error', setAttributeFromEvent);
      } else {
        // Grab existing script status from attribute and set to state.
        setStatus(script.getAttribute('data-status'));
      }

      // Script event handler to update status in state
      // Note: Even if the script already exists we still need to add
      // event handlers to update the state for *this* hook instance.
      const setStateFromEvent = event => {
        setStatus(event.type === 'load' ? 'ready' : 'error');
      };

      // Add event listeners
      script.addEventListener('load', setStateFromEvent);
      script.addEventListener('error', setStateFromEvent);

      // Remove event listeners on cleanup
      return () => {
        if (script) {
          script.removeEventListener('load', setStateFromEvent);
          script.removeEventListener('error', setStateFromEvent);
        }
      };
    },
    [src] // Only re-run effect if script src changes
  );

  return status;
}

export const VoyadoTracking = props => {
  const [cookies] = useCookies();
  if (cookies?.CookieConsent) {
    return <VoyadoTrackingInner {...props} />;
  }
  return null;
};

export const VoyadoTrackingInner = ({
  product = null,
  customer = null,
  cart = null,
  variant = null
}) => {
  const tenant = 'hooks';

  const staging = process.env.REACT_APP_SHOP_ID !== 'hooks';
  const { selectedChannel } = useContext(ChannelContext);
  let voScriptSrc;

  if (staging) {
    voScriptSrc =
      'https://assets.voyado.com/jsfiles/analytics_0.1.4.staging.min.js';
  } else {
    voScriptSrc = 'https://assets.voyado.com/jsfiles/analytics_0.1.4.min.js';
  }
  const status = useScript(voScriptSrc);
  const ready = status === 'ready';
  useEffect(() => {
    if (typeof window !== 'undefined' && ready) {
      window.va('setTenant', tenant);
    }
  }, [status]);

  useEffect(() => {
    if (typeof window !== 'undefined' && customer && ready) {
      window?.va('setContactId', customer.externalId);
    }
  }, [customer, status]);

  useEffect(() => {
    if (typeof window !== 'undefined' && product && ready) {
      const parent = product?.parents?.reduceRight(
        (accumulator, currentValue) => {
          let string =
            (accumulator ? accumulator + ' > ' : '') +
            currentValue?.object?.breadcrumbText;
          return string;
        },
        ''
      );
      let viewed_article = product?.variants?.values[0]?.articleNumber;
      if (variant) {
        viewed_article = variant.articleNumber;
      }

      window.va('productview', {
        categoryName: parent,
        itemId: viewed_article
      });
    }
  }, [product, status]);

  useEffect(() => {
    if (typeof window !== 'undefined' && cart && ready) {
      if (cart.items.length === 0) {
        try {
          window.va('emptyCart', { cartRef: cart.id });
        } catch (error) {
          console.warn('Ingen cart');
        }
      } else {
        const items = cart.items.map(item => {
          return {
            itemId: item.articleNumber,
            quantity: item.quantity,
            price: item.total.incVat
          };
        });
        window.va('cart', {
          cartRef: cart.id,
          locale: selectedChannel.defaultLanguage.culture,
          items: items
        });
      }
    }
  }, [cart, status]);

  return null;
};

// var voTracking = {
//   customerData: JetshopData.Customer,
//   contactId: null,
//   init: function() {
//     // CHECK IF IS PRODUCTION
//     if (
//       location.hostname === voTracking.config.productionHostName &&
//       !J.checker.isStage
//     ) {
//       voTracking.isStaging = false;
//     }

//     // SET SCRIPT SRCS
//     var voScriptSrc;
//     if (voTracking.isStaging) {
//       voScriptSrc =
//         'https://assets.voyado.com/jsfiles/analytics_0.1.4.staging.min.js';
//     } else {
//       voScriptSrc = 'https://assets.voyado.com/jsfiles/analytics_0.1.4.min.js';
//     }

//     // INSERT SCRIPT
//     voTracking.insertVaScript(window, document, 'script', voScriptSrc, 'va');

//     // SET STORE TENANT NAME
//     if (voTracking.isStaging) {
//       window.va('setTenant', voTracking.config.tenantNameStage);
//     } else {
//       window.va('setTenant', voTracking.config.tenantNameProduction);
//     }

//     // SET CUSTOMER ID IF LOGGED IN
//     if (
//       J.checker.isLoggedIn &&
//       voTracking.customerData &&
//       voTracking.customerData.ExternalCustomerId
//     ) {
//       voTracking.contactId = voTracking.customerData.ExternalCustomerId;
//       window.va('setContactId', voTracking.contactId);
//     } else if (getUrlParameters()['vtid']) {
//       voTracking.contactId = getUrlParameters()['vtid'];
//       window.va('setContactId', voTracking.contactId);
//     }

//     // PRODUCT PAGE
//     if (J.checker.isProductPage) {
//       voTracking.productPage();
//     } else if (J.checker.isOrderConfirmationPage) {
//       voTracking.orderConfirmationPage();
//     }

//     // TRACK CART CHANGES
//     voTracking.observeCart();
//   },
//   insertVaScript: function(i, s, o, g, r, a, m) {
//     i['VoyadoAnalyticsObject'] = r;
//     (i[r] =
//       i[r] ||
//       function() {
//         (i[r].q = i[r].q || []).push(arguments);
//       }),
//       (i[r].l = 1 * new Date());
//     (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
//     a.async = 1;
//     a.src = g;
//     m.parentNode.insertBefore(a, m);
//   },
//   productPage: function() {
//     // RESOLVE CATEGORY PATH
//     var pathString = $('#path-nav')
//       .text()
//       .replace(/[\f\n\r\t\v\u00A0\u2028\u2029]/g, '')
//       .replace(/[ ]+/g, ' ')
//       .trim();

//     // DELETE FIRST (START PAGE) & LAST (PRODUCT NAME)
//     pathString = pathString.substr(pathString.indexOf('>') + 1);
//     pathString = pathString.substr(0, pathString.lastIndexOf('>') - 1);
//     pathString = pathString.trim();

//     // DETERMINE WHAT ARTICLE NUMBER TO SEND
//     var sku = JetshopData.ProductInfo.ArticleNumber;
//     if (JetshopData.ProductInfo.Attributes) {
//       sku = JetshopData.ProductInfo.Attributes.Variations[0].ArticleNumber;
//     }

//     var trackingObject = {
//       categoryName: pathString,
//       itemId: sku
//     };

//     if (voTracking.contactId) {
//       trackingObject.contactId = voTracking.contactId;
//     }

//     window.va('productview', trackingObject);
//   },
//   orderConfirmationPage: function() {
//     window.va('emptyCart', {
//       cartRef: getCookie('voyado-cart-id')
//     });
//   },
//   observeCart: function() {
//     // BIND CART UPDATE
//     $(window).on('cart-updated', function() {
//       // CHECK COOKIE FOR PREVIOUS ITEM QTY
//       var prevItemCount = parseInt(getCookie('voyado-cart-item-count'));
//       if (!prevItemCount) {
//         prevItemCount = 0;
//         setCookie('voyado-cart-item-count', prevItemCount.toString(), 365);
//       }

//       // SET CART ID FOR USE ON ORDER CONFIRMATION PAGE (WHICH AUTOMATICALLY GETS NEW CART ID)
//       if (!J.checker.isOrderConfirmationPage) {
//         setCookie('voyado-cart-id', J.cart.cartId, 365);
//       }

//       // CHECK TOTAL NUMBER OF ITEMS IN CART
//       var currentItemCount = J.cart.ItemCount;

//       // IF ITEM QTY HAVE CHANGED - SAVE IN COOKIE AND SEND TRACKING REQUEST
//       if (prevItemCount !== currentItemCount) {
//         setCookie('voyado-cart-item-count', currentItemCount.toString(), 365);

//         var cartObject = {
//           cartRef: J.cart.cartId,
//           locale: J.data.culture,
//           items: []
//         };

//         $.each(J.cart.ProductsCart, function(index, prodData) {
//           cartObject.items.push({
//             itemId: prodData.Articlenumber,
//             price: prodData.ItemPrice,
//             quantity: prodData.Quantity
//           });
//         });
//         window.va('cart', cartObject);
//       }
//     });
//   }
// };
