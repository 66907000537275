import React, { useState, useRef, useEffect } from 'react';
import SearchAutoCompleteContainer from '@jetshop/ui/Search/SearchAutoCompleteContainer';
import { styled } from "linaria/react";
import AutocompleteQuery from '../AutocompleteQuery.gql';
import SearchResult from './SearchResult';
import { ReactComponent as SearchIcon } from '../../../../svg/fa/search.svg';
import SearchInput_new from './SearchInput_new';

const SearchContainer = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0.5rem 1rem 1rem 1rem;
  margin-top: 0.5rem;
`;

const SearchWrapper = styled('div')`
  width: 100%;
  max-width: 550px;
  height: 32px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: auto auto;

  .icon {
    grid-area: 1 / 8 / 2 / 9;
    width: 16px;
    z-index: 2;
    align-self: center;
    justify-self: flex-end;
    margin-right: 10px;
  }
`;

const Search_new = ({
  ...searchFieldProps
}) => {
  const [searchOpen, setSearchOpen] = useState(true);
  const [inputValue, setInputValue] = useState("");
  const searchResultRef = useRef(null);
  
  // Close search result when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchResultRef.current && !searchResultRef.current.contains(event.target)) {
        setSearchOpen(false);
        setInputValue('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
  <SearchAutoCompleteContainer
    autocompleteQuery={AutocompleteQuery}
    {...searchFieldProps}
    onSubmit={() => {
      setSearchOpen(false);
      setInputValue('');
    }}
    onCancel={() => {}}
  >
    {({
      getInputProps,
      getFlyoutProps,
      getCancelProps,
      inputRef,
      updateFocus,
      triggerSearch,
      ...rest
    }) => {

      return (
        <SearchContainer>
          <SearchWrapper>
            <SearchIcon className='icon' />
            <SearchInput_new
              gridArea='1 / 1 / 2 / 9'
              getInputProps={getInputProps}
              setSearchOpen={setSearchOpen}
              inputValue={inputValue}
              setInputValue={setInputValue}
              {...rest}
            />
            {rest.term.length > 0 && searchOpen && (
              <div ref={ searchResultRef } >
                <SearchResult
                  term={rest.term}
                  triggerSearch={triggerSearch}
                  setSearchOpen={setSearchOpen}
                  {...rest}
                />
              </div>
            )}
          </SearchWrapper>
        </SearchContainer>
      );
    }}
  </SearchAutoCompleteContainer>
  );
};

export default Search_new;
