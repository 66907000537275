import { useProductList } from '@jetshop/core/hooks/ProductList';
import React from 'react';
import { styled } from 'linaria/react';
import { ReactComponent as HeartSVG } from '../../svg/Heart.svg';

const IconContainer = styled('div')`
  @keyframes heartPop {
    0% {
      transform: scale(0.9);
    }

    20% {
      transform: scale(0.9);
    }

    70% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }

  @keyframes circle {
    0% {
      transform: translateY(-50%) translateX(-50%) scale(0);
      opacity: 0;
    }

    60% {
      transform: translateY(-50%) translateX(-50%) scale(0.5);
      opacity: 1;
      box-shadow: inset 0px 0px 10px 10px #000000;
    }

    100% {
      transform: translateY(-50%) translateX(-50%) scale(1);
      opacity: 0;
    }
  }

  margin-top: 0.5rem;
  margin-right: 0.5rem;
  display: inline-block;
  text-align: right;
  position: absolute;
  right: 0;
  z-index: 2;
  align-items: center;
  justify-content: flex-end;
  button {
    background: transparent;
    outline: none;
  }
  svg {
    width: 25px;
    height: 25px;
    &.active {
      fill: currentColor;
    }
  }
  &.active {
    &::before {
      pointer-events: none;
      animation: circle 0.6s ease forwards;
      box-sizing: border-box;
      content: '';
      display: block;
      position: absolute;
      box-shadow: inset 0 0 2px 2px #000000;
      width: 40px;
      height: 40px;
      top: 50%;
      left: 50%;
      transform: translateY(-50%) translateX(-50%) scale(0);
      border-radius: 50%;
      opacity: 0;
    }
  }
`;

export function AddFavourite({ product, variant, ...rest }) {
  const articleNumber = product.articleNumber;

  const selectedVariation = product.variant || variant;
  const variantArticleNumber = selectedVariation?.articleNumber;

  const { toggle, inList } = useProductList();

  function toggleWithoutBubble(e) {
    e.preventDefault();
    toggle(articleNumber, {
      variantArticleNumber
    });
  }

  const isInList = inList(articleNumber, {
    variantArticleNumber
  });

  return (
    <IconContainer className={isInList ? 'active' : 'inactive'} {...rest}>
      <button onClick={toggleWithoutBubble}>
        <HeartSVG className={isInList ? 'active' : 'inactive'} />
      </button>
    </IconContainer>
  );
}
