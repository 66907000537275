import React from 'react';
import { styled } from "linaria/react";
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import { useProductList } from '@jetshop/core/hooks/ProductList';
import { theme } from "../Theme";

import { ReactComponent as Heart } from '../../svg/fa/heart_new.svg';

const Container = styled('div')`
  cursor: pointer;
  display: inline-block;
  margin-left: 3px;
  position: relative;
  ${theme.below.xl} {
    margin-right: 6px;
  }
  ${theme.below.lg} {
    margin-left: 3px;
  }
`;

const Count = styled('div')`
  align-items: center;
  background: ${theme.colors.primary};
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 10px;
  font-weight: bold;
  height: 19px;
  justify-content: center;
  line-height: 1;
  right: -12px;
  position: absolute;
  top: -8px;
  width: 19px;
`;

export function FavouriteCount() {
  const { count } = useProductList();

  return (
    <DrawerTrigger id="favourites-drawer">
      {(drawer) => (
        <Container
          onClick={drawer.isOpen ? drawer.hideTarget : drawer.showTarget}
        >
          <Heart />
          {count > 0 && <Count>{count}</Count>}
        </Container>
      )}
    </DrawerTrigger>
  );
}
