import { Link } from 'react-router-dom';
import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../Theme';
import SubscribeToNewsletter from '@jetshop/core/components/Mutation/SubscribeToNewsletter';
import t, { Intl } from '@jetshop/intl';
import getErrorDetail from '@jetshop/core/helpers/getErrorDetail';

import { ReactComponent as Cross } from '../../../svg/Cross.svg';

const FieldErrorWrapper = styled('div')`
  h2 {
    font-size: 14px;
    letter-spacing: 0.1em;
  }
  ${theme.below.lg} {
    max-width: 350px;
    margin: auto;
    text-align: center;
  }
`;

const Wrapper = styled('div')`
  display: block;
  width: 100%;
  padding-top: 10px;
  form {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    ${theme.below.md} {
      align-items: end;
    }
  }

  input {
    border-radius: 0;
    appearance: none;
    border-radius: 0;
    background: transparent;
    border: 1px solid #a2a2a2;
    border-right: 0;
    color: white;
    font-size: 16px;
    padding: 0 15px;
    height: 40px;
    line-height: 40px;
    flex: 1;
    font-family: ${theme.fonts.primary};
    &:focus {
      border-color: white;
    }

    ${theme.below.md} {
      margin-right: 0;
      font-size: 12px;
      text-align: center;
    }
  }
  button {
    text-transform: uppercase;
    background: transparent;
    border: 1px solid #a2a2a2;
    color: white;
    font-weight: bold;
    font-size: 14px;
    padding: 0 20px;
    height: 40px;
    transition: all, 0.2s ease;
    word-break: initial;
    &:hover {
      color: white;
      background: ${theme.colors.primary};
    }
    &:focus {
      outline: none;
    }

    ${theme.below.md} {
      width: 100%;
      margin: 0;
      max-width: 60px;
      padding: 0;
      font-size: 16px;
    }
  }
`;

const Error = styled('div')`
  margin-bottom: 0.5rem;
  color: inherit;
  letter-spacing: 0.08rem;
  font-size: 12px;
`;

// Prevent the normal form submission behaviour, then call the
// onSubmit provided by the mutation
const submitEmail = (email, onSubmit) => e => {
  e.preventDefault();
  onSubmit(email);
};

class NewsletterField extends React.Component {
  state = {
    email: '',
    completed: false,
    error: false
  };

  // State change methods
  updateEmail = e =>
    this.setState({ email: e.currentTarget.value, error: false });
  setCompleted = () => this.setState({ completed: true });
  setError = () => this.setState({ error: true });
  unsetError = () => this.setState({ error: false });

  render() {
    return (
      <SubscribeToNewsletter
        onCompleted={this.setCompleted}
        onError={this.setError}
      >
        {(subscribe, result) => {
          return (
            <FieldErrorWrapper>
              <label htmlFor="newsletter">
                <h2>{t('Subscribe for our newletter')}</h2>
              </label>
              {this.state.error ? (
                <Error>
                  {getErrorDetail(result.error).codes.includes(
                    'AlreadySubscribed'
                  )
                    ? t('You have already subscribed to the newsletter!')
                    : t(
                        'Something went wrong. Please check your email and try again.'
                      )}
                </Error>
              ) : null}
              <Wrapper>
                {this.state.completed ? (
                  <p>{t('You are now subscribed')}</p>
                ) : (
                  <>
                    <form onSubmit={submitEmail(this.state.email, subscribe)}>
                      <Intl>
                        {t => (
                          <>
                            <input
                              name="newsletter"
                              type="email"
                              placeholder={t('Enter email address')}
                              onChange={this.updateEmail}
                            />
                            <button>
                              {result.loading ? t('Submitting...') : t('OK')}
                            </button>
                          </>
                        )}
                      </Intl>
                    </form>
                  </>
                )}
              </Wrapper>
            </FieldErrorWrapper>
          );
        }}
      </SubscribeToNewsletter>
    );
  }
}

export default NewsletterField;
