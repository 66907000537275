import React, { Component, Fragment, useContext, useEffect } from 'react';
import { styled } from "linaria/react";
import { theme } from "../../../Theme";
import ChannelContext from '@jetshop/core/components/ChannelContext';
import { FlyoutTarget, FlyoutTrigger } from '@jetshop/ui/Modal/Flyout';
import { Above } from '@jetshop/ui/Breakpoints';
import Modal from 'react-modal';
import Selector from './Selector';
import {Link} from 'react-router-dom';
import { createSelectedChannel } from '@jetshop/core/ChannelHandler/channelUtils';



const Flyout = styled('div')`
  background-color: white;
  position: absolute;
  z-index: 5;
  top: 100%;
  right: 0;
`;

const CountryFlagWrapper = styled('div')`
  position: relative;
  overflow: hidden;
  height: 18px;
  width: 20px;
  margin-left: 0.2rem;
  ${theme.below.md} {
    overflow: visible;
    margin-left: 0;
    width: 30px;
    height: 32px;
  }
`;
const CountryFlag = styled('img')`
  width: 100%;
  height: auto;
  ${theme.below.md} {
    width: 30px;
    height: 32px;
    margin-top: -2px;
  }
`;
const ChannelButton = styled('button')`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 8px 0;
  &:focus {
    outline: none;
  }
`;

const MobileChannelButton = styled('button')`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 0.4rem;
  margin: 0 0 0 auto;
  padding: 8px 0;
  &:focus {
    outline: none;
  }
  ${theme.below.md} {
    line-height: initial !important;
    padding: 0 !important;
  }
  .mobile-flag {
    width: 30px;
    height: 30px;
  }
  .country-name {
    font-size: 16px;
  }
`;

const EUModalInner = styled('div')`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  .euExtras {
    width:100%;
    margin-bottom: 2rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  h1,
  p {
    text-align: center;
    width: 100%;
  }
  p {
    margin-bottom: 1rem;
  }
  button {
    padding: 0.5rem 0.75rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 5px;
    font-size: 11px;
    transition: all 0.2s ease-in-out;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
      rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    img {
      margin-right: 0.25rem;
    }
    &:hover {
      transform: scale(1.01);
      box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
        rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
    }
  }
`;


const EUChannelExtras = ({}) => {
    const { selectedChannel, updateChannel,channels } = useContext(ChannelContext);
  return (
    <div className='euExtras'>
      {channels.map(channel => (
        <div key={channel.id}>
          {channel.name==='EU' ? (
            <></>
          ) : (
            <>
              <button
                isActive={channel.id === selectedChannel.id}
                onClick={() => {
                  const selected = createSelectedChannel(channel);
                  updateChannel(selected);
                }}
              >
                <img
                  src={`https://countryflags.jetshop.io/${channel.countries[0].code}/flat/32.png`}
                  alt="Country Flag"
                />
                {channel.countries[0].name}
              </button>
            </>
          )}
        </div>
      ))}
    </div>
  );
}



export const EuChannelOptions = () => {
  const {selectedChannel,updateChannel} = useContext(ChannelContext)
  const [showModal, setShowModal] = React.useState(false);
  const countries = [...selectedChannel.countries].sort((a, b) => a.name > b.name ? 1 : -1);
  useEffect(() => {
    if(typeof window !== 'undefined'){
      if(!window.localStorage.getItem('eu-channel-country')){
        setShowModal(true);

      }
    }
  })

  if(selectedChannel.name !== 'EU') return null;

  const handleCloseModal = (country) => {
    setShowModal(false);
    window.localStorage.setItem('eu-channel-country', country.code);
    updateChannel({...selectedChannel,country:country})
  }


  return (
    <Modal
    className="EU-modal"
    isOpen={showModal}
    onRequestClose={() => {}}
    contentLabel="Europe country select"
  >
    <EUModalInner>
    <h1>Welcome to Hööks</h1>
    <p>To give you the best shopping experience, please select your country.</p>
    <EUChannelExtras/>
      {countries.map(country => {
      return <button onClick={()=>handleCloseModal(country)}><img width="24"
      src={`https://countryflags.jetshop.io/${country.code}/flat/32.png`}
      alt={'flag of ' + country.name}
    />{country.name}</button>
    })}</EUModalInner>
  </Modal>
  );
}
export default class HeaderChannelSelector extends Component {
  render() {
    return (
      <ChannelContext.Consumer>
        {({ channels, selectedChannel, updateChannel }) => {
          const selectedChannelName =
            selectedChannel.name === 'EU'
              ? selectedChannel.name
              : selectedChannel.country.name;

          const selectedChannelFlag =
            selectedChannel.name === 'EU'
              ? selectedChannel.name
              : selectedChannel.country.code;

          return (
            <Fragment>
              <Above breakpoint="lg">
                {matches =>
                  matches ? (
                    <>
                      <FlyoutTrigger id="channel-selector">
                        {({ showTarget, hideTarget, isOpen }) => (
                          <ChannelButton
                            onClick={isOpen ? hideTarget : showTarget}
                            style={{ background: 'transparent' }}
                          >
                            <span>{selectedChannelName}</span>

                            <CountryFlagWrapper>
                              <CountryFlag
                                src={`https://countryflags.jetshop.io/${selectedChannelFlag}/flat/32.png`}
                                alt="Country Flag"
                              />
                            </CountryFlagWrapper>
                          </ChannelButton>
                        )}
                      </FlyoutTrigger>
                      <FlyoutTarget id="channel-selector">
                        {({ isOpen, hideTarget }) => (
                          <Flyout>
                            <Selector
                              channels={channels}
                              hideTarget={hideTarget}
                              selectedChannel={selectedChannel}
                              updateChannel={updateChannel}
                            />
                          </Flyout>
                        )}
                      </FlyoutTarget>
                    </>
                  ) : (
                    <>
                      <MobileChannelButton
                        style={{ background: 'transparent' }}
                        onClick={this.props.onClick}
                      >
                        <span className='country-name'>{selectedChannelName}</span>
                        <CountryFlagWrapper className='mobile-flag'>
                          <CountryFlag
                            src={`https://countryflags.jetshop.io/${selectedChannelFlag}/flat/32.png`}
                            alt="Country Flag"
                          />
                        </CountryFlagWrapper>
                      </MobileChannelButton>
                    </>
                  )
                }
              </Above>
            </Fragment>
          );
        }}
      </ChannelContext.Consumer>
    );
  }
}
