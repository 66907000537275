import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theme';
import { Link } from 'react-router-dom';
import Image from '@jetshop/ui/Image';
import { TrendButtonStyle } from '../../../ui/Button';
import Video from '../../Content/Video';
import { Above } from '@jetshop/ui/Breakpoints';
import { cx } from 'linaria';
const SlideWrapper = styled('div')`
  position: relative;
  z-index: -1;
  .slick-active & {
    z-index: 1;
  }

  .vimeo {
    padding-bottom: 41.65%;
  }
  &.mobile {
    .vimeo {
      padding-bottom: 100%;
    }
  }
  ${theme.below.md} {
    margin-bottom: 1rem;
  }
`;
const SlideContent = styled('div')`
  position: absolute;
  top: auto;
  left: 0;
  right: 0;
  bottom: 10%;
  margin: auto;
  color: white;
  text-align: center;
  ${theme.below.md} {
    bottom: 30px;
    transform: none;
    top: auto;
    max-width: 90%;
    margin: auto;
  }
  h2 {
    font-size: 2.5rem;
    line-height: 1.4;
    font-weight: bold;
    ${theme.below.md} {
      font-size: 30px;
      margin-bottom: 10px;
    }
  }
  p {
    letter-spacing: 0.3em;
    text-transform: uppercase;
    font-size: 13px;
    margin-bottom: 0;
  }
  a {
    ${theme.below.md} {
      font-size: 16px;
      padding: 0 40px;
      height: 43px;
      line-height: 41px;
    }
  }
  .hero-cta {
    &.blackweek {
      background: ${theme.colors.black};
      color: white;
      border-color: ${theme.colors.black};
      &:hover {
        background: ${theme.colors.darkgrey};
        color: white;
        border-color: ${theme.colors.darkgrey};
      }
    }
    &.christmas-red {
      background: ${theme.colors.christmasred};
      color: white;
      border-color: ${theme.colors.christmasred};
      &:hover {
        background: ${theme.colors.red};
        color: white;
        border-color: ${theme.colors.red};
      }
    }
  }
`;

const SlideButton = styled('span')`
  ${TrendButtonStyle};
  margin-top: 30px;
`;

const Slide = ({
  linkUrl,
  image,
  device,
  heading,
  subtitle,
  undertitle,
  button,
  vimeoSrc,
  vimeoSrcMobile,
  imageMobile,
  aspect,
  mobileaspect,
  buttonTheme,
  ...rest
}) => {
  buttonTheme?.value === undefined && (buttonTheme = { value: 'primary' });
  return (
    <Above breakpoint="md">
      {matches => (
        <SlideWrapper className={cx(matches ? 'desktop' : 'mobile')}>
          {linkUrl ? (
            <Link to={linkUrl?.value}>
              {(matches && vimeoSrc?.value) ||
              (!matches && vimeoSrcMobile?.value) ? (
                <Video
                  aspect={
                    matches
                      ? aspect?.value ?? '12:5'
                      : mobileaspect?.value ?? '4:3'
                  }
                  vimeoSrc={
                    !matches && vimeoSrcMobile?.value
                      ? vimeoSrcMobile.value
                      : vimeoSrc.value
                  }
                />
              ) : (
                <Image
                  className="image"
                  aspect={
                    matches
                      ? aspect?.value ?? '12:5'
                      : mobileaspect?.value ?? '4:3'
                  }
                  src={
                    matches
                      ? image?.value?.value
                      : imageMobile?.value?.value ?? image?.value?.value
                  }
                  critical={true}
                  modifiedDate={image.modifiedDate}
                  cover
                />
              )}
              <SlideContent>
                {subtitle?.value && <p>{subtitle?.value}</p>}
                <h2>{heading.value}</h2>
                {undertitle?.value && <p>{undertitle?.value}</p>}
                {linkUrl?.value && button?.value && (
                <SlideButton className={cx(buttonTheme?.value, 'startPage', 'hero-cta')}>
                  {button.value}
                </SlideButton>
              )}
              </SlideContent>
            </Link>
          ) : (
            <>
              {(matches && vimeoSrc?.value) ||
              (!matches && vimeoSrcMobile?.value) ? (
                <Video
                  vimeoSrc={
                    !matches && vimeoSrcMobile?.value
                      ? vimeoSrcMobile.value
                      : vimeoSrc.value
                  }
                />
              ) : (
                <Image
                  className="image"
                  aspect={
                    matches
                      ? aspect?.value ?? '12:5'
                      : mobileaspect?.value ?? '4:3'
                  }
                  src={
                    !matches && imageMobile?.value
                      ? imageMobile?.value.value
                      : image?.value?.value
                  }
                  critical={true}
                  modifiedDate={image.modifiedDate}
                  cover
                />
              )}
              <SlideContent>
                {subtitle?.value && <p>{subtitle?.value}</p>}
                <h2>{heading.value}</h2>
                {undertitle?.value && <p>{undertitle?.value}</p>}
              </SlideContent>
            </>
          )}
        </SlideWrapper>
      )}
    </Above>
  );
};

export default Slide;
