import { Above } from '@jetshop/ui/Breakpoints';
import { theme } from '../../Theme';
import React, {
  useRef,
  useState,
  useEffect,
  useCallback,
  useContext
} from 'react';
import { styled } from 'linaria/react';
import CartButton from '../../Cart/CartButton';
import CartFlyout from '../../Cart/CartFlyout';
import { Favourites } from '../../ProductList/Favourites';
import MobileMenu from './MobileMenu';
import MaxWidth from '../MaxWidth';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { Link } from 'react-router-dom';
import ChannelSelector from './ChannelSelector/ChannelSelector';
import Image from '@jetshop/ui/Image';
import logo from '../../../img/academy-logo.jpg';
import t from '@jetshop/intl';
import { ReactComponent as Logo } from '../../../svg/logo.svg';
import { CategoryMenu } from './CategoryMenu/CategoryMenu';
import { FavouriteCount } from '../../ProductList/FavouriteCount';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import Search_new from './Search/Search_new';
import UspContainer from './UspContainer';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer';
import { ReactComponent as User } from '../../../svg/fa/user_new.svg';
import { ReactComponent as MapMarker } from '../../../svg/fa/mapmarker_new.svg';
import { ReactComponent as MenuBars } from '../../../svg/fa/bars-regular.svg';
import CampaignPush from '../../Layout/CampaignPush/CampaignPush';
import { DynamicCategoryRenderer } from '../../StartPage/ContentRendererComponents/DynamicCategoryRenderer/DynamicCategoryRenderer';
import { allComponents } from '../../StartPage/ContentRendererComponents/allcomponents';

const StyledTopBar = styled('div')`
  height: 36px;
  display: flex;
  background: #f6f6f6;
  padding: 0.1rem 2rem;
  color: ${theme.colors.black};
  flex-direction: row;
  justify-content: space-between;

  ${theme.below.xl} {
    padding: 0.1rem 0.75rem;
  }

  ${theme.below.lg} {
    padding: 0.5rem;
  }

  ${theme.below.sm} {
    display: none;
  }
`;

const TopBarRight = styled('div')`
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 11px;
  svg {
    font-size: 15px;
    margin-left: 0.2rem;
    width: 13px;
  }
  > a,
  > button {
    margin-left: 1rem;
  }
  button {
    color: #000;
  }

  .academy {
    display: inline-flex;
    align-items: center;
  }
`;

const AcademyImg = styled('div')`
  height: 15px;
  margin-left: 5px;
  width: 15px;
`;

const MainHeader = styled('main')`
  display: flex;
  justify-content: space-between;
  padding: 0 2rem;
  ${theme.below.xl} {
    padding: 0.5rem 0.75rem;
  }
  ${theme.below.lg} {
    padding: 0.5rem 1rem;
    /* border-bottom: 1px solid #235837; */
  }
`;

const IconsWrapper = styled('div')`
  display: flex;
  justify-content: ${props => props.position ? 'flex-start' : 'flex-end'};
  align-items: center;
  gap: ${props => (props.gap ? '0.5rem' : '0.2rem')};
  flex: 1;

  .desktop-header-button {
    flex: none;
  }

  ${theme.below.lg} {
    width: 100%;
  }

  & > button,
  & > a {
    padding: 9px;
    max-width: 40px;
    ${theme.below.xl} {
      margin-left: 0;
      &:first-of-type {
        padding: 6px;
      }
    }
    ${theme.below.lg} {
      padding: 18px 6px;
      display: flex;
      margin-left: 2px;
      svg {
        display: block;
      }
    }
  }
  svg {
    font-size: 22px;
    height: 25px;
    max-width: 30px;
  }
`;

const LogoLink = styled(Link)`
  width: 15%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex: 1;
  ${theme.below.lg} {
    align-items: center;
  }
  svg {
    overflow: visible !important;
    width: 132px;
    ${theme.below.xl} {
      width: 100px;
    }
    ${theme.below.lg} {
      width: 106px;
    }
  }
`;

const Container = styled('header')`
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 9999;
  border-bottom: 1px solid ${theme.colors.primary};

  a {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
      color: #000;
    }
  }
`;

export const MenuButton = styled('button')`
  background: none;
  padding: 14px 6px;
  margin-left: 2px;
  margin-top: 2px;
  svg {
    width: 21px !important;
    height: 25px;
  }
`;

export const IconContainer = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  margin: 0;
`;

const MobileMenuArea = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const Top = styled('div')`
  width: 100%;
  display: flex;
  margin-top: 0.5rem;
`;

const Left = styled('div')`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
`;
const Center = styled('div')`
  width: 100%;
  flex: 2;
  display: flex;
  align-items: center;
  ${theme.below.lg} {
    flex: 0;
  }
  a {
    display: block;
    width: 100%;
    text-align: center;
    overflow: visible;
  }
`;
const Right = styled('div')`
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
`;
const Bottom = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 32px;
  margin: 0.8rem 0 0.5rem 0;
`;

const SearchButton = styled('button')`
  background: none;
  &:focus {
    outline: none;
  }
`;

const StyledMaxWidth = styled(MaxWidth)`
  overflow: visible;
`;

const HeaderOuter = styled('div')`
  ${theme.below.lg} {
    .search-wrapper {
      display: flex;
      border-bottom: 1px solid #d2d2d2;
      padding: 1rem;

      svg {
        color: #000;
        align-self: center;
        max-height: 30px;
      }

      img {
        padding: 5px;
      }

      .product-card {
        & > a {
          & > div:first-of-type {
            & > div:first-of-type {
              button {
                display: none;
              }
            }
          }
        }
      }
    }
  }

  .search-bar-wrapper {
    background: #fff;
    padding: 0.5rem 1rem 1rem 1rem;
    input {
      max-width: 350px;
      margin: 0 auto;
      display: block;
      text-align: center;
      border-bottom: 1px solid #1d5631;
    }
  }
`;

function LeftHeader() {
  const { selectedChannel } = useContext(ChannelContext);
  const isLeft = true;
  return (
    <DrawerTrigger preventOverflow={true} id="menu-drawer">
      {drawer => (
        <IconsWrapper position={isLeft} gap={isLeft}>
          <MenuButton
            onClick={
              // data-testid="header-cart"
              drawer.isOpen ? drawer.hideTarget : drawer.showTarget
            }
          >
            <MenuBars />
          </MenuButton>
          {selectedChannel.name !== 'EU' && (
            <div className="gmf-dropdown-profile" />
          )}
        </IconsWrapper>
      )}
    </DrawerTrigger>
  );
}

function RightHeader() {
  const { selectedChannel } = useContext(ChannelContext);
  const isLeft = false;
  return (
    <IconsWrapper position={isLeft} gap={isLeft}>
      <Above breakpoint="lg">
        {matches =>
          matches ? (
            <>
              {selectedChannel.name !== 'EU' && (
                <div className="gmf-dropdown-profile" />
              )}
              <FavouriteCount />

              <CartButton className="desktop-header-button" />
            </>
          ) : (
            <>
              <FavouriteCount />
              <CartButton className="desktop-header-button" />
            </>
          )
        }
      </Above>
    </IconsWrapper>
  );
}

export const TopBar = () => {
  const { loggedIn } = useAuth();
  const { selectedChannel } = useContext(ChannelContext);
  return (
    <StyledTopBar>
      <UspContainer />
      <Above breakpoint="lg">
        <TopBarRight>
          <Link to="/hooks-academy" className="academy">
            <span>Hööks Academy</span>
            <AcademyImg>
              <Image src={logo} alt="Logotyp Hööks Academy" />
            </AcademyImg>
          </Link>

          {selectedChannel.name !== 'EU' && (
            <>
              <Link to="/login">
                {loggedIn ? t('My pages') : t('Login')} <User />
              </Link>
            </>
          )}
          <Link to="/stores">
            {t('Find a store')}
            <MapMarker />
          </Link>
          <ChannelSelector />
        </TopBarRight>
      </Above>
    </StyledTopBar>
  );
};

export default function Header() {
  return (
    <>
      <Container>
        <TopBar />
        <HeaderOuter>
          <MainHeader>
            <Above breakpoint="lg">
              {matches =>
                matches ? (
                  <>
                    <LogoLink to="/">
                      <Logo />
                    </LogoLink>
                    <CategoryMenu />
                    <RightHeader />
                  </>
                ) : (
                  <MobileMenuArea>
                    <Top>
                      <Left>
                        <LeftHeader />
                      </Left>

                      <Center>
                        <LogoLink to="/">
                          <Logo />
                        </LogoLink>
                      </Center>

                      <Right>
                        <RightHeader/>
                      </Right>
                    </Top>
                  </MobileMenuArea>
                )
              }
            </Above>
          </MainHeader>
          <Above breakpoint="lg">
            {matches =>
              matches ? null : <MobileMenu />
            }
          </Above>
          <Search_new />
        </HeaderOuter>
      </Container>
      <CartFlyout />
      <Favourites />
      <DynamicCategoryRenderer  categoryId={1963} rendererComponents={allComponents} />
    </>
  );
}
