import { useContext } from 'react';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';


const useVisibilitySettings = (settings) => {
    const { loggedIn } = useAuth();
    const { selectedChannel } = useContext(ChannelContext);
    const countryName = selectedChannel?.country?.name.toLowerCase();
    
    // Default to checking the specific country; fall back to 'eu' if the country is not found
    const hideForCountry = settings.hasOwnProperty(countryName) ? settings[countryName] : settings['eu'];
    const hideForLoggedOut = settings.loggedOut && !loggedIn;
    const hideForLoggedIn = settings.loggedIn && loggedIn;
    const hide = hideForCountry || hideForLoggedOut || hideForLoggedIn;
    return hide;
  };

export default useVisibilitySettings;
