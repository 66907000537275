import React, { useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { styled } from 'linaria/react';
import { cx } from 'linaria';
import { theme } from '../../Theme';
import Image from '@jetshop/ui/Image';
import { ReactComponent as AngleRight } from '../../../svg/fa/angle-right.svg';
import Video from '../Content/Video';
import { Above, Below } from '@jetshop/ui/Breakpoints';
import useVisibilitySettings from '../../../utils/useVisibilitySettings';

export const BoxRow = ({ children }) => {
  return (
    <BoxRowWrapper
      className={cx('contentComponent', `boxes-${children.length}`)}
    >
      {children}
    </BoxRowWrapper>
  );
};

const BoxRowWrapper = styled('div')`
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  max-width: 90rem;
  width: 100%;
  margin: auto;
  ${theme.below.xl} {
    padding: 0 1rem;
  }
  ${theme.below.lg} {
    flex-wrap: wrap !important;
  }

  ${theme.below.md} {
    &.boxes-4 {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 1rem;
    }
  }
`;

const BoxWrapper = styled('div')`
  aspect-ratio: ${props => props?.aspect.replace(':', ' / ') ?? '1 / 1'};

  .no-link {
    pointer-events: none;
  }

  .wideLink{
    width: 100%;
    height: 100%;
    display:block;
  }

  position: relative;
  margin-bottom: 1rem;
  width: 100%;
  margin-right: 1rem;
  ${theme.below.lg} {
    margin: 0 0 1rem 0;
  }
    .numItems-4 & {
      ${theme.below.lg} {
        margin: 0 0 1rem 0;
        &.under {
          margin: 0;
          padding: 0;
          & > div {
            margin-top: 0;
            h2 {
              display: none;
            }
            p {
              margin: 0;
            }
            a {
    
              font-size: 14px;
              height: auto;
              line-height: 14px;
              padding-top: 10px;
              padding-bottom: 6px;
              svg {
                margin-right: 0;
              }
            }
          }
        }
      }
    }
  

  ${theme.below.lg} {

  }

  &:last-child  {
    margin-right: 0;
  }
  .image {
    opacity: ${props => (props.under ? '1' : 1)};
  }
`;
const BoxContent = styled('div')`
  a {
    color: ${props => (props.under ? '#000' : '#FFF')};
  }
  position: ${props => (props.under ? 'relative' : 'absolute')};
  top: auto;
  left: 0;
  right: 0;
  bottom: 10%;
  margin: auto;
  color: ${props => (props.under ? '#000' : '#FFF')};
  text-align: center;
  ${theme.below.lg} {
    bottom: 100px;
    transform: none;
    top: auto;
    max-width: 90%;
    margin: auto;
  }
  h2 {
    font-size: 2.5rem;
    line-height: 1.4;
    margin-bottom: 20px;
    font-weight: bold;
    ${theme.below.xl} {
      font-size: 36px;
      margin-bottom: 10px;
    }
    ${theme.below.lg} {
      font-size: 30px;
      white-space: nowrap;
    }
  }
  p {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 10px;
  }
  &.under {
    text-align: center;
    width: 100%;
    max-width: 100%;
    margin-top: 12px;
    padding: 0 1rem;
    bottom: auto;

    h2 {
      font-size: 20px;
      margin-bottom: 0.2em;
    }
  }
  &.notUnder {
    p {
      letter-spacing: 0.3em;
      text-transform: uppercase;
      font-size: 13px;
      margin-bottom: 0;
    }
    h2 + p,
    a + p {
      margin: -10px 0 20px;
    }
  }

  ${theme.below.lg} {
    bottom: 30px;
    &.under {
      width: 100%;
      max-width: 480px;
      margin-top: 10px;
      bottom: auto;
      padding: 0 0.5rem;

      h2 {
        font-size: 16px;
        margin-bottom: 0.5em;
      }

      p {
        font-size: 14px;
      }
    }
    &.notUnder {
      p {
        letter-spacing: 0.3em;
        text-transform: uppercase;
        font-size: 13px;
        margin-bottom: 0;
      }
      a + p {
        margin: -15px 0 20px;
      }
    }
  }
`;

const BoxButton = styled('span')`
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  border-radius: 0;
  text-decoration: none;
  justify-content: center;
  align-items: center;
  height: 53px;
  line-height: 51px;
  display: inline-flex;
  transition: all, 0.2s ease;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  padding: 0 1rem;
  background: transparent;
  color: white;
  border: 1px solid white;
  text-transform: uppercase;
  font-size: 20px;

  &.primary {
    background: ${theme.colors.accent};
    border: 1px solid ${theme.colors.accent};
    &:hover {
      background: ${theme.colors.lightgreen};
      color: white;
      border-color: ${theme.colors.lightgreen};
    }
    &.ghost {
      background: transparent;
      border: 1px solid white;
      &:hover {
        background: ${theme.colors.accent};
        color: white;
        border-color: ${theme.colors.accent};
      }
    }
  }
  &.blackweek {
      background: ${theme.colors.black};
      color: white;
      border-color: ${theme.colors.black};
      &:hover {
        background: ${theme.colors.darkgrey};
        color: white;
        border-color: ${theme.colors.darkgrey};
      }
    }
  &.christmas-red {
    background: ${theme.colors.christmasred};
    color: white;
    border-color: ${theme.colors.christmasred};
    &:hover {
      background: ${theme.colors.red};
      color: white;
      border-color: ${theme.colors.red};
    }
  }

  svg {
    max-width: 1.5rem;
    max-height: 1rem;
    height: 1.5rem;
    margin-left: 0.5rem;
  }
  ${theme.below.xl} {
    padding: 0 1rem;
  }
  ${theme.below.lg} {
    font-size: 20px;
    padding: 10px;
    height: 43px;
    line-height: 1.4;
    width: 85%;
    height: fit-content;
  }
  ${theme.below.sm} {
    font-size: 1rem;
    display: inline-block;
    width: auto;
    padding: 0.5rem 2rem;
  }
  &.under {
    color: #000;
    text-align: center;
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;
    height: auto;
    svg {
      margin-left: 0.5em;
    }
    &:hover {
      background: transparent;
    }
  }
`;

const GifImage = styled('img')`
  object-fit: cover;
  min-width: 100%;
`;

const TargetedLink = ({ to, children, ...rest }) => {
  const externalLink = to?.includes('http');
  const isLink = to?.length > 0; // use for disable click when empty

  if (externalLink) {
    return (
      <a
        {...rest}
        href={to}
        data-external={externalLink}
        target="_blank"
        rel="noreferrer"
        className={'wideLink ' + (isLink ? '' : 'no-link')}
      >
        {children}
      </a>
    );
  }

  return (
    <Link
      to={to}
      {...rest}
      data-external={externalLink}
      className={'wideLink ' + (isLink ? '' : 'no-link')}
    >
      {children}
    </Link>
  );
};

const useTextSizer = (initialSize = 30, unit = 'px') => {
  const textElm = useRef();

  useEffect(() => {
    let start = initialSize;

    while (textElm?.current?.scrollWidth > textElm?.current?.clientWidth) {
      start = start - 1;
      textElm.current.style.fontSize = `${start--}${unit}`;
    }
  }, [textElm?.current]);

  return textElm;
};

const BoxComponent = ({
  linkUrl,
  image,
  heading,
  text,
  subtitle,
  button,
  under,
  aspect,
  vimeoSrc,
  device = null,
  buttonTheme,
  ...rest
}) => {
  const sizes = [1/3, 1/3, 1/3, 1/3, 1, 1]
  const textElm = useTextSizer(30);
  const imageSizes = [1 / 2, 1 / 2, 1 , 1];
  const buttonClass = button?.value?.toLowerCase();
  const componentSelector = ({ image, vimeoSrc }) => {
    if (vimeoSrc?.value) {
      return (
        <Video vimeoSrc={vimeoSrc?.value} aspect={aspect?.value} quality="540p" />
      );
    } else if (image) {
      if (image?.value?.value.toLowerCase().includes('.gif')) {
        return <GifImage src={image} />;
      } else {
        return (
          <Image
            sizes={imageSizes}
            aspect={aspect?.value}
            className="image"
            src={image?.value?.value}
            alt='image'
            sizes={sizes}
            critical={true}
          />
        );
      }
    } else {
      return null;
    }
  };
  return (
    <BoxWrapper
      aspect={aspect?.value}
      under={under?.value}
      className={cx(under?.value ? 'under' : null)}
    >
      <TargetedLink to={linkUrl?.value} style={{ textDecoration: 'none' }}>
        {componentSelector({ image, vimeoSrc })}
      </TargetedLink>
      <BoxContent
        under={under?.value}
        className={under?.value ? 'under' : 'notUnder'}
      >
        {!under.value && <p>{text?.value}</p>}
        <TargetedLink to={linkUrl?.value} style={{ textDecoration: 'none' }}>
          <h2 ref={textElm}>{heading?.value}</h2>
        </TargetedLink>
        {!under.value && <p>{subtitle?.value}</p>}
        {under?.value && <p>{text?.value}</p>}
        {linkUrl?.value && button?.value && (
          <TargetedLink to={linkUrl?.value} style={{ textDecoration: 'none' }}>
            <BoxButton
              under={under?.value}
              className={
                !under?.value ? buttonTheme?.value ?? buttonClass : 'under'
              }
            >
              {button.value}
              {under.value && <AngleRight />}
            </BoxButton>
          </TargetedLink>
        )}
      </BoxContent>
    </BoxWrapper>
  );
};

const Box = ({
  linkUrl,
  image,
  heading,
  text,
  button,
  under,
  subtitle,
  aspect,
  vimeoSrc,
  device = null,
  sweden,
  denmark,
  norway,
  finland,
  netherlands,
  eu,
  loggedOut,
  ...rest
}) => {
  const countryVisibilitySettings = {
    sweden: sweden?.value,
    denmark: denmark?.value,
    norway: norway?.value,
    finland: finland?.value,
    netherlands: netherlands?.value,
    eu: eu?.value,
    loggedOut: loggedOut?.value
  };
  const doNotRender = useVisibilitySettings(countryVisibilitySettings);

  return (
    <>
      { doNotRender ? null : (
        <>
          {device?.value !== 'all' ? (
            <>
              {device?.value === 'desktop' && (
                <Above breakpoint="md">
                  <BoxComponent
                    linkUrl={linkUrl}
                    image={image}
                    heading={heading}
                    text={text}
                    button={button}
                    under={under}
                    aspect={aspect}
                    vimeoSrc={vimeoSrc}
                    device={device}
                    subtitle={subtitle}
                    {...rest}
                  />
                </Above>
              )}
              {device?.value === 'mobile' && (
                <Below breakpoint="md">
                  <BoxComponent
                    linkUrl={linkUrl}
                    image={image}
                    heading={heading}
                    text={text}
                    button={button}
                    under={under}
                    aspect={aspect}
                    vimeoSrc={vimeoSrc}
                    device={device}
                    subtitle={subtitle}
                    {...rest}
                  />
                </Below>
              )}
            </>
          ) : (
            <>
              <BoxComponent
                linkUrl={linkUrl}
                image={image}
                heading={heading}
                text={text}
                button={button}
                under={under}
                aspect={aspect}
                vimeoSrc={vimeoSrc}
                device={device}
                subtitle={subtitle}
                {...rest}
              />
            </>
          )}
        </>
      )}
    </>
  );
};

export default Box;
