import React from 'react';
import { styled } from 'linaria/react';
import Slide from './Slide';
import { theme } from '../../../Theme';

import SlickSlider from 'react-slick';

import 'slick-carousel/slick/slick.css';

import { ReactComponent as Carrot } from '../../../../svg/Carrot.svg';
import { Above } from '@jetshop/ui/Breakpoints';

const SliderWrapper = styled('div')`
  width: 100%;
  max-width: 90rem;
  margin: auto;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0;

  ${theme.below.xl} {
    padding: 0 1rem;
  }
  
  ${theme.below.sm} {
    margin: 0;
    margin-top: 1rem;
  }
  .slick-slider {
    position: relative;

    .slick-slide {
      img {
        width: 100%;
      }
    }
  }
`;

const Arrow = styled('button')`
  background: transparent;
  border: 0;
  height: 50px;
  width: 50px;
  position: absolute;
  top: 50%;
  color: white;
  z-index: 1;

  &.slick-next {
    transform: translateY(-50%) rotate(-90deg);
    right: 20px;
    left: auto;
  }
  &.slick-prev {
    transform: translateY(-50%) rotate(90deg);
    right: auto;
    left: 20px;
  }
  &:focus {
    outline: none;
  }

  svg {
    width: 40px;
    height: auto;
  }
`;

const Dots = styled('ul')`
  position: absolute;
  bottom: 30px;
  left: 0;
  right: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;
const Dot = styled('button')`
  background: white;
  border: 0;
  opacity: 0.4;
  height: 10px;
  width: 10px;
  border-radius: 100%;
  margin: 0 5px;
  &:focus {
    outline: none;
  }

  .slick-active & {
    opacity: 1;
  }
`;

const CustomArrow = props => {
  const { className, onClick } = props;
  return (
    <Arrow className={className} onClick={onClick}>
      <Carrot />
    </Arrow>
  );
};

// const getProps = (properties, prop = 'name') =>
//   properties.reduce(function (r, e) {
//     r[e[prop]] = e.value;
//     return r;
//   }, {});

const Slider = ({
  slides,
  device = 'desktop',
  aspect,
  mobileaspect,
  slidetime,
  ...rest
}) => {
 
  const settings = {
    dots: true,
    infinite: true,
    lazyLoad: false,
    fade: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: slidetime?.value ?? 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomArrow />,
    prevArrow: <CustomArrow />,
    appendDots: dots => <Dots>{dots}</Dots>,
    customPaging: i => <Dot />
  };
  const settingsMobile = {
    dots: true,
    infinite: true,
    lazyLoad: false,
    fade: true,
    autoplay: true,
    autoplaySpeed: slidetime?.value ?? 3000,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    appendDots: dots => <Dots>{dots}</Dots>,
    customPaging: i => <Dot />
  };

  return (
    <SliderWrapper>
      <Above breakpoint="md">
        {matches => {
          const activeSettings = matches ? settings : settingsMobile;
          return (
            <SlickSlider {...activeSettings}>
              {rest.children.map((slide, index) => {
                return (
                  <Slide
                    key={index}
                    {...slide.props}
                    aspect={aspect}
                    mobileaspect={mobileaspect}
                  />
                );
              })}
            </SlickSlider>
          );
        }}
      </Above>
    </SliderWrapper>
  );
};

export default Slider;
