import React, { useEffect } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theme';
import t, { Intl } from '@jetshop/intl';
import { Above } from '@jetshop/ui/Breakpoints';

const Input = styled('input')`
  grid-area: ${(props) => props.gridArea};
  background: transparent;
  border: 1px solid #d2d2d2;
  box-shadow: 0;
  height: 32px;
  color: ${theme.colors.black};
  width: 100%;
  font-size: 16px;
  padding-left: 10px;
  padding-right: 10px;
  margin: auto;
  ${theme.above.md} {
    background: ${theme.colors.white};
  }
  &:focus {
    outline: none;
  }

  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
  }
`;

const SearchInput_new = ({
  isDirty,
  getInputProps,
  gridArea,
  setSearchOpen,
  inputValue,
  setInputValue
}) => {


  return (
    <Intl>
      {t => (
        <Above breakpoint="xs">
          {matches => (
            <Input
              id="search-input-mobile"
              gridArea={gridArea}
              type="search"
              {...getInputProps({
                refKey: 'innerRef'
              })}
              value={inputValue}
              isDirty={isDirty}
              placeholder={
                matches
                  ? t('Search for products for horse, riders and dogs...')
                  : t('Search')
              }
              aria-label={t('Search')}
              onInput={(e) => {
                setInputValue(e.target.value)
                setSearchOpen(true)
              }}
            />
          )}
        </Above>
      )}
    </Intl>
  );
};

export default SearchInput_new;
