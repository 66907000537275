import t from '@jetshop/intl';
import { Query } from 'react-apollo';
import React, { Fragment, useState } from 'react';
import { styled } from "linaria/react";
import ReactModal from 'react-modal';
import SizeguideQuery from './SizeguideQuery.gql';
import { ReactComponent as Cross } from '../../svg/Cross.svg';
import { array } from 'prop-types';

import { theme } from "../Theme";

const InfoModalWrapper = styled(ReactModal)`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  pointer-events: none;
`;

const ModalInside = styled('div')`
  background-color: white;
  padding: 4rem 3rem;
  width: 100vw;
  max-width: 780px;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  pointer-events: all;
  max-height: 70vh;
  min-height: 120px;
  overflow-y: scroll;
  hr {
    margin: 2rem 0 3rem;
    border: 1px solid #235837;
  }
  svg.close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    color: ${theme.colors.primary};
    use,
    g,
    path {
      fill: ${theme.colors.primary};
    }
    ${theme.below.sm} {
      top: 10px;
      right: 10px;
      width: 16px;
    }
  }
  ${theme.below.sm} {
    padding: 1rem;
    max-height: 70vh;
    max-width: 95vw;
  }

  font-family: ${theme.fonts.primary};

  p {
    margin-bottom: 1rem;
  }
  p a,
  p a:visited {
    color: ${theme.colors.primary};
    text-decoration: underline;
  }

  p.p1 {
    font-size: 14px;
    ${theme.below.sm} {
      font-size: 12px;
    }
  }

  h2,
  h3,
  h4,
  strong,
  b {
    font-family: ${theme.fonts.primary};
    text-transform: uppercase;
    letter-spacing: 0.08rem;
  }
  h2 {
    font-size: 1.6rem;
    margin-bottom: 1rem;
  }
  h3 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
    letter-spacing: 0;
    font-size: 1rem;
    text-transform: none;
    ${theme.below.lg} {
      font-size: 1rem;
    }
  }
  strong,
  b {
    font-weight: bold;
  }

  &:after {
    display: none;
  }
  img {
    max-width: 100%;
  }

  table {
    width: 100%;
    text-align: left;
    margin-bottom: 40px;
    p {
      margin: 0;
    }
    tr {
      background: #f7f7f7;
      &:nth-child(even) {
        background: white;
        &:last-child {
          border-bottom: 5px solid #f7f7f7;
        }
      }
      &:first-of-type {
        font-weight: 600;
        font-size: 14px;
      }
      th {
        padding: 5px;
        ${theme.below.lg} {
          font-size: 12px;
        }
      }
      td {
        padding: 5px;
        font-size: 14px;
        ${theme.below.lg} {
          font-size: 11px;
        }
      }
      td:first-of-type {
        width: 20%;
      }
    }
  }
`;

const InfoLink = styled('button')`
  margin: 0;
  width: 100%;
  text-align: left;
  font-weight: bold;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:focus {
    outline: none;
  }
`;
const Title = styled('h2')`
  text-align: center;
  text-transform: uppercase;
  font-size: 1.5rem;
  letter-spacing: 0.35rem;
  max-width: 90%;
  margin: auto;
  margin-bottom: 2rem;
  display: block;
`;

const SizeguideContent = (props) => {
  const field = props.product.customFields.find(
    ({ key }) => key === 'ProductMeasureLists'
  );

  const MeasureList = ({ arrayOrder }) => {
    return (
      <Query query={SizeguideQuery} variables={{ id: 191 }}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return `Error!: ${error}`;
          if (data.category) {
            const cleanArray = data.category.subcategories.filter((cat) =>
              field.listValues.includes(cat.name)
            );

            return cleanArray.map((c, i) => (
              <>
                {i > 0 && <hr />}
                <div
                  dangerouslySetInnerHTML={{
                    __html: c.content,
                  }}
                />
              </>
            ));

            // for (let i = 0; i < data.category.subcategories.length; i++) {
            //   const subcat = data.category.subcategories[i];
            //   if (subcat.name === field.listValues[arrayOrder])
            //     return (
            //       <div
            //         dangerouslySetInnerHTML={{
            //           __html: subcat.content,
            //         }}
            //       />
            //     );
            // }
          }
        }}
      </Query>
    );
  };

  if (field) {
    if (field.listValues.length > 1) {
      if (props.product.articleNumber.includes('-B')) {
        // If the product is a childs model
        const barnArrayIndex = field.listValues
          .map((v, i) => {
            if (v.indexOf('BARN') > -1) {
              return i;
            }
          })
          .filter((v) => v !== undefined);
        if (barnArrayIndex.length > 0) {
          return <MeasureList arrayOrder={barnArrayIndex[0]} />;
        } else {
          // Couldn't find childrens measurement list, show default
          return <MeasureList arrayOrder={0} />;
        }
      } else if (props.product.articleNumber.includes('-D')) {
        // If the product is a womens model
        const damArrayIndex = field.listValues
          .map((v, i) => {
            if (v.indexOf('DAM') > -1) {
              return i;
            }
          })
          .filter((v) => v !== undefined);
        if (damArrayIndex > -1) {
          return <MeasureList arrayOrder={damArrayIndex} />;
        } else {
          // Couldn't find womens measurement list, show default
          return <MeasureList arrayOrder={0} />;
        }
      } else if (props.product.articleNumber.includes('-H')) {
        // If the product is a mens model
        const herrArrayIndex = field.listValues
          .map((v, i) => {
            if (v.indexOf('HERR') > -1) {
              return i;
            }
          })
          .filter((v) => v !== undefined);
        if (herrArrayIndex > -1) {
          return <MeasureList arrayOrder={herrArrayIndex} />;
        } else {
          // Couldn't find mens measurement list, show default
          return <MeasureList arrayOrder={0} />;
        }
      } else {
        // Couldn't match product model to any measurement list, show default
        return <MeasureList arrayOrder={0} />;
      }
    } else {
      // The product only have one measurement list
      return <MeasureList arrayOrder={0} />;
    }
  } else {
    // The product doesn't have any measurement list
    return null;
  }
};

const Sizeguide = (props) => {
  const [open, setOpen] = useState(false);
  return (
    <Fragment>
      <InfoLink
        className={`modalLink ${props.type ? props.type : null}`}
        onClick={() => {
          setOpen(!open);
        }}
      >
        {props.link && props.link}
        {props.children && props.children}
      </InfoLink>
      <InfoModalWrapper
        isOpen={open}
        onRequestClose={() => {
          setOpen(false);
        }}
      >
        <ModalInside>
          <Cross
            className="close-icon"
            onClick={() => {
              setOpen(false);
            }}
          />
          <Title>{t('Size guide')}</Title>
          <SizeguideContent product={props.product} />
          {props.Component && <props.Component {...props} />}
        </ModalInside>
      </InfoModalWrapper>
    </Fragment>
  );
};

export default Sizeguide;
