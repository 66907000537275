import Gallery from './Gallery';

import Badges from '@jetshop/ui/ProductList/Badges';
import React from 'react';
import { styled } from 'linaria/react';
import { useSwitchToVariantImage } from '@jetshop/core/hooks/Product';
import { theme } from '../../Theme';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';

import { badgePriority } from '../../../utils/badgePriority';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;

  ${theme.below.lg} {
    width: 100%;
  }

  .image-gallery-slides {
    line-height: 1;
  }

  .image-gallery-fullscreen-button {
    ${theme.below.lg} {
      display: none !important;
    }
  }

  .image-gallery-content {
    > div:first-child:not(.image-gallery-thumbnails-wrapper) {
      width: 100% !important;
    }

    &.fullscreen {
      .image-gallery-slide-wrapper.left {
        width: 100%;
      }
    }
  }

  .image-gallery-image {
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.03);
      pointer-events: none;
      z-index: 1;
    }
  }

  .image-gallery-thumbnails-wrapper.left {
    margin: 0 !important;
    width: 150px !important;
    ${theme.below.lg} {
      display: none;
    }
  }
  .image-gallery-thumbnails-container {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 auto 0;
    width: 100%;
  }
  .image-gallery-slide-wrapper.left {
    width: calc(100% - 150px);
    ${theme.below.lg} {
      width: 100%;
    }
  }
  .image-gallery-thumbnail {
    width: 100%;
    opacity: 1;
    border: 0;
    margin-right: 6px !important;
    margin-bottom: 10px !important;
    &.active {
      opacity: 1;
      border: 0;
    }
    position: relative;
    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.03);
      pointer-events: none;
      z-index: 1;
    }
    img {
      width: 100%;
      max-height: none !important;
      padding: 3px !important;
    }
  }
  .image-gallery-left-nav,
  .image-gallery-right-nav,
  .image-gallery-fullscreen-button {
    z-index: 1;
  }

  img {
    width: 100%;
    max-height: none !important;
    padding: 3px !important;
  }

  .badges-wrapper {
    > div > div {
      left: 1px;
      top: 10px;
    }
    > div > div > div {
      font-weight: bold;
      font-size: 0.55rem;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      line-height: 1.1;
      text-align: center;
      width: 95px;
      height: 25px;
      color: white;
      background: #235837;
      margin-bottom: 5px;
      ${theme.below.md} {
        height: 20px;
        font-size: 0.5rem;
      }

      &.sale-tagg {
        background: #872C2B;
      }

      &.member-tagg {
        background: #b08832;
      }

      &.best-price-tagg {
        background: #ef7622;
      }

      &.new-tagg {
        background: #000;
      }
    }
  }
`;

export default function ImageContainer({ images, badges, youtube,children, ...rest }) {
  const { loggedIn } = useAuth();
  const galleryRef = React.createRef();
  // Enable switching gallery to image of selected variant
  useSwitchToVariantImage({
    galleryImages: images,
    galleryRef
  });

  let uniqueBadges = badgePriority(badges, 'name');

  if (!loggedIn) {
    uniqueBadges = uniqueBadges.filter(badge => badge.name !== 'Medlem');
  }

  return (
    <Wrapper {...rest}>
      <Gallery
        aspect={'1:1'}
        images={images}
        galleryRef={galleryRef}
        thumbnailPosition={'left'}
        thumbnailSizes={200}
        youtube={youtube}
      >
        <div className="badges-wrapper">
          <Badges badges={uniqueBadges} />
        </div>
        {children}
      </Gallery>
    </Wrapper>
  );
}
