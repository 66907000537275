import { theme } from '../Theme';
import t from '@jetshop/intl';
import { PaginationWrapper } from '@jetshop/ui/Pagination/Pagination';
import React, { useMemo } from 'react';
import { styled } from 'linaria/react';
import { useLocation } from 'react-router';
import qs from 'qs';
import CategoryHeader from '../CategoryPage/CategoryHeader';
import MaxWidth from '../Layout/MaxWidth';
import Filters from './Filters';
import { ProductGrid } from './ProductGrid';
import StandardPage from '../StandardPage/StandardPage';
import CustomerClub from '../Pages/CustomerClub';
import Brands from '../Brands/Brands';
import useInfinitePagination from '@jetshop/core/hooks/useInfinitePagination';
import routeQuery from '../RouteQuery.gql';
import { TrendLinkFunction } from '../ui/Button';


import { SubcategoryPills } from './SubcategoryPills';
const Container = styled(MaxWidth)`
padding: 0;
`;

const CategoryWrapper = styled('div')`
  display: flex;
  flex-direction: column;
`;

const ShowMoreWrapper = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  margin: 1rem 0;
`;

export const ProductNumber = styled('p')`
  font-size: 13px;
  line-height: 13px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 0;
  color: ${theme.colors.black};
  margin-bottom: 1rem;

  ${theme.below.md} {
    display: block;
  }

  &.list-number {
    ${theme.below.md} {
      margin: 1.2rem 0;
    }
  }
`;

const MultiButtonWrapper = styled('div')`
  width: 100%;
  max-width: 90rem;
  padding: 0 1rem;
  margin: auto;
  ${theme.below.md} {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr auto;
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;

    .filter-wrapper {
      grid-area: 3 / 1 / 4 / 3;
    }
  }
`;

const ButtonWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 250px;
  margin: 0 auto 20px auto;

  a {
    display:flex;
    align-items: center;
    width: 100%;
    background-color: ${theme.colors.accent} !important;
    color: #fff !important;
    font-weight: 700 !important;
    font-family: ${theme.fonts.primary};
    font-size: 14px !important;
    line-height: 20px !important;
    letter-spacing: 0.04em !important;
    text-transform: uppercase !important;
    border-radius: 0 !important;
    text-align: center;
    height: 43px !important;
  }
`;

const GraphWrapper = styled('div')`
  width: calc(100% / 3);
  margin: 0 auto;
`;

const GraphBar = styled('div')`
  height: 3px;
  background: #33333333;
  position: relative;
  width: 100%;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    background: ${theme.colors.black};
    left: 0;
    height: 100%;
    width: ${props => Math.round(props.fill)}%;
  }
`;

const StatusBar = ({ percentage }) => {
  return (
    <GraphWrapper>
      <GraphBar fill={percentage} />
    </GraphWrapper>
  );
};

const Category = ({ category, result }) => {
  const location = useLocation();
  const params = qs.parse(location.search, { ignoreQueryPrefix: true });
  const { previous, next, products } = useInfinitePagination({
    result,
    query: routeQuery
  });
  const nowShowing = (products.length - 40) + (category?.products?.result?.length ?? 0);
  const totalResults = category?.products?.totalResults;
  const lessThan40 = products.length < 40;
  const largeProductCardsContent = category?.data?.items?.[0]?.children ?? [];
  if (category && category.id === 216) {
    return <CustomerClub category={category} />;
  }
  if (category && category.id === 540) {
    return <Brands category={category} />;
  }
  if (
    category &&
    category.parent &&
    (category.parent.id === 208 ||
      category.parent.id === 213 ||
      category.parent.id === 204)
  ) {
    return <StandardPage category={category} />;
  }
  return (
    <CategoryWrapper>
      <>
        <CategoryHeader
          category={category}
          parents={result?.data?.route?.parents}
          breadcrumbs={result?.data?.route?.breadcrumbs}
        />

        {(category && category.parent && category.parent.id === 191) ||
        (category && category.id === 191) ? null : (
          <>
            {category && (
              <>
                <MultiButtonWrapper>
                  <SubcategoryPills category={category} keepDash={false} />

                  <Filters
                    filters={category.products?.filters || []}
                    sortOrders={category.products?.sortOrders || []}
                    result={result}
                    lessThan40={lessThan40}
                  />
                </MultiButtonWrapper>
                <Container>
                  {category?.products?.totalResults > 0 ? (
                    <ProductNumber className="list-number">
                      {t('{totalProducts} Products', {
                        totalProducts: category.products.totalResults
                      })}
                    </ProductNumber>
                  ) : null}
                  {previous.hasProducts && (
                    <ButtonWrapper>
                      <TrendLinkFunction
                        className="secondary"
                        onClick={e => {
                          e.preventDefault();
                          previous.fetchProducts();
                        }}
                        to={{
                          ...location,
                          search: qs.stringify({
                            ...params,
                            page: previous.page
                          })
                        }}
                      >
                        {t('Show more products')}
                      </TrendLinkFunction>
                    </ButtonWrapper>
                  )}
                  <ProductGrid
                    id="category"
                    prevOffset={previous.offset}
                    products={products}
                    listName={category.name}
                    categoryPath={
                      category.isDynamic
                        ? null
                        : result.data && result.data.route
                    }
                    loading={result.loading}
                    imageSizes={[1 / 2, 1 / 2, 1 / 3, 1 / 4]}
                    content={largeProductCardsContent}
                  />
                  {next.hasProducts && (
                    <ShowMoreWrapper>
                      <ProductNumber className="small">
                        {t('Showing {nowShowing} of {totalResults} products', {
                          nowShowing,
                          totalResults
                        })}
                      </ProductNumber>
                      <ButtonWrapper>
                        <TrendLinkFunction
                          className="secondary"
                          onClick={e => {
                            e.preventDefault();
                            next.fetchProducts();
                          }}
                          to={{
                            ...location,
                            search: qs.stringify({
                              ...params,
                              page: next.page
                            })
                          }}
                        >
                          {t('Show more products')}
                        </TrendLinkFunction>
                      </ButtonWrapper>
                      <StatusBar
                        percentage={(nowShowing / totalResults) * 100}
                      />
                    </ShowMoreWrapper>
                  )}
                </Container>

                <SubcategoryPills
                  category={category}
                  keepDash={true}
                  alwaysShow={true}
                  extraPadding={true}
                />
              </>
            )}
          </>
        )}
      </>
    </CategoryWrapper>
  );
};

export default Category;
