import { useApolloClient } from '@apollo/react-hooks';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import {
  ExternalLookupQuery,
  LookupQuery,
  activateExternalId,
  activateExternalToken,
  loginExternalCustomer,
} from './ExternalLookup.gql';
import React, { useState } from 'react';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';

export const formatLookupData = (customer, key) => {
  if (!customer) return {};
  return {
    ...customer,
    streetName: customer['address'] ?? {
      masked: undefined,
      encrypted: undefined,
    },
    mobilePhone: customer['mobilePhoneNumber'] ?? {
      masked: undefined,
      encrypted: undefined,
    },
    emailAddress: customer['email'] ?? {
      masked: undefined,
      encrypted: undefined,
    },
    password: { masked: undefined, encrypted: undefined },
    pid: { masked: key, encrypted: key },
  };
};

export const useExternalCustomer = (storage = null) => {
  const client = useApolloClient();
  const history = useHistory();
  const [lookupData, setLookupData] = useState(null);
  const { logIn } = useAuth();
  const storeData = (data) => {
    if (storage) {
      storage(data);
    }
    setLookupData(data);
  };

  const activateExternalById = (externalId) => {
    try {
      return client
        .mutate({
          mutation: activateExternalId,
          variables: { input: { externalCustomerId: externalId } },
        })
        .then(({ data }) => {
          logIn(data.activateExternalCustomerById.token.value);
          return true;
        });
    } catch (error) {
      return false;
    }
  };

  const activateAndLoginExternalByToken = (token, callback = () => {}) => {
    client
      .mutate({
        mutation: activateExternalToken,
        variables: {
          input: token,
        },
        errorPolicy: 'all',
      })
      .then(({ data, errors }) => {
        if (errors.length > 0) {
          switch (errors[0].message) {
            case 'AdditionalUserDataRequired':
              callback({
                status: 'AdditionalUserDataRequired',
                customer: formatLookupData(
                  data.activateExternalCustomerByToken.customer
                ),
              });

              break;
          }
        }
        callback({
          status: 'SemiLogin',
        });
        //LoginExternalByToken(token);
      })
      .catch((test) => console.warn('Something went wrong...'));
  };

  const LoginExternalByToken = (token) => {
    client
      .mutate({
        mutation: loginExternalCustomer,
        variables: { input: { externalCustomerToken: token } },
      })
      .then(({ data }) => {
        logIn(data.loginExternalCustomer.token.value);
        return true;
      })
      .catch((e) => {
        console.warn('Nä, det här blev inte bra...');
        return false;
      });
  };

  // Run external Lookup using key
  const doLookup = (key, callback = () => {}) => {
    return client
      .query({
        query: ExternalLookupQuery,
        variables: { key: key },
      })
      .then(({ data }) => {
        const STATUS = data.externalCustomerLookup.status;
        const customer = data.externalCustomerLookup?.customer;
        switch (STATUS) {
          //User exists but needs activation

          case 'ACTIVATION_REQUIRED':
            callback({
              status: STATUS,
              activate: data.externalCustomerLookup.customer.externalId,
            });
            break;
          //User exists but is missing data
          case 'PREEXISTING_CUSTOMER':
            storeData(key);
            break;
          //User exists but is missing data
          case 'ADDITIONAL_USER_DATA_REQUIRED':
            //Force user to add email manually
            //storeData(formatLookupData(customer, key));
            const stringCustomer = queryString.stringify({
              customer: JSON.stringify(customer),
            });
            history.push(`/signup?${stringCustomer}`);
            break;
          // Nothing found, run normal lookup
          case 'NON_EXISTING_CUSTOMER':
            //storeData(formatLookupData(customer, key));
            history.push('/signup');
            break;

          default:
            console.error('This was unexpected...');
            break;
        }
        return STATUS;
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return {
    lookupData,
    doLookup,
    activateExternalById,
    activateAndLoginExternalByToken,
    formatLookupData,
  };
};
