import AddToCartFormik from '@jetshop/core/components/Mutation/AddToCartFormik';
import React from 'react';
import { toast } from 'react-toastify';
import addToCartMutation from '../../Cart/addToCart.gql';
import CartQuery from '../../Cart/CartQuery.gql';
import ProductToastWrapper from './ProductToast';
import { useVoyadoTracker } from '../../../useVoyadoTracker';

const AddToCartForm = ({ children, product, variant }) => {
  useVoyadoTracker();
  const onAddToCartInit = ({ selectedVariation, mutationId }) => {
    toast(
      <ProductToastWrapper
        selectedVariation={selectedVariation}
        product={product}
      />,
      {
        autoClose: 2000,
        toastId: mutationId,
      }
    );
  };
  const onAddToCartError = () => {
    return ({ selectedVariation, mutationId, error }) => {
      toast.dismiss(mutationId);
      toast(
        <ProductToastWrapper
          selectedVariation={selectedVariation}
          product={product}
          error={error}
        />,
        {
          autoClose: 5000,
          toastId: `${mutationId}--error`,
        }
      );
    };
  };
  return (
    <AddToCartFormik
      onAddToCartInit={onAddToCartInit}
      onAddToCartError={onAddToCartError}
      cartQuery={CartQuery}
      cartMutation={addToCartMutation}
      product={product}
      variant={variant}
    >
      {({ ...props }) => {
        return children(props);
      }}
    </AddToCartFormik>
  );
};

export default AddToCartForm;
