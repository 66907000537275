import React from 'react';
import { css, cx } from 'linaria';
import { theme } from '../Theme';
import { Price } from '../Price';
import { ReactComponent as AngleDown } from '../../svg/fa/angle-down.svg';
import { usePriceDiffer } from './usePriceDiffer';
import {
  DropdownMenu,
  DropdownMenuButton,
  DropdownMenuItem,
  DropdownMenuItems
} from '@jetshop/ui/DropdownMenu';

import { useIntl } from '@jetshop/intl';


export function VariantSelector({ product, variantHandler }) {
  const hasPriceDiffer = usePriceDiffer({
    variants: product?.variants?.values
  });

  const variantOptions = product?.variants?.options;

  return (
    <div className={sharedStyles}>
      {variantOptions.map((option, index) => {
        return (
          <DropdownVariantSelect
            key={option.name}
            option={option}
            doNotDisable={index === 0}
            variantHandler={variantHandler}
            hasPriceDiffer={hasPriceDiffer}
          />
        );
      })}
    </div>
  );
}

function DropdownVariantSelect({
  option,
  variantHandler,
  doNotDisable,
  hasPriceDiffer,

}) {
  const {
    getSelectedValue,
    validateSelection,
    selectValue,
    selectedVariant,
    getVariantForSelection
  } = variantHandler;
  const t = useIntl();
  const selectedValue = getSelectedValue(option);
  return (
    <div className={dropdownStyles}>
      <label htmlFor={`option-${option.name}`}>{option.name}</label>
      <DropdownMenu>
        <DropdownMenuButton id={`option-${option.name}`}>
          {selectedValue ? (
            <>
              <span>{`${option.name}: ${selectedValue}`}</span>
              <span
                className={cx(
                  'chosenStockStatus',
                  (
                    selectedVariant
                      ? selectedVariant?.stockStatus.buyable
                      : null
                  )
                    ? 'buyable'
                    : 'not-buyable'
                )}
              >
                {selectedVariant
                  ? selectedVariant?.stockStatus.text
                      .replace(/\([^)]*\)/, '')
                      .trim()
                  : null}
              </span>
            </>
          ) : (
            `${t('Select')}: ${option.name}`
          )}
          <AngleDown />
        </DropdownMenuButton>
        <DropdownMenuItems style={{ zIndex: 9999 }}>
   
          {option.values.map(value => {
         
            const validation = validateSelection(value, option);
            const variant = getVariantForSelection(value, option);

            // Do not render products with missing prices
            if (variant?.price?.incVat === 0) {
              return <div style={{ display: 'none' }} />;
            }

            return (
              <DropdownMenuItem
                data-testid={value + option.name}
                key={value + option.name}
                disabled={doNotDisable && validation === 'invalid'}
                onSelect={({ setIsOpen }) => {
                  selectValue(value, option);
                  setIsOpen(false);
                }}
              >
                <span className="variant-value">{value}</span>
                <span
                  className={cx(
                    'stockStatus',
                    variant.stockStatus.buyable ? 'buyable ' : 'not-buyable'
                  )}
                >
                  {variant.stockStatus.text.replace(/\([^)]*\)/, '').trim()}
                </span>
                {hasPriceDiffer && (
                  <span className="price">
                    <Price
                      price={variant.price}
                      previousPrice={variant.previousPrice}
                    />
                  </span>
                )}
              </DropdownMenuItem>
            );
          })}
        </DropdownMenuItems>
      </DropdownMenu>
    </div>
  );
}

export const dropdownStyles = css`
  // && wrapping needed to avoid non-deterministic styling
  && {
    > label {
      display: none;
    }
    margin: 1em 0;
    width: 100%;
    [data-flight-dropdown] {
      background: white;
    }
    [data-flight-dropdown-button] {
      justify-content: space-between;
      width: 100%;
      height: 42px;
      display: flex;
      align-items: center;
      background-color: rgb(255, 255, 255);
      position: relative;
      cursor: pointer;
      border: 1px solid rgb(0, 0, 0);
      padding-left: 10px;
      outline: 0;
      font-weight: bold;
      font-size: 14px;
      line-height: 1;
      border-radius: 0;
      svg {
        margin-right: 5px;
        width: 20px;
        height: 20px;
      }
      .chosenStockStatus {
        margin-left: 15px;
        margin-right: 10px;
        text-align: center;
        &.buyable {
          color: ${theme.colors.accent};
        }
        &.not-buyable {
          color: ${theme.colors.orange};
        }
      }
    }
  }
  [data-flight-dropdown-open='true'] {
    [data-flight-dropdown-button] {
      border-bottom-color: transparent;
    }
  }
  [data-flight-dropdown-items] {
    width: 100%;
    border: 1px solid #dedede;
    margin-top: -1px;
  }
  [data-flight-dropdown-item] {
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
    padding: 8px 20px;
    border-top: 0;
    white-space: pre-wrap !important;
    &:hover {
      background: #f7f7f7 !important;
      outline: 0;
    }
    .variant-value {
      margin-right: auto;
    }
    .stockStatus {
      text-align: right;
      font-size: 12px;
    }
    .buyable {
      color: ${theme.colors.accent};
    }
    .not-buyable {
      color: ${theme.colors.orange};
    }
    .price {
      color: #000;
      text-align: right;
      font-size: 12px;
      > div {
        flex-direction: row-reverse;
        justify-content: flex-start;
        display: flex;
        div {
          margin-left: 10px;
        }
        .new-price {
          color: ${theme.colors.red};
          white-space: nowrap;
          width: auto;
          font-weight: bold;
          margin-top: -1px;
        }
        .old-price {
          color: ${theme.colors.grey};
          white-space: nowrap;
          width: auto;
          font-weight: normal;
        }
      }
    }
  }

  &.invalid [data-flight-dropdown-button] {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  svg {
    margin-left: auto;
  }
`;

const sharedStyles = css`
  width: 100%;
  label {
    display: flex;
    padding-bottom: 0.25em;
    margin-top: 1em;
    font-size: 0.875em;
    align-items: center;
  }
  .invalid {
    label {
      color: #eb5757;
    }
  }
  .missingVariant {
    margin-right: 0.25em;
    height: 10px;
    width: 10px;
  }
`;
