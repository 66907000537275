import t from '@jetshop/intl';
import Accordion from '@jetshop/ui/Accordion/Accordion';
import ProductSpecifications from '@jetshop/ui/ProductSpecifications/ProductSpecifications';
import React, { Fragment } from 'react';
import { styled } from "linaria/react";
import {
  ProductSection as UIProductSection,
  SectionHeading,
} from './ProductInfo';
import { theme } from "../Theme";
import Sizeguide from './Sizeguide';

import { ReactComponent as AngleDown } from '../../svg/fa/angle-down.svg';
import { ReactComponent as AngleRight } from '../../svg/fa/angle-right.svg';
import { useQuery } from 'react-apollo';

import CategoryQuery from './CategoryQuery.gql';

const Description = styled('article')``;

const ProductSection = styled(UIProductSection)`
  border-top: 1px solid #d2d2d2;
  font-size: 0.875rem;
  width: 100% !important;
  padding-bottom: 0;
  margin-left: 0;
  &:last-of-type {
    border-bottom: 1px solid #d2d2d2;
  }
  &:first-of-type {
    margin-top: 2.5rem;
  }
  & > div {
    a {
      color: ${theme.colors.accent};
      transition: all ease 0.3s;
    }
    a:hover {
      color: black;
      transition: all ease 0.3s;
    }

    img {
      height: auto;
      max-width: 100%;
      padding: 0.5rem 0;
    }
  }
  ${SectionHeading} {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-weight: bold;
    justify-items: space-between;
    margin-bottom: 0;
    padding: 8px 0;
    font-family: ${theme.fonts.body};
    font-size: 14px;
    + * > :first-child {
      margin-top: 0rem;
      margin-bottom: 1rem;
    }
    ${theme.below.md} {
      font-size: 16px;
    }
  }

  .accordion-content {
    ${theme.below.md} {
      font-size: 12px;
    }
    &.material {
      & > div {
        display: flex;
        p {
          padding-left: 10px;
        }
      }
    }
  }

  .size-guide-wrapper {
    display: none;
  }
  article h2:first-child {
    display: none;
  }
`;

const IndicatorIcon = styled('span')`
  margin-left: auto;
  margin-right: 1rem;
  width: auto;
  height: auto;
  display: none;
`;

const ImportantInfo = styled('div')`
  background: ${theme.colors.tablegrey};
  margin: 1rem 0;
  padding: 0.5rem 1rem;

  p:last-of-type {
    margin-bottom: 8px !important;
  }

  h3 {
    display: inline-block;
    padding-top: 5px;
  }

  svg {
    font-size: 24px;
    * {
      fill: ${theme.colors.orange};
    }
  }
  &.warning {
    svg {
      * {
        fill: ${theme.colors.red};
      }
    }
  }

  div p img {
    padding: 0;
  }
`;

const ProductProperties = styled('ul')`
  list-style: disc inside;
  margin-top: 0.75rem;
  margin-bottom: 10px;
`;

const YoutubeVideo = styled('div')`
  margin: 1.5rem 0;
  iframe {
    width: 100%;
  }
`;
const Content = styled('div')`
  ul {
    list-style: disc inside;
  }
  ol {
    list-style: decimal inside;
  }
`;

const PaymentInfo = () => {
  const id = 1070;
  const { data } = useQuery(CategoryQuery, { variables: { id: id } });
  if (!data) return null;
  return <div dangerouslySetInnerHTML={{ __html: data.category.content }} />;
};

const AccordionIndicator = ({ isOpen }) => (
  <IndicatorIcon>{isOpen ? <AngleDown /> : <AngleRight />}</IndicatorIcon>
);

const fixSwedishChars = (str) => {
  return str  
    ?.replace(/Š/g, 'ä')
    ?.replace(/š/g, 'ö')
    ?.replace(/Œ/g, 'å')
    ?.replace(/Ž/g, 'Ä')
    ?.replace(/ž/g, 'Ö')
    ?.replace(/Œ/g, 'Å');
};

const ProductInfoAccordion = ({
  product,
  brandInfo,
  washing,
  importantInfo,
  importantinfoheadline,
  warningInfo,
  material,
  materialInfo,
  youtube,
  goodtoknow,
  artNo_partial,
  manufacturer
}) => {
  const field = product?.customFields.find(
    ({ key }) => key === 'ProductMeasureLists'
  );

  const fixedManufacturer = manufacturer && fixSwedishChars(manufacturer?.value);

  function getYoutube(youtube) {
    const youtubeLink = youtube.value.match(
      /(?:https?:\/\/|www\.|m\.|^)youtu(?:be\.com\/watch\?(?:.*?&(?:amp;)?)?v=|\.be\/)([\w‌​\-]+)(?:&(?:amp;)?[\w\?=]*)?/
    );
    return (
      <iframe
        width="560"
        height="315"
        src={'https://www.youtube.com/embed/' + youtubeLink[1]}
        frameborder="0"
        allowfullscreen
      />
    );
  }
  return (
    <>
      <Accordion single initialOpenIndexes={[0, 6]}>
        {({ openIndexes, handleClick, AccordionContent }) => (
          <Content>
            <ProductSection>
              <SectionHeading onClick={() => handleClick(0)}>
                <span>{t('Product info')}</span>
                <AccordionIndicator isOpen={openIndexes.includes(0)} />
              </SectionHeading>
              <AccordionContent isOpen={openIndexes.includes(0)}>
                <div className="accordion-content">
                  <Description
                    dangerouslySetInnerHTML={{
                      __html: product.description
                    }}
                  />
                  {washing ? (
                    <ProductProperties>
                      {washing.value.split('<br/>').map(w => (
                        <li key={w}>{w}</li>
                      ))}
                    </ProductProperties>
                  ) : null}
                  {brandInfo ? (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: brandInfo.value
                      }}
                    />
                  ) : null}

                  {importantinfoheadline || importantInfo ? (
                    <ImportantInfo>
                      {importantinfoheadline ? (
                        <h3>{importantinfoheadline.value}</h3>
                      ) : null}
                      {importantInfo ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: importantInfo.value
                          }}
                        />
                      ) : null}
                    </ImportantInfo>
                  ) : null}
                  {warningInfo ? (
                    <ImportantInfo className="warning">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: warningInfo.value
                        }}
                      />
                    </ImportantInfo>
                  ) : null}
                </div>
              </AccordionContent>
            </ProductSection>

            {material || materialInfo ? (
              <ProductSection>
                <SectionHeading onClick={() => handleClick(1)}>
                  <span>{t('Product specification')}</span>
                  <AccordionIndicator isOpen={openIndexes.includes(1)} />
                </SectionHeading>
                <AccordionContent isOpen={openIndexes.includes(1)}>
                  <div className="accordion-content material">
                    {material ? (
                      <div>
                        <span>{material.title}:</span>
                        <p>{material.value}</p>
                      </div>
                    ) : null}
                    {materialInfo ? (
                      <section
                        dangerouslySetInnerHTML={{
                          __html: materialInfo.value
                        }}
                      />
                    ) : null}
                  </div>
                </AccordionContent>
              </ProductSection>
            ) : null}

            {manufacturer ? (
              <ProductSection>
                <SectionHeading onClick={() => handleClick(2)}>
                  <span>{t('Manufacturer')}</span>
                  <AccordionIndicator isOpen={openIndexes.includes(2)} />
                </SectionHeading>
                <AccordionContent isOpen={openIndexes.includes(2)}>
                  <div className="accordion-content material">
                    {manufacturer ? (
                      <div className="product-info">
                        <p style={{ whiteSpace: 'pre-wrap' }}>{fixedManufacturer}</p>
                      </div>
                    ) : null}

                    {materialInfo ? (
                      <section
                        dangerouslySetInnerHTML={{
                          __html: materialInfo.value
                        }}
                      />
                    ) : null}
                  </div>
                </AccordionContent>
              </ProductSection>
            ) : null}

            {field ? (
              <ProductSection>
                <SectionHeading>
                  <Sizeguide product={product}>
                    {t('Size guide')}
                    <AccordionIndicator isOpen={openIndexes.includes(3)} />
                  </Sizeguide>
                </SectionHeading>
                <AccordionContent
                  isOpen={openIndexes.includes(3)}
                ></AccordionContent>
              </ProductSection>
            ) : null}

            {goodtoknow ? (
              <ProductSection>
                <SectionHeading onClick={() => handleClick(4)}>
                  <span>{t('Good to know')}</span>
                  <AccordionIndicator isOpen={openIndexes.includes(4)} />
                </SectionHeading>
                <AccordionContent isOpen={openIndexes.includes(4)}>
                  <div className="accordion-content">
                    {goodtoknow ? (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: goodtoknow.value
                        }}
                      />
                    ) : null}
                  </div>
                </AccordionContent>
              </ProductSection>
            ) : null}

            <ProductSection>
              <SectionHeading onClick={() => handleClick(5)}>
                <span>{t('Delivery & Return')}</span>
                <AccordionIndicator isOpen={openIndexes.includes(5)} />
              </SectionHeading>
              <AccordionContent isOpen={openIndexes.includes(5)}>
                <div className="accordion-content">
                  <PaymentInfo />
                </div>
              </AccordionContent>
            </ProductSection>
            <ProductSection>
              <SectionHeading onClick={() => handleClick(6)}>
                <span>{t('Reviews & FAQ')}</span>
                <AccordionIndicator isOpen={openIndexes.includes(6)} />
              </SectionHeading>
              <AccordionContent isOpen={openIndexes.includes(6)}>
                <div className="accordion-content" id="allReviews">
                  <div
                    style={{ marginTop: '4px' }}
                    id="gmf-comment-section"
                    data-product-id={artNo_partial}
                  ></div>
                </div>
              </AccordionContent>
            </ProductSection>
          </Content>
        )}
      </Accordion>
      {youtube && <YoutubeVideo>{getYoutube(youtube)}</YoutubeVideo>}
    </>
  );
};



export default ProductInfoAccordion;
