import { theme } from "../Theme";
import React from 'react';
import { styled } from "linaria/react";

const UspItemWrapper = styled('div')`
display: flex;
justify-content: center;
background: white;
border-bottom: 1px solid #ddd;
border-top: 1px solid #ddd;
margin-bottom: 10px;
padding: 5px 5px;
font-family: ${theme.fonts.primary};
font-size: 13px;
line-height: 24px;
letter-spacing: 0.02em;   
`;


export const Usps = ({ children }) => {
return (
<UspItemWrapper>
    {children}
</UspItemWrapper>
);
};

export const UspItem = ({ foregroundColor, htmlcontent }) => {
    return (
        <div style={{color: `${foregroundColor?.value}`}} dangerouslySetInnerHTML={{__html : htmlcontent?.value}}/>
    )
}


