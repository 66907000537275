import t from '@jetshop/intl';
import ChannelSelector, {
  SelectorBody,
  Title,
} from '@jetshop/ui/ChannelSelector/ChannelSelector';
import MiniSelector from '@jetshop/ui/ChannelSelector/MiniSelector';
import React, { useState } from 'react';
import { styled } from "linaria/react";
import { Above } from '@jetshop/ui/Breakpoints';
import { theme } from "../../../Theme";
import flattenCountries from './flattenCountries';

const Country = styled('div')`
  height: 42px;
  font-family: ${theme.fonts.primary};
  font-size: 14px;
  font-weight: normal;
  background-color: #fff;
  letter-spacing: 0.2px;
  padding: 0 14px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 200ms;
  color: #000;
  ${theme.below.md} {
    background-color: #fff;
    color: ${theme.colors.primary};
  }

  :hover {
    background: ${theme.colors.background};
    transition: all ease 0.4s;
    ${theme.below.md} {
      background-color: ${theme.colors.background};
    }
  }
`;

const CountryFlag = styled('img')`
  height: 20px;
  width: 20px;
  margin-right: 15px;
`;

const StyledTitle = styled(Title)`
  color: ${theme.colors.primary};
  text-align: center;
  padding: 16px 0 16px;
  font-size: 1rem;
  font-family: ${theme.fonts.primary};
  letter-spacing: 1px;
`;

const StyledSelectorBody = styled(SelectorBody)`
  ${theme.below.lg} {
    width: 100%;
    max-width: 100%;
    padding: 0;
  }
`;

const ChannelList = styled('section')`
  :last-of-type {
    margin-bottom: 1rem;
    ${theme.below.md} {
      margin-bottom: 0;
    }
  }
`;

const Selector = ({
  type = MiniSelector,
  channels,
  selectedChannel,
  hideTarget,
  updateChannel,
  hideMobile,
}) => {
  const [countries] = useState(flattenCountries(channels));
  const Comp = type;

  return (
    <ChannelSelector
    channels={channels}
      initialChannel={selectedChannel}
      render={({
        channels,
        currencyItems,
        languageItems,
        selectedChannel,
        onSelect,
        hasChanged,
        onCurrencySelect,
        onLanguageSelect,
      }) => {
        return (
          <Comp style={type === MiniSelector ? { width: '16rem' } : null}>
            <StyledSelectorBody>
              <Above breakpoint="lg">
                <StyledTitle>{t('Select channel')}</StyledTitle>
              </Above>

              <>
                {channels.map((channel) => (
                  <ChannelList key={channel.id}>
                    {channel.name==='EU' ? (
                      <>
                        <Country
                          isActive={channel.id === selectedChannel.id}
                          onClick={() => {
                            updateChannel(
                              onSelect(
                                channel.id,
                                channel.defaultLanguage.culture,
                                channel.defaultCurrency.id
                              )
                            );
                            if (hideTarget) {
                              hideTarget();
                            } else {
                              hideMobile();
                            }
                          }}
                        >
                          <CountryFlag
                            src={`https://countryflags.jetshop.io/${channel.name}/flat/32.png`}
                            alt="Country Flag"
                          />
                          {channel.name}
                        </Country>
                      </>
                    ) : (
                      <>
                        <Country
                          isActive={channel.id === selectedChannel.id}
                          onClick={() => {
                            updateChannel(
                              onSelect(
                                channel.id,
                                channel.defaultLanguage.culture,
                                channel.defaultCurrency.id
                              )
                            );
                            if (hideTarget) {
                              hideTarget();
                            } else {
                              hideMobile();
                            }
                          }}
                        >
                          <CountryFlag
                            src={`https://countryflags.jetshop.io/${channel.countries[0].code}/flat/32.png`}
                            alt="Country Flag"
                          />
                          {channel.countries[0].name}
                        </Country>
                      </>
                    )}
                  </ChannelList>
                ))}
              </>
            </StyledSelectorBody>
          </Comp>
        );
      }}
    />
  );
};

export default Selector;
