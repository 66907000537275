/* eslint-disable */

import { useContext, useEffect } from 'react';
import { useCart } from '@jetshop/core/components/Query/CartProvider';
import CartQuery from './components/Cart/CartQuery.gql';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';

const environment =
  process.env.NODE_ENV === 'development'
    ? 'https://assets.voyado.com/jsfiles/analytics_0.1.6.staging.min.js'
    : 'https://assets.voyado.com/jsfiles/analytics_0.1.6.min.js';

function initVoyado(tenant) {
  (function(i, s, o, g, r, a, m) {
    i['VoyadoAnalyticsObject'] = r;
    (i[r] =
      i[r] ||
      function() {
        (i[r].q = i[r].q || []).push(arguments);
      }),
      (i[r].l = 1 * new Date());
    (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
  })(window, document, 'script', environment, 'va');

  window.va('setTenant', tenant);
}

function useVoyadoTracker({ customer } = { customer: null }) {
  const { cart } = useCart(CartQuery);
  const { selectedChannel } = useContext(ChannelContext);
  const { loggedIn } = useAuth();

  useEffect(() => {
    if (customer?.externalId) {
      window?.va?.loaded && window.va('setContactId', customer.externalId);
    }
  }, [customer]);

  useEffect(() => {
    if (window?.va?.loaded) {
      if (cart?.items?.length) {
        return window.va.cart({
          cartRef: cart.id,
          cartUrl: cart.externalCheckoutUrl,
          contactId: loggedIn && customer && customer?.externalId,
          locale: selectedChannel.language.culture,
          items: cart.items.map(item => ({
            itemId: item.articleNumber,
            quantity: item.quantity
          }))
        });
      }
      if (window?.__cartId__ && cart?.items?.length === 0) {
        window.va.emptyCart({
          contactId: customer && customer?.externalId,
          cartRef: window.__cartId__
        });
      }
    }
  }, [cart, customer, selectedChannel.language.culture, loggedIn]);
}

export { useVoyadoTracker, initVoyado };
