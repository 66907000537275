import { Shortcodes } from '@jetshop/flight-shortcodes';
import React from 'react';
import { styled } from "linaria/react";
import MaxWidth from '../Layout/MaxWidth';
import Image from '@jetshop/ui/Image';
import { theme } from "../Theme";
// import striptags from 'striptags';

const components = {
  img: (props) => <Image {...props} aspect="4:3" />,
};

const ShortcodeWrapper = styled(MaxWidth)`
  align-items: center;
  ${theme.above.md} {
    padding: 2rem;
  }
  ${theme.below.md} {
    padding: 2rem;
  }
  div {
    width: 100%;
  }
`;

export default function DynamicContent({ content, additionalComponents }) {
  // const cleanedContent = striptags(content.replace(/\u00a0/g, ' '), '<strong>');
  const allComponents = { ...components, ...additionalComponents };
  return (
    <ShortcodeWrapper>
      <Shortcodes content={content} components={allComponents} />
    </ShortcodeWrapper>
  );
}
