import { styled } from "linaria/react";
import t from '@jetshop/intl';
import get from 'lodash.get';
import React from 'react';
import { Query } from 'react-apollo';
import pagesQuery from './PagesQuery.gql';
import ContentPageLink from '@jetshop/ui/ContentPageLink';

const PageList = styled('div')`
  ul {
    columns: 2;
    li {
      text-align: left;
    }
  }
`;

const FooterLinks = () => (
  <Query query={pagesQuery}>
    {({ data }) => {
      const pages = get(data, 'pages');
      return pages ? (
        <PageList>
          <h2>{t('Customer service')}</h2>
          <ul>
            {pages.map(
              page =>
                page.primaryRoute &&
                page.id != 62 && (
                  <li key={page.id}>
                 
                    {page.hasExternalUrl ? <a target={page.externalUrl.target} href={page.externalUrl.link}>{page.name}</a> : <ContentPageLink page={page}>{page.name}</ContentPageLink>}

                  </li>
                )
            )}
          </ul>
        </PageList>
      ) : null;
    }}
  </Query>
);

export default FooterLinks;
