import t from '@jetshop/intl';
import React from 'react';
import { css } from 'linaria';
import { AddToCart } from './AddToCart';
import { SelectVariant } from './SelectVariant';
import { ReactComponent as TrashSVG } from '../../svg/trash.svg';
import { useProductList } from '@jetshop/core/hooks/ProductList';
import { styled } from 'linaria/react';
import Image from '@jetshop/ui/Image';
import { Price } from '../Price';
import ProductLink from '@jetshop/ui/ProductLink';
import { useDynamicPrice } from '@jetshop/core/hooks/useDynamicPrice';

import { theme } from '../Theme';

const Wrapper = styled('div')`
  margin-bottom: 1rem;
  padding-bottom: 1.25rem;
  border-bottom: 1px solid #ddd;

  a {
    text-decoration: none;
    color: inherit;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-bottom: 0.25rem;
    padding: 0 10px;
  }

  h3 {
    color: ${theme.colors.primary};
    font-weight: normal;
    font-size: 12px;
    letter-spacing: 1px;
    line-height: 1.15;
    text-transform: uppercase;
  }

  .product-card-detail {
    background: transparent;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 20px;
  }

  .price-wrapper {
    font-weight: bold;
    font-size: 14px;

    > div {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      .old-price {
        color: ${theme.colors.grey};
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        margin-right: 5px;
        line-height: 1rem;
        font-weight: normal;
      }
      .new-price {
        color: #b13827;
        margin-right: 8px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        line-height: 1rem;
      }
      .price {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        line-height: 1rem;
        margin-right: 0;
      }
    }
  }
`;

const ImageWrapper = styled('div')`
  width: 80px;
  height: 80px;
`;

const FlexedList = styled('div')`
  position: relative;
`;

export function Product({ product, loading, children, hideTarget }) {
  // Use the variant's image for display
  const productForDisplay = { ...product };
  const hasImages = product.images && product.images.length > 0;
  if (product?.isVariant && product?.variant?.images) {
    productForDisplay.images = product.variant.images;
  }

  const price = useDynamicPrice(product, product.variant);
  return (
    <Wrapper>
      <FlexedList>
        <ProductLink product={product} onClick={hideTarget}>
          <ImageWrapper>
            {hasImages ? (
              <Image
                aspect="1:1"
                alt={product.images[0].alt}
                src={product.images[0].url}
              />
            ) : (
              <Image aspect="1:1" />
            )}
          </ImageWrapper>
          <section className="product-card-detail">
            <h3>{product.name}</h3>
            <div className="price-wrapper">
              <Price price={price.price} previousPrice={price.previousPrice} />
            </div>
          </section>
        </ProductLink>
        <RemoveFromList
          articleNumber={product.articleNumber}
          variant={product.variant}
        />
      </FlexedList>
      {product.hasVariants && (
        <div className="select-variant">
          <SelectVariant product={product} />
        </div>
      )}
      {product.validation.status === 'valid' ? (
        <AddToCart product={product} />
      ) : (
        <InvalidProduct validation={product.validation} />
      )}
    </Wrapper>
  );
}

function RemoveFromList({ children, articleNumber, variant }) {
  const { remove } = useProductList();
  const variantArticleNumber = variant?.articleNumber;
  return (
    <button
      className={removeStyles}
      onClick={() => remove(articleNumber, { variantArticleNumber })}
    >
      <TrashSVG />
    </button>
  );
}

function InvalidProduct({ validation }) {
  const { status } = validation;
  const reasonMessages = {
    outOfStock: t('Out of stock'),
    missingVariant: t('Select options'),
    preOrder: t('Pre-order date required'),
    configurations: t('Visit product page to add'),
  };
  return (
    <div className={invalidProductStyles}>
      {reasonMessages[status] || t('Not available')}
    </div>
  );
}

const removeStyles = css`
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  background: #fff;
  outline: none;
  svg {
    height: 15px;
    width: 15px;
  }
`;

const invalidProductStyles = css`
  background: #f3f3f3;
  border: 1px solid #ddd;
  color: grey;
  cursor: not-allowed;
  font-size: 12px;
  margin: 0 0.5rem;
  padding: 0.25rem 0.5rem;
  text-align: center;
  text-transform: uppercase;
  padding: 0.75rem 0.5em;
`;
