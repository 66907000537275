import React from 'react';
import { styled } from 'linaria/react';
import { ContentPositioner } from './ContentPositioner';
import { cx, css } from 'linaria';
import { CTA } from './CTA';

import { theme } from '../../../Theme';

const ContentWrapper = styled('div')`
  color: ${props => props.color};
  width: 100%;

  a {
    text-decoration: none;
  }

  h2 {
    font-family: ${theme.fonts.primary};
    font-size: 40px;
    line-height: 44px;
    letter-spacing: 0.05em;
    ${theme.below.lg} {
      font-size: 40px;
      line-height: 44px;
    }

    &.bold {
      font-weight: 700;
    }
  }
  h3 {
    font-family: ${theme.fonts.primary};
    font-size: 20px;
    line-height: 27px;
    font-weight: 400;
    letter-spacing: 0.1em;
    margin-bottom: 20px;
    ${theme.below.lg} {
      font-size: 18px;
      line-height: 18px;
      font-weight: 400;
    }
  }
  .text {
    margin-top: 30px;
    letter-spacing: 0.03em;

    p {
      font-family: ${theme.fonts.primary};
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 10px;
      letter-spacing: 0.02em;
    }
    a {
      color: inherit;
      text-decoration: underline;
    }

    ul,
    ol {
      font-family: ${theme.fonts.primary};
      font-size: 14px;
      line-height: 1.5;
      margin-bottom: 10px;
      letter-spacing: 0.02em;
    }
    ul,
    ol {
      list-style: revert;
    }
  }

  &.hero {
    h2 {
      font-family: ${theme.fonts.primary};
      font-size: 60px;
      line-height: 80px;
      letter-spacing: 0.05em;
      ${theme.below.lg} {
        font-size: 40px;
        line-height: 44px;
      }
    }
  }
`;

const heroButtonStyle = css`
  margin-top: 30px;
  padding: 0 27px;
`;

const boxButtonStyle = css`
  margin-top: 23px;
  ${theme.below.lg} {
    margin-top: 2rem;
  }
`;

export const BoxContent = ({
  verticalPosition,
  horizontalPosition,
  color,
  boldTitle,
  preTitle,
  title,
  text,
  buttonText,
  buttonTheme,
  handleDummyButtonClick,
  link,
  hasImage,
  forType
}) => {
  return (
    <ContentPositioner
      verticalPosition={verticalPosition?.value ?? verticalPosition}
      horizontalPosition={horizontalPosition?.value ?? horizontalPosition}
      relative={hasImage ? false : true}
    >
      <ContentWrapper color={color?.value ?? '#fff'} className={forType}>
        <div className="titles-wrapper">
          {preTitle?.value && (
            <h3
              dangerouslySetInnerHTML={{
                __html: preTitle?.value
              }}
            />
          )}
          {title?.value && (
            <h2
              className={cx(boldTitle?.value && 'bold')}
              dangerouslySetInnerHTML={{
                __html: title?.value
              }}
            />
          )}
        </div>
        {text?.value && (
          <div
            className="text"
            dangerouslySetInnerHTML={{
              __html: text?.value
            }}
          />
        )}
        {buttonText?.value && link?.value && (
          <CTA
            link={link?.value}
            className={cx(
              buttonTheme?.value,
              forType === 'hero' ? heroButtonStyle : boxButtonStyle
            )}
            clickCallback={handleDummyButtonClick}
          >
            {buttonText?.value}
          </CTA>
        )}
      </ContentWrapper>
    </ContentPositioner>
  );
};
