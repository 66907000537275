import React from 'react';
import { Query } from 'react-apollo';
import { styled } from 'linaria/react';
import get from 'lodash.get';

import Image from '@jetshop/ui/Image';
import { Link } from 'react-router-dom';
import { theme } from '../Theme';

import MaxWidth from '../Layout/MaxWidth';
import {
  StyledBreadcrumbs,
  CategoryName,
  CategoryContent,
  Inner,
} from '../CategoryPage/CategoryHeader';

import { BrandsQuery } from './BrandsQuery.gql';

const BrandsWrapper = styled(MaxWidth)`
  width: inherit;
`;

const StyledInner = styled(Inner)`
  padding-top: 10px;
  margin-top: 0.5rem;

  h1 {
    text-align: center;
  }
`;

const StyledCategoryContent = styled(CategoryContent)``;

const BrandsList = styled('section')`
  margin-top: 4rem;
  margin-bottom: calc(4rem - 5px);
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  ${theme.below.lg} {
    margin-top: 1rem;
  }
`;

const StyledLink = styled(Link)`
  display: inline-block;
  width: calc((100% - 16px) / 4);
  margin: 2px;
  ${theme.below.lg} {
    width: calc((100% - 12px) / 3);
  }
  ${theme.below.sm} {
    width: calc((100% - 8px) / 2);
  }
  ${theme.below.xs} {
    width: 100%;
  }
`;

const Brand = styled('div')`
  background: ${theme.colors.tablegrey};
  padding: 30px 40px;
  max-width: 100%;
  transition: all ease 0.3s;
  .imageWrapper {
    max-width: 150px;
    margin: auto;
  }
  &:hover {
    background: ${theme.colors.lightgrey};
  }
  ${theme.below.lg} {
    padding: 20px 30px;
  }
  ${theme.below.sm} {
    padding: 10px 20px;
  }
`;

const Brands = ({ category, cacheHit }) => {
  const parents = get(category, 'route.parents');
  const breadcrumbProps = {
    breadcrumbText: category?.breadcrumbText,
    parents,
  };
  return (
    <BrandsWrapper>
      {!cacheHit ? null : (
        <>
          {/* {category?.images && category?.images?.length > 0 ? (
            <CategoryImage>
              <Image
                src={category?.images}
                quality={80}
                crop={true}
                cover={true}
                gravity="north"
                aspect={'5:2'}
                alt={category?.name}
              />
            </CategoryImage>
          ) : null} */}
          <StyledInner>
            <StyledBreadcrumbs {...breadcrumbProps} />
            <CategoryName data-testid="page-header">
              {category?.name}
            </CategoryName>
            <StyledCategoryContent
              data-testid="category-description"
              dangerouslySetInnerHTML={{
                __html: category?.content,
              }}
            />
          </StyledInner>
        </>
      )}
      <Query query={BrandsQuery}>
        {({ loading, error, data }) => {
          if (loading) return null;
          if (error) return `Error!: ${error}`;
          const brands = data?.category?.subcategories;
          return (
            <BrandsList>
              {brands.map((brand) => (
                <StyledLink to={brand?.primaryRoute?.path} key={brand?.id}>
                  <Brand key={brand?.id}>
                    <div className="imageWrapper">
                      <Image
                        src={brand?.images[4]?.url}
                        aspect="2:1"
                        alt={brand?.name}
                      />
                    </div>
                  </Brand>
                </StyledLink>
              ))}
            </BrandsList>
          );
        }}
      </Query>
    </BrandsWrapper>
  );
};

export default Brands;
