import { Above } from '@jetshop/ui/Breakpoints';
import CategoryLink from '@jetshop/ui/CategoryLink';
import ProductLink from '@jetshop/ui/ProductLink';
import React from 'react';
import { styled } from 'linaria/react';
import t from '@jetshop/intl';
import { ProductGrid } from '../../../CategoryPage/ProductGrid';
import { Button } from '../../../ui/Button';

import { theme } from '../../../Theme';
import { cleanTextString } from '../../../CategoryPage/CategoryHeader';

const SearchResultContainer = styled('div')`
  position: absolute;
  top: 100%;
  background: #fff;
  z-index: 5;
  left: 0;
  padding: 10px;
  width: 100%;
  text-align: center;
  padding: 4rem 2rem;
  overflow: auto;
  ${theme.below.lg} {
    padding: 0;
    h2 {
      font-size: 14px;
      color: #000;
      font-weight: normal;
      padding: 10px;
    }
    & > button {
      background: #000;
      color: #fff;
      font-size: 14px;
      margin-bottom: 3rem;
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    width: 99vw;
    left: 50%;
    right: auto;

    transform: translateX(-50%);
    height: 100%;
    background: #fff;
    pointer-events: none;
    z-index: -1;
  }

  a:hover {
    text-decoration: none;
  }
`;

const SeeAll = styled(Button)`
  width: auto;
  background: #1d5630;
  color: #fff;
  font-weight: normal;
  font-size: 16px;
  &:hover {
    opacity: 0.6;
    background: #1d5630;
    color: #fff;
  }
  /* ${theme.below.md} {
    background: #000;
    color: #fff;
    font-size: 14px;
    margin-bottom: 3rem;
  } */
`;

const RowWrapper = styled('div')`
  ${theme.below.lg} {
  max-height: 500px;
  margin-bottom: 1rem;
  overflow: scroll;
  scroll-behavior: smooth;
  }
`;

const Row = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 650px;
  margin: auto;
`;
const Section = styled('div')`
  flex: 1;
  text-align: left;
  margin-bottom: 20px;
  background: #f7f7f7;
  margin: 0 10px 30px 10px;
  padding: 20px;

  h3 {
    margin-bottom: 0.3rem;
  }

  ul {
    li {
      margin-bottom: 5px;
      a {
        display: block;
        transition: all, 0.2s ease;
        line-height: 1.2;
        padding: 2px 0;
        &:hover {
          color: ${theme.colors.primary};
          text-decoration: underline;
        }
      }
    }
  }
`;

const SearchResult = ({ term, result, setSearchOpen, triggerSearch }) => {
  return (
    <SearchResultContainer onClick={() => setSearchOpen(false)}>
      {/* <Above breakpoint="md">
        {(matches) =>
          matches ? ( */}
            <>
              {result === undefined ? (
                <>
                  <SeeAll
                    style={{ marginTop: '1.5rem' }}
                    onClick={() => {
                      setSearchOpen(false);
                      triggerSearch();
                    }}
                  >
                    {t('See all results')}
                  </SeeAll>
                </>
              ) : (
                <RowWrapper>
                  <Row>
                    {result?.categories?.result.length > 0 && (
                      <Section>
                        <h3>{t('Categories')}</h3>
                        
                        <ul>
                          {result.categories.result.map((category, index) => {
                            const displayText =
                              cleanTextString(parentsToString(
                                category.primaryRoute.parents
                              ) + category.name);
                            return (
                              <li key={index}>
                                <CategoryLink category={category}>
                                  {getHighlightedText(displayText, term)}
                                </CategoryLink>
                              </li>
                            );
                          })}
                        </ul>
                      </Section>
                    )}
                    {result?.products?.result.length > 0 && (
                      <Section>
                        <h3>{t('Products')}</h3>
                        <ul>
                          {result.products.result.map((product, index) => (
                            <li key={index}>
                              <ProductLink product={product}>
                                {getHighlightedText(product.name, term)}
                              </ProductLink>
                            </li>
                          ))}
                        </ul>
                      </Section>
                    )}
                  </Row>
                  <SeeAll
                    onClick={() => {
                      setSearchOpen(false);
                      triggerSearch();
                    }}
                  >
                    {t('See all results')}
                  </SeeAll>
                </RowWrapper>
              )}
            </>
    </SearchResultContainer>
  );
};

function parentsToString(parents) {
  if (!parents || parents.length === 0) return '';
  let sortedParents =
    parents.length > 1
      ? [...parents].sort((a, b) => {
          if (a.id.length < b.id.length) {
            return -1;
          } else {
            return 1;
          }
        })
      : parents;
  return (
    sortedParents
      .map((parent) => parent?.object?.breadcrumbText ?? [])
      .join(' > ') + ' > '
  );
}

function getHighlightedText(text, highlight) {
  // Split on higlight term and include term into parts, ignore case
  const parts = text.split(
    new RegExp(`(${escapeRegExp(highlight).split(' ').join('|')})`, 'gi')
  );
  const highlightArray = highlight.split(' ');
  const toHighlightLower = highlightArray.map((word) => word.toLowerCase());
  return parts.reduce((prev, curr, index) => {
    return [
      ...prev,
      toHighlightLower.includes(curr.toLowerCase()) ? (
        <strong
          style={{
            fontWeight: 600,
          }}
          key={index}
        >
          {curr}
        </strong>
      ) : (
        curr
      ),
    ];
  }, []);
}

function escapeRegExp(string) {
  return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

export default SearchResult;
