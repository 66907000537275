import React, { Component } from 'react';
import { styled } from 'linaria/react';
import { Query } from 'react-apollo';
import { cx, css } from 'linaria';
import t, { Intl } from '@jetshop/intl';
import ProductColorQuery from './ProductColorsQuery.gql';
import ProductLink from '@jetshop/ui/ProductLink';

import { ReactComponent as AngleDown } from '../../svg/fa/angle-down.svg';
import Image from '@jetshop/ui/Image/Image';
import { theme } from '../Theme';
import {
  StyledDropdownMenuButton,
  StyledDropdownMenuItems,
  StyledDropdownMenuItem,
  StyledDropdownMenu
} from '../ProductList/SelectVariant';
import { dropdownStyles } from './SizePicker';
import { Price } from '../Price';

function checkAllArticles(products, mainProductArtNo) {
  for (let i = 0; i < products.length; i++) {
    var colorArtNo = products[i].articleNumber;

    if (colorArtNo.split('-')[0] === mainProductArtNo.split('-')[0]) {
      if (colorArtNo !== mainProductArtNo) {
        return true;
      }
    }
  }
}

const colorStyles = css`
  li a {
    width: 100%;
    display: grid;
    grid-template-columns: 30px 1fr 100px;
    grid-template-rows: 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    text-decoration: none;
    .product-name-model {
      display: flex;
      padding: 0 0.5rem;
      justify-content: flex-start;
      align-items: center;
      line-height: 1.5;
      font-size: 14px;
      color: black;
      box-sizing: inherit;

      margin-left: 13px;
    }
    .product-price {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }
`;

function getModel(product) {
  if (product.articleNumber.indexOf('-B') > -1) {
    return <>({t('Junior')})</>;
  }
  if (product.articleNumber.indexOf('-D') > -1) {
    return <>({t('Ladies')})</>;
  }
  if (product.articleNumber.indexOf('-H') > -1) {
    return <>({t('Gents')})</>;
  } else {
    return null;
  }
}

function getStockstatus(product, stockStatus) {
  if (!product.variants.options.length > 0) {
    return (
      <span
        className={
          'chosenStockStatus ' +
          (stockStatus.buyable ? 'buyable ' : 'not-buyable')
        }
      >
        {stockStatus.text}
      </span>
    );
  }
}

function priceDiffer(array, value) {
  return array.some(function (el) {
    return el.value !== value;
  });
}

function cleanUpResults(term, products) {
  const regex = /(-B|-D)$/;
  return products.filter(function (product) {
    return product.articleNumber.split('-')[0] === term;
  });
}

class ProductColors extends Component {
  render() {
    const { productArtNo, mainProductId, productColor, productVariants } =
      this.props;
    var term = productArtNo.split('-')[0];

    return (
      <div className={cx(dropdownStyles, colorStyles)}>
        {term && (
          <Query query={ProductColorQuery} variables={{ term }} skip={!term}>
            {({ loading, error, data }) => {
              if (loading) return null;
              if (error) return `Error!: ${error}`;
              const cleanProducts = cleanUpResults(
                term,
                data.search.products.result
              );

              return (
                <Intl>
                  {t => (
                    <React.Fragment>
                      {cleanProducts.length > 0 &&
                        checkAllArticles(cleanProducts, productArtNo) && (
                          <StyledDropdownMenu>
                            <StyledDropdownMenuButton>
                              <span>{`${t('Color')}: ${productColor}`}</span>
                              <span>
                                <AngleDown />
                              </span>
                            </StyledDropdownMenuButton>
                            <StyledDropdownMenuItems style={{ zIndex: 9999 }}>
                              {cleanProducts.map(product => (
                                <StyledDropdownMenuItem key={product.id}>
                                  <ProductLink product={product}>
                                    <span class="product-color">
                                      <Image src={product.images[0].url} />
                                    </span>
                                    <span class="product-name-model">
                                      {product.subName} {getModel(product)}
                                    </span>

                                    <span class="product-price">
                                      <div class="css-0 eocxb3p0">
                                        <div class="price css-0 eocxb3p1">
                                          <Price {...product} />
                                        </div>
                                      </div>
                                    </span>
                                  </ProductLink>
                                  {/* {selectedValue === value && <Check />} */}
                                </StyledDropdownMenuItem>
                              ))}
                            </StyledDropdownMenuItems>
                          </StyledDropdownMenu>
                        )}
                    </React.Fragment>
                  )}
                </Intl>
              );
            }}
          </Query>
        )}
      </div>
    );
  }
}

export default ProductColors;
