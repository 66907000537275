import { styled } from 'linaria/react';

export const smallCaps = `
  font-style: normal;
  font-weight: 600;
  line-height: 1.5rem;
  font-size: 1rem;
  margin-bottom: 0.75rem;
`;
export const SmallCaps = styled('h1')`
  ${smallCaps};
`;

export const pageTitle = `
  font-weight: 600;
  line-height: normal;
  font-size: 24px;
  margin-bottom: 30px;
  text-align: center;
  width: 100%;
  display: block;
`;
export const PageTitle = styled('h1')`
  ${pageTitle};
`;
