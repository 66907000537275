import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import { Price } from '@jetshop/ui/Price';
import { theme } from '../Theme';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import get from 'lodash.get';
import React, { useContext } from 'react';
import { styled } from 'linaria/react';
import UIButton from '../ui/Button';
import CartItem from './CartItem';
import cartQuery from './CartQuery.gql';
import ChannelContext from '@jetshop/core/components/ChannelContext';

import { VoyadoTracking } from '../MyPages/VoyadoTracking';
import CartRecommendations from './Recommendations/CartRecommendations';
import { DynamicCategoryRenderer } from '../StartPage/ContentRendererComponents/DynamicCategoryRenderer/DynamicCategoryRenderer';
import { cartComponents } from '../StartPage/ContentRendererComponents/allcomponents';
import { useVoyadoTracker } from '../../useVoyadoTracker';

export const Flyout = styled('div')`
  color: ${theme.colors.primary};
  position: absolute;
  z-index: 999;
  right: 0;
  top: 0;
  width: 30rem;
  min-width: 19rem;
  max-width: 100%;
  height: calc(100% - 6.3rem);
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0px;
  }

  ${theme.below.lg} {
    top: 0;
    height: calc(100%);
  }
`;

export const CartButtons = styled('div')`
  display: flex;
  button {
    background: none;
    transition: all, 0.2s ease;
    &:hover {
      background: #f4f4f4;
    }
  }
`;
export const CartTopButton = styled('div')`
  width: 50%;
  text-align: center;
  padding: 20px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  &:first-child {
    border-left: 0;
  }
  &.active {
    border-bottom: 3px solid #181818;
  }
`;

const CartHeader = styled('h2')`
  text-align: center;
  font-size: 1rem;
  margin-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #dddddd;
`;

const CartItems = styled('section')`
  padding: 0;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none;
  &::-webkit-scrollbar {
    width: 0px;
  }
`;

export const Summary = styled('section')`
  background: #fff;
  padding: 20px;

  a {
    width: 100%;
    color: white;
    :hover {
      color: white;
    }
  }
`;

export const TotalSummary = styled('div')`
  font-size: 1.25rem;
  color: ${theme.colors.primary};
  display: flex;
  justify-content: center;
  margin-bottom: 3px;
  label {
  }
  > div {
    font-weight: 500;
  }
`;

export const Checkout = styled('a')`
  background-color: ${theme.colors.accent};
  display: flex;
  margin-bottom: 0.5rem;
  justify-content: center;
  padding: 0.6rem 0.5rem;
  text-align: center;
  text-decoration: none !important;
  text-transform: uppercase;
  width: 100%;
  font-family: ${theme.fonts.body};
  font-weight: bold;
  border: 2px solid ${theme.colors.accent};
  &:hover {
    color: ${theme.colors.accent}!important;
    background: white;
  }
`;

export const UspContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
`;

export const UspItem = styled('div')`
  display: flex;
  align-items: center;
  color: #a2a2a2;
  padding: 0 20px;
`;

export const UspText = styled('span')`
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 300;
`;

export const CartFlyoutOuter = styled('div')`
  > div {
    overflow: hidden;
    ${theme.above.xl} {
      margin-top: 100px;
    }
    ${theme.below.xl} {
      margin-top: 116px;
      height: calc(100% - 116px);
    }
    ${theme.below.lg} {
      margin-top: 92px;
      height: calc(100% - 92px);
      overflow: hidden;
    }
    ${theme.below.md} {
      margin-top: 136px;
      height: calc(100% - 136px);
    }
    ${theme.below.sm} {
      margin-top: 60px;
      height: calc(100% - 60px);
    }
  }
`;

const Button = styled(UIButton)`
  font-size: 12px;
  margin-top: 0.5rem;
  outline: none;
  text-align: center;
  transition: all ease 0.3s;
  width: 100%;
  font-weight: bold;
  margin-bottom: 1rem;
  background: #ddd;
  height: 42px;
  line-height: 42px;
  font-size: 14px;
  &:hover {
  }
`;

const VatDetails = styled('div')`
  color: ${theme.colors.grey};
  display: flex;
  font-size: 14px;
  justify-content: center;
  margin-bottom: 2rem;
  text-align: center;
`;

const Discounts = styled('div')`
  color: ${theme.colors.red};
  font-size: 0.75rem;
  margin-bottom: 2rem;
  .price::before {
    content: '-';
  }
  > div {
    padding: 0.5rem 0;
    display: flex;
    justify-content: space-between;
  }
`;

const CartFlyoutView = ({ result, modal, ...rest }) => {
  const items = get(result, 'data.cart.items', []);
  const checkoutUrl = get(result, 'data.cart.externalCheckoutUrl');
  const track = useTracker();
  useVoyadoTracker();
  const { selectedChannel } = useContext(ChannelContext);

  const VAT = new Intl.NumberFormat(selectedChannel.defaultLanguage.culture, {
    style: 'currency',
    currency: selectedChannel.currency.name,
  }).format(result.data.cart.productTotal.vat);
  let discounts = result?.data?.cart?.aggregatedDiscounts;
  if (discounts && discounts.length > 0) {
    discounts = discounts.filter((camp) => {
      if (!camp.name.startsWith('*')) {
        return camp;
      }
    });
  }

  return (
    <Flyout {...rest}>
      <VoyadoTracking cart={result.data.cart} />
      <CartHeader>{t('Your shopping cart')}</CartHeader>
      <CartItems>
        {items.map((item) => (
          <CartItem hideTarget={modal.hideTarget} item={item} key={item.id} />
        ))}
      </CartItems>

      <Summary>
        {discounts && discounts.length > 0 && (
          <Discounts>
            {discounts.map((discount) => (
              <div>
                <strong>{discount.name}</strong>
                <Price price={discount.value} />
              </div>
            ))}
          </Discounts>
        )}
        <TotalSummary>
          <label>{t('Total')}:&nbsp;</label>
          <Price price={result.data.cart.productTotal} />
        </TotalSummary>
        <VatDetails>
          <label>{t('of which VAT')}:&nbsp;</label>
          {VAT}
        </VatDetails>

        {checkoutUrl && (
          <Checkout
            href={checkoutUrl}
            onClick={() => {
              track(trackCartCheckoutEvent({ cart: result.data.cart }));
            }}
          >
            {t('Check out')}
          </Checkout>
        )}
        <Button onClick={modal.hideTarget}>{t('Continue shopping')}</Button>
        <DynamicCategoryRenderer  categoryId={3285} rendererComponents={cartComponents} />
      </Summary>
      <CartRecommendations whenClicked={modal.hideTarget} />
    </Flyout>
  );
};

const CartFlyout = (props) => (
  // Wrap the flyout with the needed providers
  <CartFlyoutOuter>
    <CartProvider query={cartQuery}>
      {(result) =>
        result.data && result.data.cart ? (
          <DrawerTarget id="cart-drawer">
            {(drawer) => (
              <Drawer isOpen={drawer.isOpen} right>
                <CartFlyoutView modal={drawer} result={result} {...props} />
              </Drawer>
            )}
          </DrawerTarget>
        ) : null
      }
    </CartProvider>
  </CartFlyoutOuter>
);

export default CartFlyout;
