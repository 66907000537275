import React, { useEffect, useRef, useState } from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theme';

const LinkRowWrapper = styled('div')`
  text-align: center;
  width: 100%;
  margin: 20px 0;
`;

const LinkRowItemsWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  flex-wrap: wrap;
  grid-column-gap: 65px;
  grid-row-gap: 20px;

  a {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 110px;
  }

  ${theme.below.lg} {
    flex-wrap: nowrap;
    overflow-x: scroll;
    white-space: nowrap;
    justify-content: flex-start;
    padding: 0 10px;
    grid-gap: 15px;
    a {
      flex-wrap: wrap;
      white-space: normal;
      overflow: hidden;
    }

    &::-webkit-scrollbar {
      display: none;
    }
    &.links-less-than-3 {
      justify-content: center;
    }
  }
  ${theme.below.sm} {
    grid-gap: 0px;
  }
`;

export const CategoryLinkRow = ({ children }) => {
  const linkRowRef = useRef(null);
  const [hasScrollLeft, setHasScrollLeft] = useState(false);
  const [hasScrollRight, setHasScrollRight] = useState(true);

  useEffect(() => {
    const linkRowElement = linkRowRef.current;

    function updateScrollClasses() {
      if (linkRowElement.scrollLeft > 0) {
        setHasScrollLeft(true);
      } else {
        setHasScrollLeft(false);
      }

      if (
        linkRowElement.scrollLeft <
        linkRowElement.scrollWidth - linkRowElement.clientWidth
      ) {
        setHasScrollRight(true);
      } else {
        setHasScrollRight(false);
      }
    }

    linkRowElement.addEventListener('scroll', updateScrollClasses);
    window.addEventListener('resize', updateScrollClasses);

    return () => {
      linkRowElement.removeEventListener('scroll', updateScrollClasses);
      window.removeEventListener('resize', updateScrollClasses);
    };
  }, []);

  return (
    <LinkRowWrapper>

      <div className={`linkRowGradientWrapper`}>
        <div
          className={`linkRowGradient ${
            hasScrollLeft && children.length > 4 ? 'has-scroll-left' : ''
          }`}
        ></div>
        <div
          className={`linkRowGradient ${
            hasScrollRight && children.length > 4 ? 'has-scroll-right' : ''
          }`}
        ></div>

        <LinkRowItemsWrapper
          className={children.length < 4 ? 'links-less-than-3 ' : ''}
          ref={linkRowRef}
        >
          {children}
        </LinkRowItemsWrapper>
      </div>
    </LinkRowWrapper>
  );
};
