import { Link } from 'react-router-dom';
import { Query } from 'react-apollo';
import CategoryLink from '@jetshop/ui/CategoryLink';
import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../../../Theme';
import Image from '@jetshop/ui/Image/Image';
import { Shortcodes } from '@jetshop/flight-shortcodes';
import SubMenuSection from './SubMenuSection';
import MenuImageQuery from './MenuImageQuery.gql';

// Set the submenu a minimum of 50% of the browser view, but fit the content
// so it doesn't always extend to 100%.
const ContentFit = styled('div')`
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 90rem;
  padding: 0 2rem;
  padding-bottom: 50px;
  &.inactive {
    pointer-events: none;
    display: none;
  }

  > div {
    width: 100%;
    margin: 0;
    padding: 0;
    h2 {
      font-size: 18px;
      font-weight: bold;
      line-height: 1;
      a {
        font-size: 16px;
        font-weight: bold;
      }
    }
    a {
      font-size: 14px;
      padding: 6px 0;
      color: ${theme.colors.primary};
      line-height: 1.2;
      display: block;
      font-weight: normal;
      &.active {
        text-decoration: underline;
      }
    }
  }
`;

// We need to put the border here (inside the Wrapper), because if we put it directly
// on the Wrapper it will still be shown when the Wrapper's height is 0
const InnerWrapper = styled('div')`
  width: 100%;
  margin: 2rem;
  width: calc(100% - 4rem);
  &.inactive {
    pointer-events: none;
    display: none;
  }
`;

const PosedWrapper = styled('section')`
  height: auto;
  position: absolute;
  right: 0;
  left: 0;
  background: ${theme.colors.white};
  z-index: 10;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    width: 99vw;
    left: 50%;
    right: auto;
    transform: translateX(-50%);
    height: 1px;
    background: #ddd;
  }
`;

const NoThirdTierItems = styled('div')``;

const MenuItems = styled('div')`
  > div {
    display: inline-block;
    width: 16.666667%;
    vertical-align: top;
    margin: 0;
    padding: 0 40px 0 0;
  }
`;
const MenuImage = styled('div')`
  flex: 1;
`;

const MenuImageContent = styled('div')`
  text-align: center;
  h2 {
    margin-top: 10px;
    font-size: 18px !important;
  }
`;

const Menybild = ({ image, link, text }) => (
  <MenuImageContent>
    <Link to={link}>
      <Image src={image} cover aspect={'680:450'} alt={text} />
      <h2>{text}</h2>
    </Link>
  </MenuImageContent>
);

const MenuImageItem = ({ closeNav, id }) => {
  return (
    <Query query={MenuImageQuery} variables={{ id: id }}>
      {({ loading, error, data }) => {
        if (loading) return null;
        if (error) return `Error!: ${error}`;
        return data.category ? (
          <div onClick={closeNav}>
            <Shortcodes
              content={data.category.content}
              components={{
                Menybild: Menybild
              }}
            />
          </div>
        ) : null;
      }}
    </Query>
  );
};

const SubMenuWrapper = ({ pose, activeCategory, closeNav, client, data }) => {
  if (activeCategory?.subcategories?.length === 0) {
    return null;
  }

  const subcategories = activeCategory?.subcategories?.filter((cat) => !cat.name.startsWith('-'));

  return (
    <PosedWrapper pose={pose}>
      <InnerWrapper className={!activeCategory ? 'inactive' : null}>
        <ContentFit className={!activeCategory ? 'inactive' : null}>
          <>
            <MenuItems>
              {subcategories &&
                subcategories.map((cat, index) =>
                  cat.subcategories.length > 0 ? (
                    <SubMenuSection
                      key={index}
                      heading={cat}
                      categories={cat.subcategories}
                      onSelect={closeNav}
                      closeNav={closeNav}
                    />
                  ) : (
                    <React.Fragment key={index}>
                      {cat.name !== 'Menybild' ? (
                        <NoThirdTierItems key={index}>
                          <h2>
                            <CategoryLink category={cat} onClick={closeNav}>
                              {cat.name}
                            </CategoryLink>
                          </h2>
                        </NoThirdTierItems>
                      ) : null}
                    </React.Fragment>
                  )
                )}
            </MenuItems>

            {activeCategory &&
              activeCategory.subcategories.map((cat, index) =>
                cat.name === 'Menybild' ? (
                  <MenuImage key={index}>
                    <MenuImageItem closeNav={closeNav} id={cat.id} />
                  </MenuImage>
                ) : null
              )}
          </>
        </ContentFit>
      </InnerWrapper>
    </PosedWrapper>
  );
};

export default SubMenuWrapper;
