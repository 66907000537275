import React from 'react';
import { styled } from "linaria/react";
import Video from './Video';
import MaxWidth from '../../Layout/MaxWidth';
import { Link } from 'react-router-dom';
import { Above } from '@jetshop/ui/Breakpoints';

import { theme } from "../../Theme";

const VideoHeroWrapper = styled('div')`
  position: relative;
  margin-bottom: 1rem;

  .vimeo {
    padding-bottom: 42.65%;
  }

  &.mobile {
    @media (min-width: 50rem) {
      display: none;
      content-visiblity: none;
    }
  }

  &.desktop {
    @media (max-width: 50rem) {
      border: 2px solid green;
      display: none;
      content-visiblity: none;
    }
  }
`;

const Container = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 2rem !important;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.6);

  ${theme.below.md} {
    padding: 1rem !important;
  }

  p {
    font-size: 28px;
    letter-spacing: 0.03em;
  }

  &.left {
    justify-content: flex-start;
    text-align: left;
  }
  &.right {
    justify-content: flex-end;
    text-align: right;
  }
  &.center {
    justify-content: center;
    text-align: center;
  }

  &.top {
    align-items: flex-start;
  }
  &.bottom {
    align-items: flex-end;
  }
  &.middle {
    align-items: center;
  }
`;
const Heading = styled('h1')`
  font-size: 2.5rem;
  text-transform: uppercase;
  margin: 0;
  font-weight: 700;
  letter-spacing: 0.03em;

  color: ${(props) => (props.color ? props.color : 'black')};

  ${theme.below.md} {
    font-size: 1.5rem;
  }
`;

const StyledButton = styled(Link)`
  display: inline-block;
  color: ${(props) => (props.color ? props.color : 'black')};
  text-decoration: none;
  height: 53px;
  padding: 0 1rem;
  line-height: 51px;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  transition: all, 0.2s ease;
  letter-spacing: 0.15em;
  border: 1px solid #000;
  border-color: ${(props) => (props.color ? props.color : 'black')};

  &:hover {
    background: #235837 !important;
    border-color: #235837;
  }

  // ${theme.below.md} {
  //   font-size: 14px;
  //   line-height: 35px;
  //   height: 38px;
  // }
`;

const ButtonsWrapper = styled('div')`
  margin-top: 23px;
  margin-right: -20px;
  ${theme.below.md} {
    margin-right: -10px;
  }
`;

const Positioner = styled('div')`
  display: flex;
  flex-direction: column;
  width: 90%;

  &.left {
    align-items: flex-start !important;
  }
  &.right {
    justify-content: flex-end;
    text-align: right;
  }
  &.center {
    align-items: center !important;
  }

  &.top {
    align-items: flex-start;
  }
  &.bottom {
    align-items: flex-end;
  }
  &.middle {
    align-items: center;
  }

  ${theme.below.md} {
    // padding: 1rem;
    top: 0 !important;
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    transform: none !important;
  }

  a {
    margin-right: 20px;
    ${theme.below.md} {
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }
`;

const VideoHero = ({
  vimeoSrc,
  heading,
  text,
  buttons,
  vertical,
  horizontal,
  foregroundColor,
  device = null,
}) => {
  return (
    <>
      <VideoHeroWrapper className={'videoHeroWrapper ' + device ?? ''}>
        <Video vimeoSrc={vimeoSrc}></Video>
        <Container className={vertical + ' ' + horizontal}>
          <Positioner className={vertical + ' ' + horizontal}>
            <Heading color={foregroundColor ? foregroundColor : false}>
              {heading}
            </Heading>
            <Above breakpoint="md">
              {text && text.startsWith('<') ? (
                <div
                  style={{ color: foregroundColor ? foregroundColor : false }}
                  dangerouslySetInnerHTML={{ __html: text }}
                />
              ) : (
                <p style={{ color: foregroundColor ? foregroundColor : false }}>
                  {text}
                </p>
              )}
            </Above>

            <ButtonsWrapper>
              {buttons &&
                buttons.map((button) => (
                  <StyledButton
                    key={button.title}
                    color={foregroundColor ? foregroundColor : false}
                    to={button.url}
                  >
                    {button.title}
                  </StyledButton>
                ))}
            </ButtonsWrapper>
          </Positioner>
        </Container>
      </VideoHeroWrapper>
    </>
  );
};

export default VideoHero;
