import { css } from 'linaria';

const globals = `
  html, body, #root {
    min-height: 100%;
    font-family:"Helvetica Neue",Helvetica,Arial,sans-serif;
  }
  .mobileOnly{
      display:none;
  }
  html {
  scroll-behavior: smooth;
}
  @media screen and (max-width: 630px) {
    .desktopOnly{
      display:none;
    }
    .mobileOnly{
      display:block;
    }
  }
  #root {
    display: flex;
    flex-direction: column;
  }
  button {
	  cursor: pointer;
    padding: 0px;
    border: none;
    font: inherit;
	}
  ol, ul, h4, h3, h2, h1 {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  p, dd {
    margin: 0;
    padding: 0;
  }
  input {
    -webkit-appearance: none;
  }
  .ReactModal__Body--open {
    overflow: hidden;
  }
  .ReactModal__Overlay--after-open {
    overflow: scroll;
  }
  .EU-modal{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    padding: 2rem;
    box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset, rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
    border-radius: 5px;
    background:#fff;
  }
  body {

    -webkit-font-smoothing: antialiased;
  }
  /* 
   * apply a natural box layout model to all elements, but allowing components
   * to change */
  html {
    box-sizing: border-box;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }

  .MuiTooltip-popper {
    margin-top: -10px;
  }

  .ReactModalPortal {
    .ReactModal__Overlay {
      z-index: 5;
    }
  }
  .Toastify > div {
    top:0;
  }
  .svg-inline--fa {
    vertical-align:middle;
  }

`;

css`
  :global() {
    ${globals};
  }
`;
