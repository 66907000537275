import React from 'react';
import { styled } from "linaria/react";
import CategoryLink from '@jetshop/ui/CategoryLink';
import { theme } from "../../../Theme";

const Wrapper = styled('div')`
  display: block;
  width: 100%;
  padding-bottom: 50px;

  ul {
    display: block;
    width: 100%;
  }

  li {
    flex: auto;
    padding: 0;
    margin: 0;
    display: block;
    width: 100%;
    a {
      width: 100%;
      opacity: 1;
      display: block;
      font-weight: normal;
      transition: all ease 0.3s;

      &.hover,
      &.active {
        color: ${theme.colors.accent};
        font-weight: bold;
      }
    }
  }

  a {
    text-decoration: none;
    display: block;
  }
`;

const Heading = styled('h2')`
  margin-bottom: 3px;
  padding-bottom: 2px;

  a {
    &.active {
      color: ${theme.colors.accent};
      background: ${theme.colors.tablegrey};
      padding: 0.5rem;
      margin-left: -0.5rem;
      text-decoration: none !important;
    }
  }
`;

const SubMenuSection = ({
  heading,
  categories,
  onMouseOver,
  closeNav,
  onSelect = () => {}
}) => {
  const cleanCategories = categories.filter(cat => !cat.name.startsWith('-'));
  return (
    <Wrapper onMouseOver={onMouseOver}>
      <Heading>
        <CategoryLink onClick={onSelect} category={heading}>
          {heading.name}
        </CategoryLink>
      </Heading>
      <ul>
        {/* <li>
          <CategoryLink onClick={onSelect} category={heading}>
            Se alla {heading.name}
          </CategoryLink>
        </li> */}
        {cleanCategories.map(cat => (
          <li key={cat.id}>
            <CategoryLink onClick={onSelect} category={cat}>
              {cat.name}
            </CategoryLink>
          </li>
        ))}
      </ul>
    </Wrapper>
  );
};

export default SubMenuSection;
