import { useMemo } from 'react';

export function usePriceDiffer({ variants }) {
  const differs = useMemo(() => {
    if (!variants || variants?.length <= 0) {
      return false;
    }

    const sorted = [...variants].sort(
      (a, b) => a?.price?.incVat - b?.price?.incVat
    );

    const [first] = sorted;
    const last = sorted[sorted.length - 1];

    return first?.price?.incVat < last?.price?.incVat;
  }, [variants]);

  return differs;
}
