import React, { useEffect, useState } from 'react';
import { styled } from "linaria/react";
import Modal from 'react-modal';
import { useQuery } from 'react-apollo';
import { PopupContentQuery } from './DynamicContentQuery.gql';
import useAuth from '@jetshop/core/components/AuthContext/useAuth';
import { Link } from 'react-router-dom';
import { theme } from './Theme';

const customStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
    zIndex: 9999,
  },
  content: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    border: 'none',
    background: '#000',
    overflow: 'auto',
    WebkitOverflowScrolling: 'touch',
    borderRadius: '2px',
    outline: 'none',
    padding: '0',
    transform: 'translate(-50%, -50%)',
    width: '90vw',
    maxWidth: '320px',
    height: '90vh',
    maxHeight: '540px',
  },
};

const PopupContent = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  color: white;
  padding: 15px;
  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  div,
  a {
    position: relative;
    z-index: 2;
  }
  a {
    margin-top: 1rem;
    background: transparent;
    color: white;
    border: 1px solid white;
    font-size: 20px;
    font-weight: bold;
    border-radius: 0;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 53px;
    line-height: 51px;
    padding: 0 20px;
    display: inline-block;
    font-family: ${theme.fonts.primary};
    text-transform: uppercase;
    transition: all, 0.2s ease;

    &:hover {
      background: ${theme.colors.accent}!important;
      color: white;
      border-color: ${theme.colors.accent};
    }
  }
  button {
    color: #fff;
    position: absolute;
    top: 1rem;
    right: 1rem;
    font-size: 2rem;
    height: 2rem;
    width: 2rem;
    line-height: 1.5rem;
    background: none;
    text-transform: uppercase;
    z-index: 99;
  }
`;

const Popup = () => {
  const popUpId = 2347;
  const { data, loading, error } = useQuery(PopupContentQuery, {
    variables: { id: popUpId },
  });
  const { loggedIn } = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  const timePassed = (time) => {
    if (!time) return true;
    const seconds = (Date.now() - parseInt(time)) / 1000;
    const days = Math.floor(seconds / (3600 * 24));
    if (days > 7) return true;
    return false;
  };
  useEffect(() => {
    if (loading || error) return;
    if (!data?.category) return;
    if (typeof window === 'undefined') return;
    if (!timePassed(window.localStorage.getItem('memberPopup'))) return;
    if (loggedIn) return;
    setIsOpen(true);
  }, [data, loading, error, loggedIn]);

  function closeModal() {
    window.localStorage.setItem('memberPopup', Date.now());
    setIsOpen(false);
  }
  if (error) return null;

  if (data?.category?.name.indexOf('DOLD') > -1) {
    return null;
  }
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Hööks and Friends"
    >
      <PopupContent>
        {!loading && data && data?.category && (
          <>
            <img src={data.category.images[3]?.url} />
            <div
              dangerouslySetInnerHTML={{ __html: data?.category?.content }}
            />
          </>
        )}
        <Link onClick={closeModal} to="/login">
          {data?.category?.mainHeader}
        </Link>
        <button title="Stäng" onClick={closeModal}>
          &times;
        </button>
      </PopupContent>
    </Modal>
  );
};

export default Popup;
