import React from 'react';
import striptags from 'striptags';
import { theme } from "../../Theme";

import { useQuery } from '@apollo/react-hooks';
import uspQuery from './UspQuery.gql';

import { styled } from "linaria/react";

import { ReactComponent as CheckMark } from '../../../svg/fa/check.svg';

const UspBarContainer = styled('ul')`
  display: flex;
  width: auto;
  li {
    color: ${theme.colors.black};
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    span {
      font-size: 11px;
      display: block;
    }
    svg {
      width: 10px;
      font-size: 10px;
      margin-right: 5px;
    }
  }
  ${theme.below.lg} {
    justify-content: center;
    width: 100%;
  }
`;

const UspContainer = () => {
  const { data } = useQuery(uspQuery, {
    variables: {
      id: 1960,
    },
  });
  if (!data || !data.category.content) return <div />;

  let content = data.category.content;

  const clean = striptags(content, [], '\r').split('\n').filter(Boolean);
  return (
    <UspBarContainer>
      {clean.map((usp, index) => (
        <React.Fragment key={index}>
          {usp && usp.length > 1 ? (
            <li>
              <CheckMark />
              <span>{usp}</span>
            </li>
          ) : null}
        </React.Fragment>
      ))}
    </UspBarContainer>
  );
};

export default UspContainer;
