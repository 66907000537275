import React from 'react';
import { styled } from 'linaria/react';
import { Above } from '@jetshop/ui/Breakpoints';

const SpacerWrapper = styled('div')`
  width: 100%;
  height: ${props => props.spacerheight}rem;
`;

export const Spacer = ({ vertical }) => {
  let selectedSpace = 2;
  switch (vertical?.value) {
    case 'SMALL':
      selectedSpace = 1;
      break;
    case 'MEDIUM':
      selectedSpace = 3;
      break;
    case 'LARGE':
      selectedSpace = 6;
      break;
    case 'X-LARGE':
      selectedSpace = 12;
      break;
    default:
      selectedSpace = 3;
      break;
  }
  return (
    <Above breakpoint="md">
      {matches =>
        matches ? (
          <SpacerWrapper spacerheight={selectedSpace} />
        ) : (
          <SpacerWrapper spacerheight={selectedSpace * 0.7} />
        )
      }
    </Above>
  );
};
