import React, { useContext, useEffect, useRef, useState } from 'react';
import { styled } from 'linaria/react';
import { useBooleanFilter } from '@jetshop/core/hooks/Filters/useBooleanFilter';
import t from '@jetshop/intl';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import ToggleQuery from './ToggleQuery.gql';
import { useQuery } from '@apollo/react-hooks';
const SliderWrap = styled('div')`
  display: flex;
  justify-content: center;
  // align-items: center;
  padding-top: 0.75em;
  white-space: nowrap;
  span {
    margin: 0 0.5rem;
    font-size: 0.8rem;
    &:first-child {
      text-align: right;
    }
  }
`;

const Slider = styled('div')`
  width: 50px;
  height: 30px;
  transform: translatey(-20%);
  border-radius: 15px;
  border: solid 1px #f7f7f7;
  transition: 0.2s ease-in-out;
  background: ${props => (props.active ? 'white' : 'white')};
  box-shadow: inset 0 0 3px rgb(0 0 0 / 20%);
  &::after {
    transition: 0.2s ease-in-out;
    display: block;
    content: '';
    width: 24px;
    height: 24px;
    background: #225837;
    margin: 2px;
    border-radius: 12px;
    transform: ${props => (props.active ? 'translate(0)' : 'translate(20px)')};
    box-shadow: 0 0 3px rgb(0 0 0 / 20%);
  }
`;

const Toggler = ({ filter, lessThan40, className }) => {
  const { data } = useQuery(ToggleQuery);
  const { selectedChannel } = useContext(ChannelContext);
  const { apply } = useBooleanFilter({ filter });
  const initialSetupDoneRef = useRef(false);
  
  useEffect(() => {
    if (lessThan40){
    if (!initialSetupDoneRef.current && filter?.default === filter?.value) {
      apply({ value: !filter.value });
      initialSetupDoneRef.current = true;  // Mark that initial setup is done
    }
  }
  }, [filter, apply]);

  const { value } = filter;
  if (['EU', 'NL'].includes(selectedChannel.name)) return null;
  const toggle = () => {
    apply({ value: !value });
  };
  const [first, second] = data?.category?.mainHeader?.split('|') ?? [
    null,
    null
  ];
  return (
    <SliderWrap className={className}>
      <span>{second ?? t('Webshop and/or store')}</span>
      <Slider active={!value} onClick={toggle} />
      <span>{first ?? t('Webshop only')}</span>
    </SliderWrap>
  );
};

const OnlineStockToggle = ({ filters, lessThan40, className }) => {
  const filter = filters?.find(f => f.id.includes('buyable'));
  if (!filter) return null;
  return <Toggler filter={filter} lessThan40={lessThan40} className={className} />;
};

export default OnlineStockToggle;
