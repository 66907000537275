import React from 'react';

import { useQuery } from 'react-apollo';
import BrandQuery from './BrandQuery.gql';

import Image from '@jetshop/ui/Image/Image';

const useBrandCat = brandName => {
  const { data } = useQuery(BrandQuery);
  if (!data) return null;
  if (data.category && data.category.subcategories) {
    const subCat = data.category.subcategories.filter(
      subCat => subCat.name === brandName
    );
    return subCat.length > 0 ? subCat[0] : subCat;
  }
};

const BrandLogo = ({ brandName }) => {
  const brandCat = useBrandCat(brandName);
  if (!brandCat) return null;
  return (
    <Image
      className="brand-logo"
      src={brandCat.images}
      quality={100}
      crop={false}
      alt={brandCat.name}
    />
  );
};

export default BrandLogo;
