import SubscribeToStockNotifications from '@jetshop/core/components/Mutation/SubscribeToStockNotifications';
import getErrorDetail from '@jetshop/core/helpers/getErrorDetail';
import t, { Intl } from '@jetshop/intl';
import React, { Component } from 'react';
import { styled } from 'linaria/react';
import UIButton from '../../ui/Button';

import { theme } from '../../Theme';

const Button = styled(UIButton)`
  background: ${theme.colors.primary};
  width: 100%;
`;

const Label = styled('label')`
  font-size: 12px;
  text-align: center;
  letter-spacing: 0.05em;
  margin-bottom: 10px;
`;

const Error = styled('p')`
  margin-top: 1rem;
  color: #eb5757;
  font-size: 0.875rem;
`;
const PosedWrapper = styled('div')`
  display: flex;
  border: 1px solid #bfbdbd;
  padding: 0 0.5rem;
  position: absolute;
  top: 0;
  width: 100%;

  opacity: ${({ loading }) => (loading ? 0.5 : 1)};

  input {
    flex: 1 1 auto;
  }

  input,
  button {
    height: 3rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1rem;
    border: 0;
    padding: 0 0.25rem;
    :focus {
      outline: none;
    }
  }

  button {
    justify-content: center;
  }
`;

const Wrapper = styled('div')`
  position: relative;
  width: 100%;
`;

const Completed = styled('div')`
  position: absolute;
  top: 0;
  font-weight: 600;
  height: 3rem;
  display: flex;
  align-items: center;
`;

export default class NotifyWhenBack extends Component {
  state = {
    submitted: false,
    showForm: false,
    error: false,
    email: ''
  };

  handleInputChange = e =>
    this.setState({ email: e.currentTarget.value, error: false });
  handleSubmit = mutate => e => {
    e.preventDefault();
    mutate(this.state.email, this.props.articleNumber);
  };
  handleCompleted = () => this.setState({ submitted: true });
  handleError = () => this.setState({ error: true });
  showForm = () => this.setState({ showForm: true });

  render() {
    const { text } = this.props;

    return (
      <>
        <Label>{text}</Label>
        <Wrapper>
          <div pose={this.state.showForm ? 'closed' : 'open'}>
            <Button
              onClick={this.showForm}
              data-testid="notify-when-back-button"
            >
              {t('Notify me when back in stock')}
            </Button>
          </div>

          <SubscribeToStockNotifications
            onCompleted={this.handleCompleted}
            onError={this.handleError}
          >
            {(mutate, result, errorCode) => {
              return (
                <form onSubmit={this.handleSubmit(mutate)}>
                  <PosedWrapper loading={result.loading}>
                    <Intl>
                      {t => (
                        <>
                          <input
                            data-testid="notify-when-back-input"
                            onChange={this.handleInputChange}
                            placeholder={t('E-mail address')}
                          />
                          <button
                            data-testid="notify-when-back-ok"
                            disabled={this.state.email.length === 0}
                          >
                            {t('OK')}
                          </button>
                        </>
                      )}
                    </Intl>
                  </PosedWrapper>
                  {this.state.error && (
                    <Error>
                      {getErrorDetail(result.error).codes.includes(
                        'InvalidEmail'
                      )
                        ? t('Please enter a valid email address.')
                        : t(
                            'Something went wrong. Please check your email and try again.'
                          )}
                    </Error>
                  )}
                </form>
              );
            }}
          </SubscribeToStockNotifications>

          <Completed pose={this.state.submitted ? 'open' : 'closed'}>
            <p data-testid="notify-when-back-success">
              {t('You are now subscribed.')}
            </p>
          </Completed>
        </Wrapper>
      </>
    );
  }
}
