import useDecrementQuantity from '@jetshop/core/components/Mutation/useDecrementQuantity';
import useIncrementQuantity from '@jetshop/core/components/Mutation/useIncrementQuantity';
import useRemoveFromCart from '@jetshop/core/components/Mutation/useRemoveFromCart';
import getCartItemVariant from '@jetshop/core/helpers/getCartItemVariant';
import Image from '@jetshop/ui/Image';
import { Price } from '@jetshop/ui/Price';
import { ReactComponent as Cross } from '@jetshop/ui/svg/Cross.svg';
import { theme } from "../Theme";
import ProductLink from '@jetshop/ui/ProductLink';
import React from 'react';
import { styled } from "linaria/react";
import { css } from "linaria";
import removeFromCartMutation from './removeFromCart.gql';
import incrementQuantityMutation from './incrementQuantity.gql';
import decrementQuantityMutation from './decrementQuantity.gql';
import cartQuery from './CartQuery.gql';
import t from '@jetshop/intl';


export const Wrapper = styled('div')`
  display: flex;
  background: white;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
  padding: 10px 10px;
  > :first-child {
    flex: 1 1 25%;
  }
  &:first-of-type {
    padding-top: 20px;
  }
  > :last-child {
    flex: 1 1 70%;
    padding: 0.5rem;
  }
`;

export const ProductName = styled('div')`
  color: ${theme.colors.primary};
  font-size: 12px;
  line-height: 1.15;
  text-transform: uppercase;
  font-weight: normal;
  h2 {
    color: ${theme.colors.primary};
    font-size: 12px;
    line-height: 1.15;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: normal;
  }
  a {
    color: ${theme.colors.primary};
    text-decoration: none;
    font-weight: normal;
  }
`;

export const ProductDetail = styled('section')`
  display: grid;
  grid-auto-columns: 1fr 1fr 1fr 1fr;
  grid-template-areas: 'topleft topleft topleft topright' 'bottomleft bottomleft bottomright bottomright';
  padding-top: 0 !important;
  padding-right: 0 !important;
`;

export const ItemDetails = styled('div')`
  grid-area: topleft;
`;

export const LinePrice = styled(Price)`
  margin-top: 1rem;
  grid-area: bottomright;
  font-weight: bold;
  font-size: 14px;
  text-align: right;
  ${Price.Normal} {
    font-size: 1rem;
  }
  .new-price {
    color: ${theme.colors.red};
    font-weight: bold;
    font-size: 1rem;
  }
  .old-price {
    color: #878787;
    font-size: 1rem;
    text-decoration: line-through;
  }
`;

const AdjustQty = styled('div')`
  margin-top: 1rem;
  display: flex;
  grid-area: bottomleft;
  /* Plus/minus buttons */
  button {
    background: transparent;
    color: black;
    height: 25px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    line-height: 20px;
    border: 1px solid black;
    :focus {
      outline: none;
    }
  }
  /* Count */
  span {
    display: inline-block;
    border: 1px solid black;
    border-left: none;
    border-right: 1px solid black;
    padding: 0 3px;
    height: 25px;
    line-height: 25px;
    width: 35px;
    text-align: center;
    font-size: 13px;
    & + button{
      border-left:none;
    }
  }
`;

const ErrorSpan = styled('span')`
  position: absolute;
  font-style: italic;
  margin-top: 4.3rem;
  left: 6.3rem;
  font-size: 12px;
  color: ${theme.colors.orange};
  `;

export const RemoveItem = styled('div')`
  grid-area: topright;
  text-align: right;
  button {
    padding: 0;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    outline: none;
    border: none;
    background: #fff;
    margin-left: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  svg {
    stroke: black;
    stroke-width: 1px;
    height: 10px;
    width: 10px;
    g,
    use {
      fill: #000000;
    }
  }
`;

const CartItem = ({ item, hideTarget, className = '' }) => {
  const {
    isVariant,
    variantImage,
    variantValues,
    hasVariantImage,
  } = getCartItemVariant(item);
  const { incrementQuantity } = useIncrementQuantity({
    incrementQuantityMutation,
    cartQuery,
  });

  const { decrementQuantity } = useDecrementQuantity({
    decrementQuantityMutation,
    cartQuery,
  });

  const { removeFromCart } = useRemoveFromCart({
    removeFromCartMutation,
    cartQuery,
  });
  
  const hasMax = item.customerComments.find(comment => comment.name === 'max') ?? null;
  const maxValue = hasMax?.value ?? null;
  return (
    <Wrapper className={className}>
      <div>
        <ProductLink product={item.product} onClick={hideTarget}>
          {item.product.images.length > 0 && (
            <Image
              aspect="1:1"
              sizes="5rem"
              modifiedDate={item.product.images[0].modifiedDate}
              src={
                hasVariantImage ? variantImage.url : item.product.images[0].url
              }
              alt={
                hasVariantImage ? variantImage.alt : item.product.images[0].alt
              }
            />
          )}
        </ProductLink>
      </div>
      <ProductDetail>
        <ItemDetails>
          <ProductName>
            <ProductLink product={item.product} onClick={hideTarget}>
              <h2>{item.product.name}</h2>
            </ProductLink>
            {isVariant && (
              <span
                style={{ marginTop: '0', display: 'block', textTransform: 'capitalize' }}
              >
                {item.variantOptionNames[0] + ': '}
                {variantValues.join(', ')}
              </span>
            )}
          </ProductName>
        </ItemDetails>

        <RemoveItem>
          <button
            onClick={() =>
              removeFromCart({ itemId: item.id, product: item.product })
            }
            data-testid="remove-from-cart"
          >
            <Cross />
          </button>
        </RemoveItem>

        <AdjustQty>
          <button
            disabled={item.quantity === 1}
            onClick={() =>
              item.quantity !== 1 && decrementQuantity({ itemId: item.id })
            }
          >
            -
          </button>
          <span>{item.quantity}</span>
          
            <button
              disabled={hasMax && parseInt(maxValue) === item.quantity}
              onClick={() =>
                item.quantity !== maxValue && incrementQuantity({ itemId: item.id })
              }
            >
              +
            </button>
          
        </AdjustQty>
        <LinePrice price={item.total} previousPrice={item.previousTotal} />
      { hasMax && parseInt(maxValue) === item.quantity && (
        <ErrorSpan>{t('Max quantity {maxValue}', {maxValue})}</ErrorSpan>
      )}
      </ProductDetail>
    </Wrapper>
  );
};

export default CartItem;
