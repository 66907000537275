import React, { createContext, useContext, useEffect, useState,useMemo } from 'react';
import { useQuery } from 'react-apollo';
import GlobalSettingsQuery from './GlobalSettingsQuery.gql';
// Create the context
const GlobalSettingsContext = createContext();

// Create a custom hook to use the context
const useGlobalSettings = () => {
  return useContext(GlobalSettingsContext);
};

const parseProductTemplates = (data) => {
const parsedTemplates = {};

// Parse properties of each "Product Template"
data?.forEach(template => {
  if (template.type === 'Product Template') {
    const parsedTemplate = {};
    let tagName = null;

    template.properties.forEach(property => {
      const propertyName = property.name;
      const propertyValue = property?.value?.value ?? null;

      if (propertyName === 'tagName' && !tagName) {
        tagName = propertyValue;
      } else {
        parsedTemplate[propertyName] = propertyValue;
      }
    });

    if (tagName && !parsedTemplates[tagName]) {
      parsedTemplates[tagName] = parsedTemplate;
    }
  }
});
return parsedTemplates;
}

const GlobalSettingsProvider = ({children}) => {
    const { data, loading, error } = useQuery(GlobalSettingsQuery);
    const [productTemplates, setProductTemplates] = useState({});
    useEffect(() => {
        if (!loading && !error) {
         
            
            setProductTemplates(parseProductTemplates(data?.category?.data?.items));
        }
    }, [data]);

    const saleTemplate = useMemo(() => {
      if (!data) return null;
      let tagName = null;
      const parsedTemplate = {};
      const saleTemplate = data?.saleCategory?.data?.items.find(item => {
        return item.type === 'SaleTemplate';
      });
      if (!saleTemplate) return {};
          saleTemplate.properties.forEach(property => {

            const propertyName = property.name;
            const propertyValue = property?.value?.value ?? null;
            

            if (propertyName === 'saleLink' && !tagName) {
              parsedTemplate[propertyName] = property.value
            } else {
              parsedTemplate[propertyName] = propertyValue;
            }
          });
      return parsedTemplate;
    }, [data]);


  const globalSettings = {
    'productTemplates': productTemplates,
    'saleTemplate': saleTemplate
  };


  return (
    <GlobalSettingsContext.Provider value={{ ...globalSettings }}>
      {children}
    </GlobalSettingsContext.Provider>
  );
};

export { GlobalSettingsContext, useGlobalSettings, GlobalSettingsProvider };
