import React from 'react';
import { styled } from 'linaria/react';
import { theme } from '../Theme';

import { ReactComponent as Carrot } from '../../svg/Carrot.svg';

import SlickSlider from 'react-slick';
import { Above } from '@jetshop/ui/Breakpoints';

const ProductCarouselWrapper = styled('div')`
  width: 100%;
  padding: 0;

  ${theme.above.lg} {
    position: relative;
    width: calc(100% + 13px);
    margin: 0 -8px;
  }

  .slick-list,
  .slick-slider,
  .slick-track {
    position: relative;
    display: block;
  }
  .slick-loading .slick-slide,
  .slick-loading .slick-track {
    visibility: hidden;
  }
  .slick-slider {
    list-style: none;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  .slick-list {
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: 0;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }
  .slick-slider .slick-list,
  .slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .slick-track {
    top: 0;
    left: 0;
  }
  .slick-track:after,
  .slick-track:before {
    display: table;
    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    padding: 0 0.5rem;
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
`;

const ProductCarouselContainer = styled('div')`
  overflow-x: scroll;
  position: relative;

  -webkit-overflow-scrolling: touch;
  overflow-scrolling: touch;
  &::-webkit-scrollbar,
  &::-webkit-scrollbar-track,
  ::-webkit-scrollbar-thumb {
    background-color: transparent;
    display: none;
  }
  ${theme.above.md} {
    padding-left: 1rem;
  }
  ${theme.above.lg} {
    overflow: visible;
    padding-left: 0;
  }

  ${theme.below.lg} {
    padding-bottom: 10px;
  }

  &:before {
    content: '';
    height: 1px;
    width: calc(100% - 20px);
    margin: auto;
    background: #ececec;
    margin-bottom: 20px;
  }
  &:after {
    content: '';
    height: 1px;
    width: calc(100% - 20px);
    margin: auto;
    background: #ececec;
    margin-bottom: 20px;
  }

  .slick-arrow {
    position: absolute;
    height: 36px;
    width: 36px;
    top: 39%;
    transform: translateY(-50%);
    border-radius: 100%;
    background: #fff;
    display: none;
    text-align: center;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1;
    cursor: pointer;
    transition: 0.2s;
    padding: 0;
    display: flex !important;
    box-shadow: 0px 0px 10px -5px rgba(0, 0, 0, 0.2);
    svg {
      height: 14px;

      use {
        fill: #000;
      }
    }
    &.slick-prev {
      left: 0px;

      svg {
        transform: rotate(90deg);
      }
    }
    &.slick-next {
      right: 0px;
      align-items: center;
      svg {
        transform: rotate(-90deg);
      }
    }
    &:hover {
      transform: translateY(-50%) scale(1.05);
      box-shadow: 0px 0px 10px -3px rgba(0, 0, 0, 0.3);
    }
    ${theme.below.lg} {
      &.slick-prev {
        left: 0px;
        svg {
          transform: rotate(90deg);
        }
      }
      &.slick-next {
        right: 0px;
        align-items: center;
        svg {
          transform: rotate(-90deg);
        }
      }
    }
  }
`;

const productPlaceholder = {
  name: '',
  id: 'placeholder',
  images: []
};

export default function ProductCarousel({
  className,
  products,
  loading,
  categoryPath,
  ProductComponent,
  productWidth,
  toShow = null
}) {
  const productList =
    products.length === 0 && loading
      ? Array(5).fill(productPlaceholder)
      : products;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: toShow ?? 5,
    slidesToScroll: toShow ?? 5,
    slide: 'ul',

    nextArrow: (
      <div>
        <Carrot />
      </div>
    ),
    prevArrow: (
      <div>
        <Carrot />
      </div>
    )
  };
  const mobileSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: toShow ?? 2,
    slidesToScroll: toShow ?? 2,
    slide: 'ul',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 690,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      }
    ],
    nextArrow: (
      <div>
        <Carrot />
      </div>
    ),
    prevArrow: (
      <div>
        <Carrot />
      </div>
    )
  };
  return (
    <Above breakpoint="lg">
      {matches => {
        const slideSettings = matches ? settings : mobileSettings;
        return (
          <ProductCarouselWrapper className={className + ' carousel'}>
            <ProductCarouselContainer
              data-testid="product-grid"
              className={className}
            >
              {products && (
                <SlickSlider {...slideSettings}>
                  {productList?.map((product, index) => (
                    <ProductComponent
                      loading={loading}
                      key={`${index}${product.id}`}
                      product={product}
                      categoryPath={categoryPath}
                    />
                  ))}
                </SlickSlider>
              )}
            </ProductCarouselContainer>
          </ProductCarouselWrapper>
        );
      }}
    </Above>
  );
}
