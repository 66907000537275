import ChannelContext from '@jetshop/core/components/ChannelContext';
import t from '@jetshop/intl';
import React, { Fragment, useState, useContext, useEffect } from 'react';
import { styled } from "linaria/react";
import ReactModal from 'react-modal';
import { ReactComponent as StoreFinderIcon } from '../../svg/StoreFinderIcon.svg';
import { ReactComponent as Cross } from '../../svg/Cross.svg';
import slugify from 'slugify'
import Spinner from '../ui/Spinner';

import { useLazyQuery } from 'react-apollo';
import { WarehouseStockQuery } from './WarehouseStockQuery.gql'

import { theme } from "../Theme";

const InfoModalWrapper = styled(ReactModal)`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  pointer-events: none;
`;

const ModalInside = styled('div')`
  background-color: white;
  padding: 4rem 3rem;
  width: 100vw;
  max-width: 660px;
  position: relative;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  pointer-events: all;
  max-height: 85vh;
  min-height: 120px;
  overflow-y: scroll;

  svg.close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 20px;
    height: 20px;
    color: ${theme.colors.primary};
    use,
    g,
    path {
      fill: ${theme.colors.primary};
    }
  }
  ${theme.below.sm} {
    padding: 1rem;
    max-height: 100vh;
    top: 60px;
  }
`;

const InfoLink = styled('button')`
  font-size: 12px;
  line-height: 17px;
  appearance: none;
  border: 0;
  border-radius: 0;
  background: transparent;
  text-decoration: underline;
  ${theme.below.md} {
    font-size: 14px;
  }
  span {
    text-decoration: underline;
  }
  svg {
    margin-right: 5px;
    width: 14px;
    height: 18px;
  }
  &:focus {
    outline: none;
  }
`;
const Title = styled('h2')`
  text-align: center;
  font-size: 1.5rem;
  max-width: 90%;
  margin: auto;
  margin-bottom: 0.5rem;
  display: block;
  color: #000;
`;
const Text = styled('p')`
  text-align: center;
  margin-bottom: 2rem;
  font-size: 14px;
  color: #000;
`;
const WarehouseList = styled('div')`
  display: block;
  margin-bottom: 2rem;
`;
const WarehouseItem = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 10px 30px;
  position: relative;
  border-bottom: 1px solid #d2d2d2;

  span {
    font-size: 14px;
    color: #000;
    font-weight: bold;
    width: 100%;
    &:last-of-type {
      min-width: 100px;
      text-align: right;
      font-weight: normal;
    }
    a {
      display: block;
      font-size: 11px;
      color: #8e8e8e;
      font-weight: normal;
      text-decoration: none;
    }
  }

  &:after {
    content: '';
    width: 9px;
    height: 45px;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  &.in-stock {
    &:after {
      background: #419241;
    }
    span:last-of-type {
      color: #419241;
    }
  }
  &.few-in-stock {
    &:after {
      background: #eaab36;
    }
    span:last-of-type {
      color: #eaab36;
    }
  }
  &.not-in-stock {
    &:after {
      background: #ca1313;
    }
    span:last-of-type {
      color: #ca1313;
    }
  }
`;
const SizeWrapper = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
  flex-wrap: wrap;
`;
const SizeItem = styled('button')`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: relative;
  color: #000;

  background: transparent;
  border: 1px solid ${theme.colors.primary};
  outline: none;
  padding: 5px 8px;
  min-width: 35px;
  min-height: 35px;
  margin-right: 12px;
  margin-bottom: 12px;

  font-size: 14px;
  letter-spacing: 2px;
  font-weight: 500;

  &.active {
    &:after {
      content: '';
      position: absolute;
      top: -2px;
      right: -2px;
      bottom: -2px;
      left: -2px;
      background: transparent;
      border: 3px solid ${theme.colors.primary};
      z-index: 1;
    }
  }
  ${theme.below.sm} {
    font-size: 12px;
  }
`;

const displayText = {
  'xx-small': 'XXS',
  'x-small': 'XS',
  small: 'S',
  medium: 'M',
  large: 'L',
  'x-large': 'XL',
  'xx-large': 'XXL',
};

const countries = {
  SV: 'Sverige',
  DK: 'Danmark',
  NO: 'Norge',
  FI: 'Suomi',
};

const Warehouse = ({ warehouseStock }) => {
  const { selectedChannel } = useContext(ChannelContext);
  return (
    <>
      {warehouseStock.map((warehouse, index) => (
        <React.Fragment key={index}>
          {warehouse.location &&
            warehouse.location.region === countries[selectedChannel.name] ? (
              <WarehouseItem
                index={warehouse.id}
                className={
                  warehouse.stockLevel <= 0
                    ? 'not-in-stock'
                    : warehouse.stockLevel > 0
                      ? 'in-stock'
                      : ''
                }
              >
                <span>
        
                  {warehouse.location && warehouse.location.name
                    ? warehouse.location.name
                    : ''}{' '}
                  <a href={'/stores/' + slugify(warehouse.location.name).toLowerCase()}>{t('Read more')}</a>
                </span>
                <span>
                  {warehouse.stockLevel > 0 ? t('In stock') : t('Out of stock')}
                </span>
              </WarehouseItem>
            ) : null}
        </React.Fragment>
      ))}
    </>
  );
};

const Size = (props) => {
  return (
    <SizeItem
      onClick={() => props.onClickEvent(props.variant.articleNumber)}
      className={
        props.activeIndex === props.variant.articleNumber ? 'active' : null
      }
    >
      {displayText[props.variant.values[0].toLowerCase()]
        ? displayText[props.variant.values[0].toLowerCase()]
        : props.variant.values[0]}
    </SizeItem>
  );
};

const ModalLink = ({
  product,
  link,
  children,
  type,
  Component,
  initialIndex,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    setActiveIndex(initialIndex)
  }, [initialIndex])

  const [getWarehouseStock, { loading, data }] = useLazyQuery(WarehouseStockQuery, {
    variables: {
      articleNumber: initialIndex ? initialIndex : product?.articleNumber
    }
  })

  const variants = data?.product?.variants ?? []

  return (
    <Fragment>
      <InfoLink
        className={`modalLink ${type ? type : null}`}
        onClick={() => {
          setOpen(!open);
          getWarehouseStock()
        }}
      >
        <StoreFinderIcon />
        {link && link}
        {children && children}
      </InfoLink>
      <InfoModalWrapper
        isOpen={open}
        onRequestClose={() => {
          setOpen(false);
        }}
      >
        <ModalInside>
          <Cross
            className="close-icon"
            onClick={() => {
              setOpen(false);
            }}
          />
          <Title>{t('View store availability')}</Title>
          {loading ? (
            <div style={{ textAlign: 'center' }}>
              <Spinner />
            </div>
          ) : null}
          {variants && variants.values.length > 0 && open ? (
            <>
              <Text>{t('Select size to see store availability')}</Text>

              <SizeWrapper>
                {variants.values.map((variant, index) => (
                  <Size
                    index={index}
                    variant={variant}
                    onClickEvent={setActiveIndex}
                    activeIndex={activeIndex}
                  />
                ))}
              </SizeWrapper>
              {activeIndex && !loading ? (
                <>
                  <WarehouseList>
                    {variants.values.map((variant, index) => (
                      <React.Fragment key={index}>
                        {variant.articleNumber === activeIndex ? (
                          <Warehouse
                            warehouseStock={variant.warehouseStock}
                            key={index}
                          />
                        ) : null}
                      </React.Fragment>
                    ))}
                  </WarehouseList>
                  <Text>
                    {t(
                      'Stock availability may change, please contact store to ensure availability.'
                    )}
                  </Text>
                </>
              ) : null}
            </>
          ) : (
              <>
                <WarehouseList>
                  {data?.product?.warehouseStock && <Warehouse warehouseStock={data?.product?.warehouseStock ?? []} />}
                </WarehouseList>
                <Text>
                  {t(
                    'Stock availability may change, please contact store to ensure availability.'
                  )}
                </Text>
              </>
            )}
          {Component && <Component {...props} />}
        </ModalInside>
      </InfoModalWrapper>
    </Fragment>
  );
};

export default ModalLink;
